import React, { Component } from "react";
import { getTenant, getToken, getUser } from "../../../../auth-helpers.js";
const campañamanager = require('../../../../ServerFunctions/campaignmanager');
export default class TenantCampaignAdd extends Component {
constructor(props){
    super(props);
    this.state={
        mesagge:'',
        t_conector:'',
        appId:'',
        segments:[],
        arnlambda:"arn:aws:lambda:us-east-1:372676347984:function:PinPointLambdaNew",
        data:"Prueba",
        t_campaign_name:'',
        segment_id:'',
        timezone:'UTC-05',
        sent:'IMMEDIATE',
        disabled:false,
        StartDate:"",
        StartTime:"",
        EndDate:"",
        EndTime:"",
        segemento:null,
        habilitada:false,
        reserva:false,
        select_cod:""

    }
    this.handleCreate=this.handleCreate.bind(this);
}

handleCreate(event){
    event.preventDefault();
    var authToken = getToken();
    var user=getUser(authToken)
    var start="";
    var end="";
    var frecuency=this.state.sent;
    var reserva=this.state.reserva;
    this.setState({
        mensaje:"Espere mientras creamos la campaña",
        disabled:true,
        usuario:user.email
    })
    if(this.state.t_campaign_name=="" || this.state.segment_id==""){
        this.setState({
            mensaje:"Campos vacios",
            disabled:false
        })
        return false;
    }
    const segmento = this.state.segments.filter(segmento => segmento.segment_id==this.state.segment_id );
    console.log(segmento)
    var tenant=getTenant();
    this.setState({
        data:segmento[0].bodytext 
    })
    var key={
        "key":segmento[0].urlS3.split(tenant.TenantId+ "/")[1]
    }
    console.log(key)
    if(this.state.sent==="IMMEDIATE"){
        start="IMMEDIATE";
        frecuency="";
        reserva=false
        
    }else{
        reserva=true;
        if(this.state.StartDate=="" || this.state.StartTime==""){
            this.setState({
                mensaje:"No se ha seleccionado fecha y hora de inicio del envio de campaña.",
                disabled:false
            })
            return false;
        }
        var startdate=this.state.StartDate.split("-");
        var starttime=this.state.StartTime.split(":");
        var fecha= new Date(startdate[0],parseInt(startdate[1])-1,startdate[2],starttime[0],starttime[1]);
        var utcma=this.state.timezone.split("-");
        var utcme=this.state.timezone.split("+");
        start=fecha;
        if(utcma.length>1){
            fecha.setHours(fecha.getHours()+parseInt(utcma[1]));
            start=fecha;
        }
        if(utcme.length>1){
            fecha.setHours(fecha.getHours()-parseInt(utcme[1]));
            start=fecha;

        }
        start = (new Date(start.toString().split('GMT')[0]+' UTC').toISOString()).toString();
        if(this.state.sent!=="ONCE"){
            end=this.state.EndDate;
            if(this.state.EndDate =="" ){
                this.setState({
                    mensaje:"No se ha seleccionado fecha y hora fin del envio de campaña.",
                    disabled:false
                })
                return false;
            }
            if(this.state.sent==="HOURLY"){
                if(this.state.EndTime =="" ){
                    this.setState({
                        mensaje:"No se ha seleccionado fecha y hora fin del envio de campaña.",
                        disabled:false
                    })
                    return false;
                }
                var enddate=this.state.EndDate.split("-");
                var endtime=this.state.EndTime.split(":");
                var fechaend= new Date(enddate[0],parseInt(enddate[1])-1,enddate[2],endtime[0],endtime[1]);
                var utcmaend=this.state.timezone.split("-");
                var utcmeend=this.state.timezone.split("+");
                if(utcmaend.length>1){
                    fechaend.setHours(fechaend.getHours()+parseInt(utcmaend[1]));
                    end=fechaend;
                }
                if(utcmeend.length>1){
                    fechaend.setHours(fechaend.getHours()-parseInt(utcmeend[1]));
                    end=fechaend;
 
                }
                end = (new Date(end.toString().split('GMT')[0]+' UTC').toISOString()).toString();
            }
        }
    }
    var thisa=this
    var tenant=getTenant();
    campañamanager.ObtenerReservasAdministrador(authToken,tenant.TenantId,function(reservas){
        if(reservas){
            var indicesarray=[]
            var indicesarray_test=[]
            var indices=[]
            var contin=1
            var contador=0
            for(let nuevo=0;nuevo<reservas.length;nuevo++){
                for(contin;contin<parseInt(reservas[nuevo].inicio);contin++){
                    indicesarray.push(contin)
                    contador=contador+1
                    if(contador<=segmento[0].registros){
                        indicesarray_test.push(contin)  
                    }
                }
                if(indicesarray_test.length>0){
                    indices.push(indicesarray_test[0])
                    indices.push(indicesarray_test[indicesarray_test.length-1])
                    indicesarray_test=[]
                }
                contin=parseInt(reservas[nuevo].fin)+1;
            }

            var valorult=contin;
            if(indicesarray.length<segmento[0].registros){
                indices.push(valorult)
                for(let n=indicesarray.length;n<segmento[0].registros;n++){
                    indicesarray.push(valorult)
                    valorult=valorult+1
                }
                indices.push(valorult-1)
            }
            var reg=""
                for(let i=0;i<indices.length;i++){
                    if(reg==""){
                        reg=reg+indices[i]
                    }else{
                        if(i%2==0){
                            reg=reg+","+indices[i]
                        }else{
                            reg=reg+"-"+indices[i]    
                        }
                    }
                }
            var informacion=[]
            informacion=campañamanager.ObtenerSegmentS3Administrador(authToken,tenant.TenantId,key);
                informacion.then(res=>{
                var addres=0;
                var endpoint=0;
                var endpointId=0;
                var indata=res.split("\r\n")
                var str=""
                for(let i=0;i<indata.length-1;i++){
                    indata[i]=indata[i].split(",")
                    for(let j=0;j<43;j++){
                        if(i==0){
                            if(indata[i][j]==="Address"){
                                addres=j
                            }
                            if(indata[i][j]==="Attributes.EndpointId"){
                                endpoint=j
                            }
                            if(indata[i][j]==="Id"){
                                endpointId=j
                            }
                        }else{
                            if(indicesarray[i-1]!=undefined){
                                indata[i][addres]=(indicesarray[i-1]).toString();
                                indata[i][endpoint]=(indicesarray[i-1]).toString();
                                indata[i][endpointId]=(indicesarray[i-1]).toString();
                            }
                        }
                    }
                    let line = "";
                    for(let j=0;j<46;j++){
                        if (line != ""){
                            line += ",";
                        }
                        if(indata[i][j]===undefined || indata[i][j]==="undefined"){
                            line += "contiene_error";
                        }else{
                            line += indata[i][j];
                        }
                    }
                    if(i==0){
                        line+=",Attributes.agente"
                    }else{
                        line+=","+thisa.state.usuario
                    }
                    var errmsg="contiene_error";
                    if(!line.includes(errmsg)){
                        console.log(line)
                        str+=line+ "\r\n";
                    }
                }
                console.log(str)
                const exportName = segmento.nombre + ".csv" || "export.csv";
                const blob = new Blob([str], { type: "text/csv;charset=utf-8;" });
                if (navigator.msSaveBlob) {
                    navigator.msSaveBlob(blob, exportName);
                } else {
                    var authToken = getToken();
                    const f = new FormData();
                    f.append('file',blob);
                    console.log(segmento[0])
                    var path=segmento[0].urlS3.split(tenant.TenantId+ "/")[1].split("/")[0]
                    var name=segmento[0].nombre+"_"+segmento[0].segment_id
                    campañamanager.ActualizarSegmentoAdministrador(authToken,f,path,name,tenant.TenantId, function(segment){
                        if(segment){
                            var body={
                                "ApplicationId":tenant.PinPointId,
                                "RoleArn":"arn:aws:iam::372676347984:role/service-role/test",
                                "S3Url":tenant.TenantId+"/"+path+"/"+name+'.csv',
                                "SegmentID":"931c400e83234ccfa6c68d2ef8b5c05b",
                                "SegmentName":segmento[0].nombre
                            }
                            //console.log(body)
                            campañamanager.CrearSegmento(authToken,body,function(segm){
                                if(segm){
                                 //   console.log(segm)
                                    var campaigncreate={
                                        tenantappId:tenant.PinPointId,
                                        arnlambda:"",
                                        daily:thisa.state.daily,
                                        data:thisa.state.data,
                                        name:thisa.state.t_campaign_name,
                                        Start:start,
                                        End:end,
                                        frecuency:frecuency,
                                        Timezone:thisa.state.timezone,
                                        SegmentId:segm.ImportJobResponse.Definition.SegmentId
                                    }
                                    //console.log(campaign)
                                     setTimeout(function(){
                                        campañamanager.CrearCampaña(authToken,campaigncreate, function(campaign) {
                                            if(campaign){
                                                var camp={
                                                    "Id":campaign.Id,
                                                    "SegmentId":campaign.SegmentId,
                                                    "ApplicationId":campaign.ApplicationId,
                                                    "CreationDate":campaign.CreationDate,
                                                    "Timezone":thisa.state.timezone,
                                                    "StartTime":start,
                                                    "Frequency":frecuency,
                                                    "EndTime":end,
                                                    "registros":reg,
                                                    "habilitada":thisa.state.habilitada,
                                                    "reserva":reserva,
                                                    "segment_id":segmento[0].segment_id,
                                                    "codigo":segmento[0].codigo,
                                                    "nombre":thisa.state.t_campaign_name,
                                                    "conector_id":segmento[0].conector_id,
                                                    "template_id":segmento[0].template_id,
                                                    "user_id":user["email"]
                                                }
                                                console.log(camp)
                                                console.log(reg)
                                                campañamanager.InsertarCampaña(authToken,camp,function(response){
                                                    if(response){
                                                        console.log("almacenado en la base")     
                                                    }
                                                })
                                                setTimeout(function(){
                                                    thisa.setState({
                                                        mensaje:"Campaña creada exitosamente."
                                                    })
                                                },5000)
  
                                                setTimeout(function(){
                                                   window.location.href="/campaign-list"
                                                },5000)
 
                                            }else{
                                                if(frecuency==""){
                                                    thisa.setState({
                                                        mensaje:"No se pudo crear la campaña. Intentelo más tarde.",
                                                        disabled:false
                                                    })
                                                }else{
                                                    thisa.setState({
                                                        mensaje:"No se pudo crear la campaña, verifique los parametros de envio. Si el problema persiste intentelo más tarde.",
                                                        disabled:false
                                                    })
                                                }
                                            }
                                        });
                                    }, 15000);
                                }else{
                                    console.log("ERROR importjob")
                                    thisa.setState({
                                                        mensaje:"No se pudo crear la campaña. Intentelo más tarde.",
                                    })
                                    setTimeout(function(){
                                        window.location.href="/campaign-list"
                                     },2000)

                                }
                            })
                        }else{
                            console.log("ERROR S3")
                            thisa.setState({
                                mensaje:"No se pudo crear la campaña, verifique los parametros de envio. Si el problema persiste intentelo más tarde."
                            })
                            setTimeout(function(){
                                window.location.href="/campaign-list"
                             },2000)
                        }
            
                    })
                }
            })
        }else{
            this.setState({
                mensaje:"No se pudo crear la campaña. Intentelo más tarde.",
                disabled:false
            })
            return false;
        }
    })
}

componentDidMount(){
    
    var authToken = getToken();
    var thisa= this;
    var tenant=getTenant();
    campañamanager.ListSegmentosAdministrador(authToken,tenant.TenantId,function(segments){
        thisa.setState({
            segments:segments
        })
    })
}

    render() {
        const CheckBox = ({segment}) => (
            <input
              id={segment.segment_id}
              onChange={() => {
                this.setState({
                    checkedId:segment.segment_id,
                    segment_id:segment.segment_id,
                })
              }}
              checked={this.state.checkedId === segment.segment_id}
              type="checkbox"
            />
          )
        var segments= this.state.segments;
        return (
           <>
            <div >
                <form onSubmit={this.handleCreate}>
                    <br/>
                    <div className="template-inner">
                        <h2>Crear Campaña</h2>
                        <br/>
                        <div className="row">
                            <div className="col">
                            <label>Nombre de campaña</label>
                            <input type="text" className="form-control" placeholder="Campaign Name" value={this.state.t_campaign_name}
                               disabled={(this.state.disabled)? "disabled" : ""}
                               onChange={(e)=>this.setState({
                                    t_campaign_name: e.target.value
                                    })}
                            />
                            </div>
                            <div className="col">
                            <label>País</label>
                            <select 
                                className="form-select"
                                disabled={(this.state.disable)? "disabled" : "" }
                                value={this.state.select_cod}
                                onChange={
                                (e)=>{
                                        this.setState({
                                            select_cod:e.target.value
                                    })}}
                                >
                                        <option value='' >Seleccionar país</option>
                                        <option value='593' >Ecuador</option>
                                        <option value='51' >Perú</option>
                                        <option value='57' >Colombia</option>
                            </select>
                            </div>
                        </div>
                        <div>
                            <br/>
                        <label>Lista de contactos</label>
                        <div className="row" style={{alignContent:"center"}}>
                        <div  style={{maxHeight:"300px"}} className="table-responsive">
                           <table  className="table table-striped">
                                    <thead>
                                        <tr>
                                        <th></th>
                                        <th>Nombre</th>
                                        <th>Pais</th>
                                        </tr>
                                    </thead>
                                <tbody>
                                    {
                                        segments?(
                                            <>
                                            { segments.map((item, i) => {
                                                    return (
                                                        <>{
                                                                <>
                                                                {
                                                                    this.state.select_cod==""?(<>
                                                                    <tr key={item.segment_id}>
                                                                    <td>
                                                                    <CheckBox segment={item} />
                                                                    </td>
                                                                    <td>{item.nombre}</td>
                                                                    <td>{item.codigo=="593"?("EC"):(
                                                                        <>
                                                                        {
                                                                            item.codigo=="51"?("PE"):("COL")
                                                                        }</>)}</td>
                                                                    </tr>
                                                                    </>):(<>
                                                                    {
                                                                        item.codigo==this.state.select_cod?(
                                                                        <>
                                                                            <tr key={item.segment_id}>
                                                                                <td>
                                                                                <CheckBox segment={item} />
                                                                                </td>
                                                                                <td>{item.nombre}</td>
                                                                                <td>{item.codigo=="593"?("EC"):(
                                                                                <>
                                                                                {
                                                                                    item.codigo=="51"?("PE"):("COL")
                                                                                }</>)}</td>
                                                                            </tr>
                                                                        </>):(<></>)
                                                                    }</>)
                                                                }
                                                            </>
                                                        }
                                                        </>
                                                       
                                                    );
                                            })}
                                            </>
                                        ):(
                                            <>
                                            </>
                                        )
                                    }
                                </tbody>
                                </table>
                        </div>
                     </div>
                        </div> </div>
                    <br/>
                    <div className="template-inner">
                        <h2>Elegir cuándo enviar la campaña</h2>
                        <br/>
                        <div className="row">
                            <div className="col">
                                <label>Elegir cuándo se debe enviar la campaña</label>
                                <select 
                                        disabled={(this.state.disabled)? "disabled" : ""}
                                        className="form-select"
                                        defaultValue="IMMEDIATE" 
                                        onChange={(e)=>this.setState({
                                            sent: e.target.value
                                        })}
                                    >
                                        <option value='IMMEDIATE' >Inmediatamente</option>
                                        <option value='ONCE' >Una vez</option>
                                        <option value='HOURLY' >Por hora</option>
                                        <option value='DAILY' >Diaria</option>
                                        <option value='WEEKLY' >Semanal</option>
                                        <option value='MONTHLY' >Mensual</option>
                                    </select>
                            </div>
                            <div className="col">
                                <label>Zona horaria</label>
                                <select 
                                        disabled={(this.state.disabled)? "disabled" : ""}
                                        className="form-select"
                                        defaultValue={this.state.timezone}
                                        onChange={(e)=>this.setState({
                                            timezone: e.target.value
                                        })}
                                    >
                                        <option value='UTC'>UTC+00:00</option>
                                        <option value='UTC+01' >UTC+01:00</option>
                                        <option value='UTC+02' >UTC+02:00</option>
                                        <option value='UTC+03' >UTC+03:00</option>
                                        <option value='UTC+04' >UTC+04:00</option>
                                        <option value='UTC+05' >UTC+05:00</option>
                                        <option value='UTC+06' >UTC+06:00</option>
                                        <option value='UTC+07' >UTC+07:00</option>
                                        <option value='UTC+08' >UTC+08:00</option>
                                        <option value='UTC+09' >UTC+09:00</option>
                                        <option value='UTC+10' >UTC+10:00</option>
                                        <option value='UTC+11' >UTC+11:00</option>
                                        <option value='UTC+12' >UTC+12:00</option>
                                        <option value='UTC+13' >UTC+13:00</option>
                                        <option value='UTC-02' >UTC-02:00</option>
                                        <option value='UTC-03' >UTC-03:00</option>
                                        <option value='UTC-04' >UTC-04:00</option>
                                        <option value='UTC-05' >UTC-05:00</option>
                                        <option value='UTC-06' >UTC-06:00</option>
                                        <option value='UTC-07' >UTC-07:00</option>
                                        <option value='UTC-08' >UTC-08:00</option>
                                        <option value='UTC-09' >UTC-09:00</option>
                                        <option value='UTC-10' >UTC-10:00</option>
                                        <option value='UTC-11' >UTC-11:00</option>
                                    </select>
                            </div>
                        </div>
                        {
                            this.state.sent!=="IMMEDIATE"&&(
                                <>
                                <br/>
                                <div className="row">
                                    <div className="col-sm-6" style={{textAlign:"start"}}>
                                        <label>Fecha y hora de inicio</label>
                                        <div className="row">
                                            <div className="col-sm-8">
                                                <input type="date" 
                                                    disabled={(this.state.disabled)? "disabled" : ""}
                                                    onChange={(e)=>this.setState({
                                                    StartDate: e.target.value
                                                })}
                                                className="form-control"></input>
                                            </div>
                                            <div className="col-sm-4">
                                                <input 
                                                    disabled={(this.state.disabled)? "disabled" : ""}
                                                    onChange={(e)=>this.setState({
                                                    StartTime: e.target.value
                                                 })}
                                                    type="time" className="form-control"></input>
                                            </div>
                                        </div>
                                        
                                    </div>
                                {
                                    this.state.sent!=="ONCE"&&(
                                        <>
                                            <div className="col-sm-6" style={{textAlign:"start"}}>
                                                <label>Fecha y hora de finalización</label>
                                                <div className="row">
                                                    <div className="col-sm-8">
                                                        <input 
                                                            disabled={(this.state.disabled)? "disabled" : ""}
                                                            onChange={(e)=>this.setState({
                                                            EndDate: e.target.value
                                                        })}
                                                        type="date" className="form-control"></input>
                                                    </div>
                                                    {
                                                        this.state.sent==="HOURLY"&&(
                                                            <>
                                                            <div className="col-sm-4">
                                                                <input 
                                                                    disabled={(this.state.disabled)? "disabled" : ""}
                                                                    onChange={(e)=>this.setState({
                                                                    EndTime: e.target.value
                                                                })}
                                                                type="time" className="form-control"></input>
                                                            </div>
                                                            </>
                                                        )
                                                    }
                                                    
                                                </div>
                                                
                                            </div>
                                        </>
                                    )
                                }
                                </div>
                                </>
                            )
                        }
                        <br/>
                        <div className="row">
                            <div className="col-sm-4">
                            <label className="form-check-label" >Habilitar para agentes</label>
                            </div>
                            <div className="form-check form-switch col-sm-2">
                                    <input className="form-check-input" type="checkbox" 
                                        disabled={(this.state.disabled)? "disabled" : "" }
                                        checked={(this.state.habilitada)? "checked" : ""}
                                        onChange={(e) => {
                                            this.setState({
                                                habilitada:e.target.checked
                                            })
                                                                                    }}
                                        />
                                </div>
                         </div>
                    </div>
                    <br/>
                    <button type="submit" className="paddingbutton btn btn-primary btn-block" 
                    onClick={this.handleCreate}
                    disabled={(this.state.disabled)? "disabled" : "" }> Crear</button>
                    <a href="/campaign-list" className="btn btn-secondary "  role="button" aria-disabled="true">Volver</a>  
                    <div>
                    <label>{this.state.mensaje}</label>
                    </div>
                    <br/>
                    <br/>

                   </form>
             </div>
            </>
        );
    }
}