import axios from 'axios';
//local
//const url_conector="http://localhost:3090/"
//const url_template="http://localhost:3050/"
//
//desarrollo
//const url_conector="https://xh4tj9f7ek.execute-api.us-east-2.amazonaws.com/dev/"
//const url_template="https://cuymtpz4r2.execute-api.us-east-2.amazonaws.com/dev/"

//producción
const url_conector="https://ujgtcn03ji.execute-api.us-east-2.amazonaws.com/prod/"
const url_template="https://0ghmyt12g8.execute-api.us-east-2.amazonaws.com/prod/"
//

export function ListConector(token,callback){
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.get( 
        url_conector+`conectors`,
        config
    ).then(res=>{
         callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}

export function ObtenerConector(token,id,callback){
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.get( 
        url_conector+`conector/`+id,
        config
    ).then(res=>{
         callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}

export function ObtenerConectorAdministrador(token,id,tenantId,callback){
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.get( 
        url_conector+`conector/system/`+tenantId+"/"+id,
        config
    ).then(res=>{
         callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}

export function CrearConectorAdministrador(token,conector,tenantid,callback) {
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.post( 
        url_conector+`conector/system/`+tenantid,
      conector,
      config
    ).then(res=>{
        callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}

export function EliminarConectarAdministrador(conector_id,tenantid,token,callback) {
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.get(
        url_conector+'buscarconexion/'+tenantid+'/'+conector_id,
        config 
    ).then(res=>{
        if(res.data.length!==0){
            res.data.mensaje='Este conectar esta en uso. No se puede eliminar'
            callback(res.data)
        }else{

            axios.delete( 
                url_conector+`conector/system/`+tenantid+'/'+conector_id,
                config
            ).then(res=>{
                callback(res.data)
            }).catch(err=>{
                callback(null)
            });
        }
    }).catch(err=>{
        callback(null)
    });
    
}

export function EditarConectorAdministrador(token,conector,tenantid,callback) {
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.put( 
        url_conector+`conector/system/`+tenantid,
      conector,
      config
    ).then(res=>{
        callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}
   
export function ListConectorAdministrador(token,tenantid,callback){
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.get( 
        url_conector+`conectors/system/`+tenantid,
      config
    ).then(res=>{
         callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}

export function CrearProvider(token,provider,callback) {
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.post( 
        url_conector+`provider`,
      provider,
      config
    ).then(res=>{
        callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}

export function EliminarProvider(provider_id,token,callback) {
    const config = {
        headers: { Authorization: `${token}` }
    };

    axios.get(
        url_conector+'conectors/'+provider_id,
        config
    ).then(res=>{
        if(res.data.length!==0){
            res.data.mensaje='Este proveedor esta en uso. No se puede eliminar'
            callback(res.data)
        }else{
            axios.delete( 
                url_conector+`provider/`+provider_id,
                 config
             ).then(res=>{
                res.data.mensaje='Proveedor eliminado exitosamente'
                 callback(res.data)
             }).catch(err=>{
                 callback(null)
             });
        }
    })
    
}

export function EditarProvider(token,provider,callback) {
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.put( 
        url_conector+`provider`,
      provider,
      config
    ).then(res=>{
        callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}
   
export function ListProviders(token,callback){
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.get( 
        url_conector+`providers`,
      config
    ).then(res=>{
         callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}
