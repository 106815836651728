import React,{useEffect, useState} from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter as Router, Switch, Route,Redirect} from "react-router-dom";

import Login from "./Components/login.component";
import Confirm from './Components/confirm.component';
import { getRefresh, getTenant, getToken, getUser, initAxiosInterceptors, setToken } from './auth-helpers';
import Home from './Components/home.component';
import UserAdd from './Components/Users/useradd.component';
import UserList from './Components/Users/userlist.component';
import TenantList from './Components/Tenants/tenantlist.component';
import TenantAdd from './Components/Tenants/tenantadd.component';
import TenantEdit from './Components/Tenants/tenantedit.component';
import TenantDelete from './Components/Tenants/tenantdelete.component';
import UserDelete from './Components/Users/userdelete.component';
import UserEdit from './Components/Users/useredit.component';
import UserEnable from './Components/Users/userenable.component';

import ProviderList from './Components/Provider/providerlist.component';
import ProviderAdd from './Components/Provider/provideradd.component';
import ProviderEdit from './Components/Provider/provideredit.component';
import ProviderDelete from './Components/Provider/providerdelete.component';
import TemplateList from './Components/Templates/templetelist.component';
import TemplateAdd from './Components/Templates/templeteadd.component';
import TemplateEdit from './Components/Templates/templeteedit.component';
import TemplateDelete from './Components/Templates/templetedelete.component';
import CampaignList from './Components/Campaign/Campaign/campaignlist.component';
import CampaignAdd from './Components/Campaign/Campaign/campaignadd.component';
import CampaignEdit from './Components/Campaign/Campaign/campaignedit.component';
import CampaignDelete from './Components/Campaign/Campaign/campaindelete.component';
import SegmentList from './Components/Campaign/Segments/segmentlist.component';
import SegmentAdd from './Components/Campaign/Segments/segmentadd.component';
import SegmentEdit from './Components/Campaign/Segments/segmentedit.component';
import SegmentDelete from './Components/Campaign/Segments/segmentdelete.component';
import SegmentGenesysList from './Components/Campaign/CampaignGenesys/segmentlist.component';
import SegmentGenesysAdd from './Components/Campaign/CampaignGenesys/segmentadd.component';
import SegmentGenesysEdit from './Components/Campaign/CampaignGenesys/segmentedit.component';
import SegmentGenesysDelete from './Components/Campaign/CampaignGenesys/segmentdelete.component';
import ReporteriaTemplates from './Components/Reporteria/reporteriatemplates.component';
import ReporteriaFechas from './Components/Reporteria/reporteriafechas.component';
import ReporteriaCampañas from './Components/Reporteria/reporteria.component';

import TenantUserList from './Components/Tenants/Users/tenantuserlist.component';
import TenantUserAdd from './Components/Tenants/Users/tenantuseradd.components';
import TenantUserEdit from './Components/Tenants/Users/tenantuseredit.component';
import TenantUserEnable from './Components/Tenants/Users/tenantuserenabled.components';
import TenantUserDelete from './Components/Tenants/Users/tenantuserdelete.component';
import TenantTemplateList from './Components/Tenants/Templates/tenantTemplatelist.component'
import TenatTemplateAdd from './Components/Tenants/Templates/tenantTemplateadd.component';
import TenantTemplateEdit from './Components/Tenants/Templates/tenantTemplateedit.component';
import TenantTemplateDelete from './Components/Tenants/Templates/tenantTemplatedelete.component';

import TenantConectorGenesysList from './Components/Tenants/ConectorsGenesys/tenantconnectorgenesyslist.component';
import TenantConectorGenesysAdd from './Components/Tenants/ConectorsGenesys/tenantconnectorgenesysadd.component';
import TenantConectorGenesysDelete from './Components/Tenants/ConectorsGenesys/tenantconnectorgenesysdelete.component';
import TenantConectorGenesysEdit from './Components/Tenants/ConectorsGenesys/tenantconnectorgenesysedit.component';

import TenantConectorList from './Components/Tenants/Connectors/tenantconnectorlist.component';
import TenantConectorAdd from './Components/Tenants/Connectors/tenantconnectoradd.component';
import TenantConectorDelete from './Components/Tenants/Connectors/tenantconnectordelete.component';
import TenantConectorEdit from './Components/Tenants/Connectors/tenantconnectoredit.component';

import TenantConectorGenesysConexionList from './Components/Tenants/ConectoresConexion/tenantconnectorlist.component';
import TenantConectorGenesysConexionAdd from './Components/Tenants/ConectoresConexion/tenantconnectoradd.component';
import TenantConectorGenesysConexionDelete from './Components/Tenants/ConectoresConexion/tenantconnectordelete.component';
import TenantConectorGenesysConexionEdit from './Components/Tenants/ConectoresConexion/tenantconnectoredit.component';

import TenantCampaignAdd from './Components/Tenants/Campaign/Campaign/tenantcampaignadd.component';
import TenantCampaignEdit from './Components/Tenants/Campaign/Campaign/tenantcampaignedit.component';
import TenantCampaignDelete from './Components/Tenants/Campaign/Campaign/tenantcampaindelete.component';
import TenantCampaignList from './Components/Tenants/Campaign/Campaign/tenantcampaignlist.component';
import TenantSegmentAdd from './Components/Tenants/Campaign/Segments/tenantsegmentadd.component';
import TenantSegmentEdit from './Components/Tenants/Campaign/Segments/tenantsegmentedit.component'
import TenantSegmentDelete from './Components/Tenants/Campaign/Segments/tenantsegmentdelete.component';
import TenantSegmentList from './Components/Tenants/Campaign/Segments/tenantsegmentlist.component'
import TenantReport from './Components/Tenants/Reportes/tenantreporter.component'

import TenantReporteriaTemplates from './Components/Tenants/Reportes/tenantreporteriatemplates.component';
import TenantReporteriaFechas from './Components/Tenants/Reportes/tenantreporteriafechas.component';
import TenantReporteriaCampañas from './Components/Tenants/Reportes/tenantreporteria.component';

import Password from './Components/Users/getpassword.component';

const usermanager = require('./ServerFunctions/usermanager');
function App() {

  const [user,setUser]=useState(null);
  const [tenant,setTenant]=useState(null);
  const [role,setRole]=useState(null);
  const confirmar = window.localStorage.getItem('changepassword');
  const forgot = window.localStorage.getItem('forgotPassword');
  useEffect(()=>{
    async function cargarUsuario(){
      if(!getToken()){
        return;
      }
      try{
        const token=getToken();
        var users= getUser(token);
          setUser(users);
          if(users){
            setRole(users["custom:role"])
            var today= new Date()
            var expire=new Date(parseInt(users.exp)*1000)
           if(today>expire){
              var usern={
                companyName:users['custom:companyName'],
                userName:users['email'],
                refresh:getRefresh()
              }
              usermanager.Refresh(usern, function(usrs) {
              if(usrs){
                  console.log("NEW TOKEN")
                  setToken(usrs.AuthenticationResult.IdToken);
                }else{
                  console.log("TOKEN EXPIRED")
                  window.localStorage.clear()
                  window.location.href="/"
                }
              });          
            }
            var tenant=getTenant();
            if(tenant){
              setTenant(tenant)
            }
          }

      }catch(error){
        console.log(error)
      }
    }
    
    cargarUsuario();
    
  },[]);
  return (
    <div className="App">
    {
      user?(
        <>
        {
          tenant?(
            <>
               <Router>
                <Switch>
                    <Route
                    exact
                    path="/"
                    render={() => {
                        return (
                          <Redirect to="/home" /> 
                        )
                    }}
                  />
                  <Route path="/home" component={Home} />
                  <Route path="/user-add" component={TenantUserAdd} />
                  <Route path="/user-edit" component={TenantUserEdit} />
                  <Route path="/user-delete" component={TenantUserDelete} />
                  <Route path="/user-enabled" component={TenantUserEnable} />
                  <Route path="/user-list" component={TenantUserList} />
                  <Route path="/template-list" component={TenantTemplateList} />
                  <Route path="/template-add" component={TenatTemplateAdd} />
                  <Route path="/template-delete" component={TenantTemplateDelete} />
                  <Route path="/template-edit" component={TenantTemplateEdit} />
                  <Route path="/conector-add" component={TenantConectorAdd} />
                  <Route path="/conector-edit" component={TenantConectorEdit} />
                  <Route path="/conector-delete" component={TenantConectorDelete} />
                  <Route path="/conector-list" component={TenantConectorList} />
                  <Route path="/conectorgenesys-add" component={TenantConectorGenesysAdd} />
                  <Route path="/conectorgenesys-edit" component={TenantConectorGenesysEdit} />
                  <Route path="/conectorgenesys-delete" component={TenantConectorGenesysDelete} />
                  <Route path="/conectorgenesys-list" component={TenantConectorGenesysList} />
                  <Route path="/conectorgenesysconexion-add" component={TenantConectorGenesysConexionAdd} />
                  <Route path="/conectorgenesysconexion-edit" component={TenantConectorGenesysConexionEdit} />
                  <Route path="/conectorgenesysconexion-delete" component={TenantConectorGenesysConexionDelete} />
                  <Route path="/conectorgenesysconexion-list" component={TenantConectorGenesysConexionList} />
                  <Route path="/campaign-list" component={TenantCampaignList} />
                  <Route path="/campaign-add" component={TenantCampaignAdd} />
                  <Route path="/campaign-edit" component={TenantCampaignEdit}/>
                  <Route path="/campaign-delete" component={TenantCampaignDelete}/>
                  <Route path="/segment-list" component={TenantSegmentList} />
                  <Route path="/segment-add" component={TenantSegmentAdd} />
                  <Route path="/segment-edit" component={TenantSegmentEdit} />
                  <Route path="/reporteria" component={TenantReport} />
                  <Route path="/segment-delete" component={TenantSegmentDelete} />
                  <Route path="/reporteria-campañas" component={TenantReporteriaCampañas} />
                  <Route path="/reporteria-fechas" component={TenantReporteriaFechas} />
                  <Route path="/reporteria-templates" component={TenantReporteriaTemplates} />
                </Switch>
            </Router>
          
            </>
          ):(
            <>
               <Router>
                <Switch>
                    <Route
                    exact
                    path="/"
                    render={() => {
                        return (
                          <Redirect to="/home" /> 
                        )
                    }}
                  />
                  <Route path="/home" component={Home} />
                  <Route path="/user-add" component={UserAdd} />
                  <Route path="/user-edit" component={UserEdit} />
                  <Route path="/user-delete" component={UserDelete} />
                  <Route path="/user-enabled" component={UserEnable} />
                  <Route path="/user-list" component={UserList} />
                  <Route path="/tenant-add" component={TenantAdd} />
                  <Route path="/tenant-edit" component={TenantEdit} />
                  <Route path="/tenant-delete" component={TenantDelete} />
                  <Route path="/tenant-list" component={TenantList} />
                  <Route path="/template-list" component={TemplateList} />
                  <Route path="/template-add" component={TemplateAdd} />
                  <Route path="/template-delete" component={TemplateDelete} />
                  <Route path="/template-edit" component={TemplateEdit} />
                  <Route path="/campaign-list" component={CampaignList} />
                  <Route path="/campaign-add" component={CampaignAdd} />
                  <Route path="/campaign-edit" component={CampaignEdit}/>
                  <Route path="/campaign-delete" component={CampaignDelete}/>
                  <Route path="/segment-list" component={SegmentList} />
                  <Route path="/segment-add" component={SegmentAdd} />
                  <Route path="/segment-edit" component={SegmentEdit} />
                  <Route path="/segment-delete" component={SegmentDelete} />
                  <Route path="/genesys-list" component={SegmentGenesysList} />
                  <Route path="/genesys-add" component={SegmentGenesysAdd} />
                  <Route path="/genesys-edit" component={SegmentGenesysEdit} />
                  <Route path="/genesys-delete" component={SegmentGenesysDelete} />
                  <Route path="/reporteria-campañas" component={ReporteriaCampañas} />
                  <Route path="/reporteria-fechas" component={ReporteriaFechas} />
                  <Route path="/reporteria-templates" component={ReporteriaTemplates} />
                  {
                    role==="SystemAdmin"?(
                      <> 
                      <Route path="/provider-add" component={ProviderAdd} />
                      <Route path="/provider-edit" component={ProviderEdit} />
                      <Route path="/provider-delete" component={ProviderDelete} />
                      <Route path="/provider-list" component={ProviderList} />
                 </>
                    ):(
                      <>
                        </>
                    )
                  }
                </Switch>
            </Router>
          
            </>
          )
        }
        </>
      ):(
        <>
        {
          confirmar?(
            <>
            <Confirm/>
            </>
          ):(
            <>
            {forgot ? (
                <>
                  <Password />
                </>
              ) : (
                <>
                  <Login />
                </>
              )}
            </>
          )
        }
          </>
      )
    }
   </div>
  );
}

export default App;