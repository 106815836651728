//Configure Environment
import axios from 'axios';
import { getUser } from '../auth-helpers';
const { v4: uuidv4 } = require('uuid');

//desarrollo
//const url_campaña="http://localhost:3030/"
//const bucketname="pinpointht"
//const arnlambda="arn:aws:lambda:us-east-1:372676347984:function:PinPointLambdaNewd"

//desarrollo
const url_campaña="https://au95g314f4.execute-api.us-east-2.amazonaws.com/dev/"
//const bucketname="pinpointht"
//const arnlambda="arn:aws:lambda:us-east-1:372676347984:function:PinPointLambdaNewd"

//producción
//const url_campaña="https://cj3pqyeckb.execute-api.us-east-2.amazonaws.com/prod/";
const bucketname="whatsappengaging";
const arnlambda="arn:aws:lambda:us-east-1:372676347984:function:PinPointLambdaProd";
//const url_contactos="http://localhost:3085/";


export function ListSegmentos(token,callback){
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.get( 
        url_campaña+`segmentos`,
      config
    ).then(res=>{
         callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}

export function InsertarSegmento(authToken,archivo,templateid,conector_id,nombre,tenantid,filas,bodytext,codconector,callback){
    const config = {
        headers: { Authorization: `${authToken}` }
    };
    var seg_id=uuidv4();
    var segid=nombre+"_"+seg_id
    axios.post(
        url_campaña+'bucket/upload/'+bucketname+'/'+segid,
        archivo,
        config
    ).then(response=>{
        var usr;
            var user = getUser(authToken)
            usr={
                companyName:user['custom:companyName'],
                email:user['email'],
                appid:user['custom:appid']
            };

        var body={
            segment_id:seg_id,
            urlS3:"s3://"+bucketname+"/"+tenantid+"/Contact/"+segid+'.csv',
            app_id:usr.appid,
            nombre:nombre,
            template_id:templateid,
            conector_id:conector_id,
            registros:parseInt(filas),
            fecha:new Date(),
            bodytext:bodytext,
            codigo:codconector
        }
            axios.post(
                url_campaña+'campaign/insert/segment',
                body, 
                config
            ).then(response=>{ 
                callback(response)
            }).catch(error=>{
                callback(error)
            }) 
    }).catch(error=>{
        callback(error)
    })
}



export function CrearCampaña(token,campaign,callback) {
    const config = {
        headers: { Authorization: `${token}` }
    };
    campaign.arnlambda=arnlambda;
    axios.post( 
      url_campaña+`campaign/create/campaign`,
      campaign,
      config
    ).then(res=>{
        console.log(res.data)
        callback(res.data.CampaignResponse)
    }).catch(err=>{
        console.log(err)
        console.log("ENVIANDO NULL")

        callback(null)
    });
}
   
export function CrearCampañaAdmin(token,campaign,callback) {
    const config = {
        headers: { Authorization: `${token}` }
    };
    campaign.arnlambda=arnlambda;
    axios.post( 
      url_campaña+`campaign/create/campaign`,
      campaign,
      config
    ).then(res=>{
        console.log(res.data)
        callback(res.data.CampaignResponse)
    }).catch(err=>{
        callback(null)
    });
}

export function ListCampaña(token,appid,callback){
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.get( 
        url_campaña+`campaigns/`+appid,
      config
    ).then(res=>{
         callback(res.data.CampaignsResponse.Item)
    }).catch(err=>{
        callback(null)
    });
}

export function ListSegmentosAdministrador(token,tenantid,callback){
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.get( 
        url_campaña+`admin/segments/`+tenantid,
      config
    ).then(res=>{
         callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}

export function ObtenerReservas(token,callback){
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.get( 
        url_campaña+`reservas`,
      config
    ).then(res=>{
         callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}

export function ObtenerReservasAdministrador(token,tenantid,callback){
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.get( 
        url_campaña+`sys/reservas/`+tenantid,
      config
    ).then(res=>{
         callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}



export function InsertarSegmentoAdministrador(authToken,archivo,templateid,conector_id,nombre,tenantid,filas,bodytext,codconector,callback){
    const config = {
        headers: { Authorization: `${authToken}` }
    };
    var seg_id=uuidv4();
    var segid=nombre+"_"+seg_id
    axios.post(
        url_campaña+'admin/bucket/upload/'+bucketname+'/'+tenantid+'/'+segid,
        archivo,
        config
    ).then(response=>{
        console.log("ENVIANDO A BD")
        var usr;
            var user = getUser(authToken)
            usr={
                companyName:user['custom:companyName'],
                email:user['email'],
                appid:user['custom:appid']
            };

        var body={
            segment_id:seg_id,
            urlS3:"s3://"+bucketname+"/"+tenantid+"/Contact/"+segid+'.csv',
            app_id:usr.appid,
            nombre:nombre,
            template_id:templateid,
            conector_id:conector_id,
            registros:parseInt(filas),
            fecha:new Date(),
            bodytext:bodytext,
            codigo:codconector
        }
            axios.post(
                url_campaña+'admin/campaign/insert/segment/'+tenantid,
                body, 
                config
            ).then(response=>{ 
                callback(response)
            }).catch(error=>{
                callback(error)
            }) 
    }).catch(error=>{
        callback(error)
    })
}


export function ActualizarSegmento(authToken,archivo,path,nombre,callback){
    const config = {
        headers: { Authorization: `${authToken}` }
    };
    axios.post(
        url_campaña+'bucket/load/'+bucketname+'/'+path+"/"+nombre,
        archivo,
        config
    ).then(response=>{
        callback(response)
    }).catch(error=>{
        callback(null)
    })
}

export function ActualizarSegmentoAdministrador(authToken,archivo,path,nombre,tenantid,callback){
    const config = {
        headers: { Authorization: `${authToken}` }
    };
    axios.post(
        url_campaña+'sys/bucket/load/'+bucketname+'/'+path+"/"+tenantid+"/"+nombre,
        archivo,
        config
    ).then(response=>{
        callback(response)
    }).catch(error=>{
        callback(null)
    })
}

export function CrearSegmento(authToken,body,callback){
    const config = {
        headers: { Authorization: `${authToken}` }
    };
        body.S3Url="s3://"+bucketname+"/"+body.S3Url
    
        axios.post(
            url_campaña+'campaign/create/importjob',
            body,
            config
        ).then(response=>{
            callback(response.data)
        }).catch(error=>{
            callback(null)
        })      
}

export function EliminarSegmento(authToken,id,key,callback){
    const config = {
        headers: { Authorization: `${authToken}` }
    };
    var body={
        key:key
    }
    axios.post(
        url_campaña+'bucket/delete/'+bucketname,
        body,
        config,
    ).then(response=>{ 
        axios.delete( 
            url_campaña+'segment/'+id,
            config
          ).then(res=>{
              callback(res.data)
          }).catch(err=>{
              callback(null)
          });
    }).catch(error=>{
        callback(error)
    })
}

export function EliminarSegmentoAdministrador(authToken,id,key,tenantid,callback){
    const config = {
        headers: { Authorization: `${authToken}` }
    };
    var body={
        key:key
    }
    axios.post(
        url_campaña+'bucket/sysdelete/'+bucketname+'/'+tenantid,
        body,
        config,
    ).then(response=>{ 
        axios.delete( 
            url_campaña+'system/segment/'+id+"/"+tenantid,
            config
          ).then(res=>{
              //console.log(res);
              callback(res.data)
          }).catch(err=>{
              //console.log(err);
              callback(null)
          });
    }).catch(error=>{
        callback(error)
    })

}

export function ObtenerSegments3(authToken,key,callback){
    const config = {
        headers: { Authorization: `${authToken}` }
    };
    axios.post(
        url_campaña+'bucket/'+bucketname,
        key,
        config
    ).then(response=>{ 
        callback(response.data)
    }).catch(error=>{
        callback(error)
    })

}

export function ObtenerSegmentS3(authToken,key){
    return new Promise(
        function(resolve,reject){
            const config = {
                headers: { Authorization: `${authToken}` }
            };
            axios.post(
                url_campaña+'bucket/'+bucketname,
                key,
                config
            ).then(response=>{ 
                resolve(response.data)
            }).catch(error=>{
                reject(error)
            })
        }
    )
}

export function ObtenerSegmentS3Administrador(authToken,tenantid,key){
    return new Promise(
        function(resolve,reject){
            const config = {
                headers: { Authorization: `${authToken}` }
            };
            axios.post(
                url_campaña+'sys/bucket/'+bucketname+'/'+tenantid,
                key,
                config
            ).then(response=>{ 
                resolve(response.data)
            }).catch(error=>{
                reject(error)
            })
        }
    )
}
////////////////////////////Campaña DynamoDB//////////////////////////////

export function InsertarCampaña(authToken,campaign,callback){
    const config = {
        headers: { Authorization: `${authToken}` }
    };
    axios.post(
        url_campaña+'campana',
        campaign,
        config
    ).then(response=>{
        callback(response.data)
    }).catch(error=>{
        callback(null)
    })
} 
export function InsertarCampañaAdministrador(authToken,campaign,tenantid,callback){
    const config = {
        headers: { Authorization: `${authToken}` }
    };
    axios.post(
        url_campaña+'sytem/campana/'+tenantid,
        campaign,
        config
    ).then(response=>{
        callback(response.data)
    }).catch(error=>{
        callback(null)
    })
} 

export function ObtenerCampaña(authToken,campaign,callback){
    const config = {
        headers: { Authorization: `${authToken}` }
    };
    axios.get(
        url_campaña+'campana/'+campaign,
        config
    ).then(response=>{
        callback(response.data[0])
    }).catch(error=>{
        callback(null)
    })
} 

export function EliminarCampaña(authToken,id,appid,campaign_id,callback){
    const config = {
        headers: { Authorization: `${authToken}` }
    };
    axios.delete( 
        url_campaña+'campaign/campaign/'+appid+'/'+id,
      config
    ).then(res=>{
        axios.delete( 
            url_campaña+'campana/'+campaign_id,
            config
          ).then(res=>{
                callback(res.data)
          }).catch(err=>{
                callback(null) 
          });
    }).catch(err=>{
        callback(null) 
    });
} 

export function EliminarCampañaAdministrador(authToken,id,appid,campaign_id,tenantid,callback){
    const config = {
        headers: { Authorization: `${authToken}` }
    };
    axios.delete( 
        url_campaña+'campaign/campaign/'+appid+'/'+id,
      config
    ).then(res=>{
        axios.delete( 
            url_campaña+'system/campana/'+campaign_id+"/"+tenantid,
            config
          ).then(res=>{
                callback(res.data)
          }).catch(err=>{
                callback(null) 
          });
    }).catch(err=>{
        callback(null) 
    });
} 

export function ObtenerCampañaAdministrador(authToken,campaign,tenantid,callback){
    const config = {
        headers: { Authorization: `${authToken}` }
    };
    axios.get(
        url_campaña+'sys/campana/'+tenantid+"/"+campaign,
        config
    ).then(response=>{
        callback(response.data[0])
    }).catch(error=>{
        callback(null)
    })
} 

export function ActualizarCampaña(authToken,campaign,callback){
    const config = {
        headers: { Authorization: `${authToken}` }
    };
    axios.put(
        url_campaña+'campaign/habilitar',
        campaign,
        config
    ).then(response=>{
        callback(response.data)
    }).catch(error=>{

        callback(null)
    })
} 

export function ActualizarCampañaAdministrador(authToken,campaign,tenantid,callback){
    const config = {
        headers: { Authorization: `${authToken}` }
    };
    axios.put(
        url_campaña+'sys/campaign/habilitar/'+tenantid,
        campaign,
        config
    ).then(response=>{
        callback(response.data)
    }).catch(error=>{
        callback(null)
    })
} 