import React, { Component} from "react";
import { getTenant, getToken } from "../../../auth-helpers";
import {Link} from 'react-router-dom';
const conectormanagergenesys = require('../../../ServerFunctions/conectormanagergenesys.js');
export default class TenantConectorConexionGenesysList extends Component {
constructor(props){
    super(props);
    this.state={
        conectors:[],
        user:null
    }    
}

componentDidMount(){
    var authToken = getToken();
    var thisa= this;
    var tenant=getTenant();
    conectormanagergenesys.ListGenesysConectorAdministrador(authToken,"ObtenerNoDisponibles",tenant.TenantId,function(conectors) {
        if(conectors){
            var conexionconectores=[]
            var con_whatsapp=conectors.conectores_servicio
            var con_genesys=conectors.conectores_genesys
            var contador=0
            con_whatsapp.forEach(conector => {
                con_genesys.forEach(congen=>{
                    if(conector.conector_genesys_id==congen.conector_id){
                        contador=contador+1
                        var conexionconector={
                            conector_genesys_name:congen.nombre,
                            conector_servicio_name:conector.nombre,
                            conector_genesys_id:congen.conector_id,
                            conector_servicio_id:conector.conector_id,
                            conexion_id:contador
                        }
                        conexionconectores.push(conexionconector)
                    }    
                })
            });
            thisa.setState({
                conectors:conexionconectores
            })
        }
    })
}

    render() {
        console.log(this.state.conectors)
        var conectors=this.state.conectors;
        return (
            <>
            <div>
            <div id="cognitousers"  align="right">
                <a className="btn btn-primary" href="/conectorgenesysconexion-add">
                    <span className="glyphicon glyphicon-plus"></span> Agregar Conector
                </a>
            </div>
            <div id="cognitousers" className="table-responsive">
            <table className="table table-striped">
            <thead>
                    <tr>
                    <th>Conector Genesys</th>
                    <th>Conector Servicio</th>
                    <th>Acciones</th>
                    </tr>
                </thead>
            <tbody>
            {
                    conectors?(
                        <>
                        {conectors.map((item, i) => {
                            return (
                                <tr key={item.conexion_id}>
                                    <td>{item.conector_genesys_name}</td>
                                    <td>{item.conector_servicio_name}</td>
                                <td>
                                    <div className="btn-group">
                                        
                                        <Link
                                        className="paddingicon" title="Edit"
                                        to={{
                                            pathname: "/conectorgenesysconexion-edit",
                                            state: { conector: item }
                                        }}>
                                            <span className="glyphicon glyphicon-eye-open"></span>
                                        </Link>
                                        <Link
                                        className="paddingicon" title="Delete"
                                        to={{
                                            pathname: "/conectorgenesysconexion-delete",
                                            state: { conector: item }
                                        }}>
                                            <span className="glyphicon glyphicon-trash"></span>
                                        </Link>
                                    </div>
                                    </td>
                                </tr>
                            );
                        })}
                        </>
                    ):(
                        <>
                        </>
                    )
                }
            </tbody>
            </table>
            </div>
            </div>         

          </>

        );
    }
}