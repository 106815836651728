import React, { Component } from "react";
import { getToken } from "../../auth-helpers";
const tenantmanager = require('../../ServerFunctions/tenantmanager');
export default class TenantDelete extends Component {
constructor(props){
    super(props);
    console.log(props.location.state.tenant)
    this.state={
        id:props.location.state.tenant.tenant_id,
        companyName:props.location.state.tenant.companyName,
        accountName:props.location.state.tenant.companyName,
        ownerName: props.location.state.tenant.companyName,
        status:props.location.state.tenant.status,
        mensaje:'',
        disabled:false
    }
    this.handleDelete=this.handleDelete.bind(this);
}

handleDelete(event){
    event.preventDefault();
    this.setState({
        mensaje:'',
        disabled:true
    })
    const thisa = this; 
    var authToken = getToken();
    tenantmanager.DeleteTenant(this.state.id,authToken,function(tenant) {
        if(tenant){
            thisa.setState({
                mensaje:'Organización eliminada correctamente'
            })
            setTimeout(function(){
                window.location.href="/tenant-list"
            }, 1000);
        }else{
            thisa.setState({
                mensaje:'No se pudo eliminar la organización porfavor intentelo más tarde'
            })
            setTimeout(function(){
                window.location.href="/tenant-list"
            }, 3000);
        }
    })
}

    render() {
        return (
            <div className="auth-wrapper">
              <div className="auth-inner">
              <form >
                <p>Esta seguro de eliminar la organización: {this.state.companyName}?</p>
                <button 
                type="submit" 
                disabled={(this.state.disabled)? "disabled" : ""}
                className="paddingbutton btn btn-block btn-outline-primary" 
                onClick={this.handleDelete}>Eliminar</button>
                <a href="/tenant-list" className="paddingbutton btn btn-outline-info"  role="button" aria-disabled="true">Volver</a>  
                <div>
                <br/>
                <label>{this.state.mensaje}</label>
                </div>
            </form>
            </div>
            </div>
        );
    }
}