import React, { Component} from "react";
import { getToken, getUser, setTenant } from "../../auth-helpers";
import {Link} from 'react-router-dom';
const tenantmanager = require('../../ServerFunctions/tenantmanager');
export default class TenantList extends Component {
constructor(props){
    super(props);
    this.state={
        tenants:[],
        user:null,
    }    
    this.handleIngresar=this.handleIngresar.bind(this);
}

handleIngresar(item,event){
    event.preventDefault();
    var tenantchild= getToken()+'/'+ item.UserPoolId+'::'+
        item.IdentityPoolId+'::'+
        item.ClientId+'::'+
        item.tenant_id+'::'+
        item.companyName+'::'+
        item.PinPointId+'/'+getToken()
    setTenant(tenantchild);
    window.location.href="/"
}

componentDidMount(){
        var authToken = getToken();
        var thisa= this;
        tenantmanager.ListTenant(authToken,function(tenants) {
            thisa.setState({
                tenants
            })
        });
        var user=getUser(getToken());
        this.setState({
            user
        })
}

    render() {
        var tenants=this.state.tenants;
        var user=this.state.user;
        return (
        <div>
            <div id="cognitousers"  align="right">
                <a className="btn btn-primary" href="/tenant-add">
                    <span className="glyphicon glyphicon-plus"></span> Agregar Organización
                </a>
            </div>
            <div id="cognitousers" className="table-responsive" >
            <table className="table table-striped">
                <thead>
                    <tr>
                    <th>Organización</th>
                    <th>Propietario</th>
                    <th>Estado</th>
                    <th>Acciones</th>
                    </tr>
                </thead>
            <tbody>
                {
                    tenants?(
                        <>
                        {tenants.map((item, i) => {
                            return (
                                <tr key={item.tenant_id}>
                                    <td>{item.companyName}</td>
                                    <td>{item.ownerName}</td>
                                    <td>{item.status}</td>
                                    <td>
                                    <div className="btn-group">
                                        <Link
                                        className="paddingicon" title="Edit"
                                        to={{
                                            pathname: "/tenant-edit",
                                            state: { tenant: item }
                                        }}>
                                            <span className="glyphicon glyphicon-pencil"></span>
                                        </Link>
                                        {
                                            user&&(
                                            <>
                                                {
                                                    user["custom:tenant_id"]===item.tenant_id?(
                                                        <></>
                                                    ):(
                                                        <>
                                                            <Link
                                                            className="paddingicon" title="Delete"
                                                            to={{
                                                                pathname: "/tenant-delete",
                                                                state: { tenant: item }
                                                            }}>
                                                                <span className="glyphicon glyphicon-trash"></span>
                                                            </Link>
                                                        </>
                                                    )
                                                }
                                            </>)
                                        }
                                        
                                    </div>
                                    </td>
                                    <td>
                                        {
                                            user&&(
                                            <>
                                                {
                                                    user["custom:tenant_id"]===item.tenant_id?(
                                                        <></>
                                                    ):(
                                                        <>
                                                        <button 
                                                        className="paddingbutton btn btn-primary btn-block" 
                                                        onClick={(e) => this.handleIngresar(item, e)}>Ingresar</button>
                                                        </>
                                                    )
                                                }
                                            </>)
                                        }
                                        
                                    </td>
                                </tr>
                            );
                        })}
                        </>
                    ):(
                        <>
                        </>
                    )
                }
            </tbody>
          </table>
          </div>
          </div>         
        );
    }
}