import React, { Component } from "react";
import { getTenant, getToken } from "../../../auth-helpers.js";
import fondoimage from '../../../fondo.jpg';
import { Image } from "react-bootstrap";
import axios from 'axios';
import Modal from './Modal'
const DIV_BUTTON_STYLE = {
    position: 'relative',
    zIndex:1
  }
  const CONTENT_STYLE =  {
    background: 'red',
    padding: '20px',
    zIndex: 2
  }
const conectormanager = require('../../../ServerFunctions/conectormanager.js');
const templatemanager = require('../../../ServerFunctions/templatemanager.js');
//desarrollo
//const url_conector="https://xh4tj9f7ek.execute-api.us-east-2.amazonaws.com/dev/"
//produccion
const url_conector="https://ujgtcn03ji.execute-api.us-east-2.amazonaws.com/prod/"
export default class TenantTemplateAdd extends Component {
    constructor(props){
        super(props);
        this.state={
            user:null, 
            testparameters:[],
            border:"10px 10px 10px 10px",
            picture: fondoimage,
            headerlength:0,
            conector:[],
            isopen:false,
            bodylength:0,
            footerlenght:0,
            contador:0,
            maxlengt:60,
            buttonsac: [],
            buttons:[],
            parameters:[], 
            mesagge:'',
            disabled:props.location.state.edit,
            conectors:[],
            template:props.location.state.template,
            tenant_id:props.location.state.template.tenant_id||null,
            t_header:props.location.state.template.header||null,
            t_body:props.location.state.template.body||null,
            t_buttons:props.location.state.template.buttons||null,
            t_conector:props.location.state.template.conector||null,
            t_msg_language:props.location.state.template.msg_language||"",
            t_msg_type:props.location.state.template.msg_type||"",
            t_template:props.location.state.template.template||"",
            t_template_id:props.location.state.template.template_id||"",
            t_template_name:props.location.state.template.template_name||"",
            verificacion:props.location.state.template.verificacion||"pendiente",
            mensaje_verificado:false
        }
        this.handleCreate=this.handleCreate.bind(this);
        this.EnviarTestMessage=this.EnviarTestMessage.bind(this);
    }
    
    handleCreate(event,validar){
        event.preventDefault();
    
        if(this.state.disabled){
            return
        }
    
        this.setState({
            disabled:true,
            mensaje:"Espere mientras actualizamos la plantilla"
        })
        const template = {
            tenant_id:this.state.tenant_id,
            template_id:this.state.t_template_id,
            verificacion:''
        };
        if(validar){
            template.verificacion="true"
        }else{
            template.verificacion="reject"
        }
        if((this.state.mensaje_verificado===true && template.verificacion==="true")||(this.state.mensaje_verificado===false && template.verificacion==="reject")){
            var authToken = getToken();
            var thisa= this;
            templatemanager.EditarTemplateAdministrador(authToken,template,template.tenant_id,function(result) {
                if(result){
                    thisa.setState({
                        mensaje:'Plantilla actualizada correctamente'
                    })
                    setTimeout(function(){
                        window.location.href="/template-list"
                    }, 1000);
                }else{
                    thisa.setState({
                        mensaje:'No se puede actualizar la plantilla. Intentelo más tarde'
                    })
                    setTimeout(function(){
                        window.location.href="/template-list"
                    }, 2000);
                }
            });
            
        }else if(this.state.mensaje_verificado===false && template.verificacion==="true"){
            
            var dialog = window.confirm("El resultado del mensaje de prueba fue erroneo o no se realizo. Aun desea aprobar la plantilla.");
            if (dialog) {
                var authToken = getToken();
            var thisa= this;
            templatemanager.EditarTemplateAdministrador(authToken,template,template.tenant_id,function(result) {
                if(result){
                    thisa.setState({
                        mensaje:'Plantilla actualizada correctamente'
                    })
                    setTimeout(function(){
                        window.location.href="/template-list"
                    }, 1000);
                }else{
                    thisa.setState({
                        mensaje:'No se puede actualizar la plantilla. Intentelo más tarde'
                    })
                    setTimeout(function(){
                        window.location.href="/template-list"
                    }, 2000);
                }
            });
            }
            else {
                this.setState({
                    mensaje:'',
                    disabled:false,
                    })
            }
        }else if(this.state.mensaje_verificado===true && template.verificacion==="reject"){
            this.setState({
            disabled:false,
            mensaje:'El mensaje de prueba fue exitoso, no puede rechazar la plantilla.'
            })   
        }
            
    }
    componentDidMount(){
        var authToken = getToken();
        var tenant=getTenant()
            var thisa= this;
            conectormanager.ListConectorAdministrador(authToken,tenant.TenantId,function(conectors) {
                if(conectors){
                    conectors.forEach(element => {
                            if(element.conector_id==thisa.state.t_conector.conector_id){
                            var newconector={
                                codigo:thisa.state.t_conector.codigo,
                                conector_id:thisa.state.t_conector.conector_id,
                                namespace:thisa.state.t_conector.namespace,
                                nombre:element.nombre
                            }
                            thisa.setState({
                                t_conector:newconector,
                                conector:element
                            })
                        }
                    });
                }
                
            })
            if(this.state.tipobtn==="callaction"){
                this.setState({
                    border:"10px 10px 0px 0px",
                })
            }
    
            this.setState({
                parameters:this.state.t_body.parameters,
                buttons:this.state.t_buttons.buttons_quick,
                buttonsac:this.state.t_buttons.buttons_action
            })
    }

    EnviarTestMessage(){
        var number=this.state.number;
        var parameters=this.state.testparameters;
        var conector=this.state.conector;
        var template=this.state.template
        var list={
                var1:'',
                var2:'',
                var3:'',
                var4:'',
                var5:'',
                var6:'',
                var7:'',
                var8:'',
                var9:'',
                var10:'',
                var11:this.state.var11,
            }
            if(parameters.length>0){
                if(parameters.length>=1){
                    list.var1=parameters[0].description;
                }
                if(parameters.length>=2){
                    list.var2=parameters[1].description;
                }
                if(parameters.length>=3){
                    list.var3=parameters[2].description;
                }
                if(parameters.length>=4){
                    list.var4=parameters[3].description;
                }
                if(parameters.length>=5){
                    list.var5=parameters[4].description;
                }
                if(parameters.length>=6){
                    list.var6=parameters[5].description;
                }
                if(parameters.length>=7){
                    list.var7=parameters[6].description;
                }
                if(parameters.length>=8){
                    list.var8=parameters[7].description;
                }
                if(parameters.length>=9){
                    list.var9=parameters[8].description;
                }
                if(parameters.length>=10){
                    list.var10=parameters[9].description;
                }
            }
            
            var url=url_conector+"send"
            template.parcount=template.body.parameters.length;
            template.headertype=template.header.header_type
            template.headermedia=template.header.header_media
            template.headertext=template.header.header_text
            template.codigo=template.conector.codigo
            template.conector_id=template.conector.conector_id
            template.namespace=template.conector.namespace
            template.btnqu_varone=""
            template.btnqu_vartwo=""
            template.btnqu_varthree=""
            template.body_text=template.body.body_text
            if(template.buttons.buttons_quick.length>0){
                if(template.buttons.buttons_quick[0]!=undefined && template.buttons.buttons_quick[0]!="" &&template.buttons.buttons_quick[0]!=null){
                    template.btnqu_varone=template.buttons.buttons_quick[0].description+"/"+template.buttons.buttons_quick[0].token
                }
                if(template.buttons.buttons_quick[1]!=undefined &&template.buttons.buttons_quick[1]!=""&&template.buttons.buttons_quick[1]!=null){
                    template.btnqu_vartwo=template.buttons.buttons_quick[1].description+"/"+template.buttons.buttons_quick[1].token
                }
                if(template.buttons.buttons_quick[2]!=undefined&&template.buttons.buttons_quick[2]!=""&&template.buttons.buttons_quick[2]!=null){
                    template.btnqu_varthree=template.buttons.buttons_quick[2].description+"/"+template.buttons.buttons_quick[2].token
                }
            }
            if(this.state.number.toString()[0]=='0'){
                    number=this.state.number.substring(1)
            }
            var body={
                "username":conector.aplication_id,
                 "password":conector.private_key,
                 "from":conector.cod_whatsapp+conector.num_whatsapp.substring(1),
                 "to":this.state.codpais+number,
                 "url":conector.message_url,
                 "namespace":template.conector.namespace,
                 "name":template.template,
                 "language":template.msg_language,
                 "template":template,
                 "proveedor":conector.provider_name,
                 "datos":list
             };
             var parameter=template.body.body_text;
            for(let i=1;i<=template.body.parameters.length;i++){
                parameter=parameter.replace('{{'+i+'}}',list['var'+i])
            }
            const config={
                headers: {"content-type": "application/json"},
            }
                axios.post( 
                    url,
                    body,
                    config
                ).then(res=>{
                    this.setState({
                        mensajetest:'Mensaje enviado',
                        mensaje_verificado:true
                    })
                   
                }).catch(err=>{
                    this.setState({
                        mensajetest:'Se produjo un error de envio. Esto puede darse si el número al que se envia el mensaje no posee WhatsaApp o los parametros de envio en el template son incorrectos o puede darse por que el mensaje se mantiene en cola por mas de 3 segundos y no se logra acceder al estado del mensaje. Si el prolema persiste comuníquese con el administrador',
                    })
                });
        
        }
    
        render() {
            var btns=this.state.t_buttons.buttons_quick;
            var btnsac=this.state.t_buttons.buttons_action;
            var testparameters=this.state.testparameters;
            var parameters=this.state.parameters;
            var hora = new Date();
            var show=hora.getHours()+':'+hora.getMinutes()
            return (
               <>
                <div >
                        <br/>
                        <div className="template-inner">
                            <h2>Detalles de la plantilla</h2>
                            <br/>
                            <div className="row">
                                <div className="col">
                                <label>Nombre</label>
                                <input type="text" className="form-control" 
                                disabled
                                placeholder="Nombre" value={this.state.t_template_name}
                                />
                                </div>
                                <div className="col">
                                <label>Template </label>
                                <input type="text" className="form-control" 
                                disabled
                                placeholder="Template" value={this.state.t_template}
                                />
                                </div>
                                {
                                    this.state.condisabled?(<>
                                    <div className="col">
                                        <label>Namespace</label>
                                        <input type="text" className="form-control"
                                        disabled
                                        placeholder="Namespace" value={this.state.t_conector.namespace}
                                        />
                                        </div>
                                        
                                    </>):(<>
                                    </>)
                                }
                            </div>
                            <br/>
                            <div className="row">
                                <div className="col-sm-6">
                                <label>País</label>
                               <select 
                                    className="form-select"
                                    disabled
                                    value={this.state.t_conector.codigo}
                                >
                                    <option value='' >Seleccionar país</option>
                                        <option value='57' >Colombia</option>
                                        <option value='593' >Ecuador</option>
                                        <option value='51' >Perú</option>
                                </select> 
                                </div>
                                <div className="col-sm-6">
                                <label>Conector</label>
                                <select 
                                    className="form-select"
                                    disabled
                                    defaultValue={this.state.t_conector.nombre}
                                >
                                    <option value={this.state.t_conector.conector_id} >{this.state.t_conector.nombre}</option>
                                </select>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div className="template-inner table-responsive">
                            <h2>Estructura del mensaje</h2>
                            <br/>
                            <div className="row">
                                <div className="col-sm-8">
                                <div className="row">
                                        <div className="col">   
                                            <label>Lenguaje</label>
                                            <select className="form-select"
                                            disabled
                                            value={this.state.t_msg_language}
                                            >
                                                <option value="es">Spanish</option>
                                                <option value="en">English</option>
                                            </select>
                                        </div>
                                        <div className="col">
                                            <label>Tipo
                                            </label>
                                            <select className="form-select"
                                            disabled
                                            value={this.state.t_msg_type}
                                             >
                                                <option value="Account update">Account update</option>
                                                <option value="Auto-Reply">Auto-Reply</option>
                                                <option value="Personal finance update">Personal finance update</option>
                                                <option value="Update on tickets">Update on tickets</option>
                                                <option value="Update on alerts">Update on alerts</option>
                                                <option value="Troubleshooting">Troubleshooting</option>
                                                <option value="Reserve update">Reserve update</option>
                                                <option value="Transportation update">Transportation update</option>
                                                <option value="Date update">Date update</option>
                                                <option value="Payment update">Payment update</option>
                                                <option value="Shipping update">Shipping update</option>
                                            </select>
                                        </div>
                                    </div>
                                <div >   
                                    <br/>
                                    {
                                    this.state.condisabledcab!='none'?(<>
                                    <h4>Cabecera (Opcional)</h4>
                                <small className="text-muted">
                                Agregue un título o elija qué tipo de medio usará para este encabezado
                                </small>
                                <br/>
                                <br/>
                                <div className="row">
                                    <div className="col-md-4">   
                                        <select 
                                            style={{width:"auto"}}
                                            className="form-select"
                                            defaultValue={this.state.t_header.header_type}
                                            disabled
                                            >
                                        <option value='none'>None</option>
                                        <option value='text'>Text</option>
                                        <option value='media'>Media</option>
                                        </select>
                                    <br/>
                                    </div>
                                    <div className="row">   
                                        {
                                            this.state.t_header.header_type!=="none"&&(
                                            <>
                                            {
                                                this.state.t_header.header_type!=="text"?(
                                                    <>
                                                    <div className="col-md-4">   
                                                        <select 
                                                        style={{maxWidth:"150px"}}
                                                            className="form-select"
                                                            disabled
                                                            defaultValue={this.state.t_header.header_media}
                                                            maxlengt="300"
                                                            >
                                                            <option value='image'>Imagen</option>
                                                            <option value='file'>Archivo</option>
                                                        </select>
                                                        <br/>
                                                    </div>
                                                    <label>Nombre del archivo</label>
                                                    </>
                                                ):(
                                                    <>
                                                    <label>Texto de cabecera</label>
                                                    </>
                                                )
                                            }
                                    <div>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        disabled
                                        maxLength={this.state.maxlengt}
                                        value={this.state.t_header.header_text}
                                        />
                                        <small className="text-muted">
                                            Caracteres {
                                            this.state.t_header.header_text.length
                                            }/{this.state.maxlengt}
                                        </small>
                                        </div>
                                    </>
                                    )
                                }    
                                </div>
                                </div>
                                    
                                    </>):(<></>)}  <br/>
                                        <h4>Cuerpo</h4>
                                        <small className="text-muted">Ingrese el texto de su mensaje en el idioma que ha seleccionado</small>
                                        <br/>
                                        <br/>
                                        <div className="row">
                                            <textarea type="text" 
                                            maxLength="1024"
                                            style={{height:"100px"}}
                                            disabled
                                            className="form-control textarea" 
                                            value={this.state.t_body.body_text}
                                            />
    
                                                <div className="row">
                                                    <div className="col">
                                                        <small className="text-muted">
                                                        Caracteres {this.state.t_body.body_text.length}/1024
                                                        </small>
                                                    </div>
                                                    <div className="col">
                                                    <button disabled  className="btn btn-primary" style={{marginTop:"5px"}}>
                                                    <span className="glyphicon glyphicon-plus"></span> Agregar variable
                                                    </button>
                                                    </div>
                                                </div>
                                                
                                        </div>
                                        <br/>
                                       
                                    {
                                    this.state.condisabled?(<>
                                     <h4>Píe del mensaje (Opcional)</h4>
                                        <div className="row">
                                            <div className="col">   
                                                <small className="text-muted">Agrega una línea corta de texto al final de tu plantilla de mensaje</small>
                                                <input type="text" 
                                                    className="form-control"
                                                    disabled
                                                    maxLength='60' 
                                                    value={this.state.t_body.footer}
                                                    />
                                                <small className="text-muted">Caracteres {this.state.t_body.footer.length}/60</small>
                                            </div>
                                        </div>
                                    </>):(<></>)}    
                                    
                                        <br/>
                                        {
                                            this.state.t_buttons.buttons_type==='none'?(
                                                <></>
                                            ):(
                                                <>
                                                 <h4>Botones (Opcional)</h4>
                                        <div className="row">
                                            <div className="form-check form-check-inline">
                                            <select 
                                                style={{width:"150px"}}
                                                className="form-select"
                                                defaultValue={this.state.t_buttons.buttons_type}
                                                disabled>
                                                    <option value='none'>None</option>
                                                    <option value='callaction'>Call to Action</option>
                                                    <option value='quick'>Quick</option>
                                            </select>
                                            </div>
                                        </div>
                                        <br/>
                                          {
                                                    this.state.t_buttons.buttons_type==='callaction'?(
                                                        <>
                                                        <div className="row" >
                                                            <div className="col-md-3">   
                                                            <p>Acción</p>
                                                            <select 
                                                            style={{width:"auto", minWidth:"150px", marginBottom:"10px"}}
                                                            className="form-select" 
                                                            disabled
                                                            defaultValue="web"
                                                            >
                                                                <option value='web'>Visitar sitio web</option>
                                                                <option value='call'>Llamada a un número de teléfono</option>
                                                            </select>
                                                        
                                                        
                                                        </div>
                                                        {
                                                            btnsac.length>0?(<>
                                                             <div className="table-responsive"> 
                                                     <table className="table table-striped">
                                                    <tbody>
                                                         {
                                                             btnsac.map((item,i)=>{
                                                                 return (
                                                                     <tr key={item.index}>
                                                                         <td>
                                                                         Texto: 
                                                                            <input type="text" className="form-control" 
                                                                                disabled
                                                                                placeholder={item.texto}/>
                                                                         </td>
                                                                         <td>
                                                                         Tipo: 
                                                                         <input type="text" className="form-control" 
                                                                                disabled
                                                                                placeholder={item.tipo}/>
                                                                         </td>
                                                                            {
                                                                                item.tipo=="web"?(<>
                                                                         <td>
                                                                            Url:
                                                                                         <input type="text" className="form-control" 
                                                                                         disabled
                                                                                         placeholder={item.url}/>
                                                                         </td>
                                                                                </>):(<>
                                                                                    <td>
                                                                                    Código de país: 
                                                                                    <input type="text" className="form-control" 
                                                                                         disabled
                                                                                         placeholder={item.codigo}/>
                                                                         </td>
                                                                         <td>
                                                                                    Número de teléfono: 
                                                                                    <input type="text" className="form-control" 
                                                                                         disabled
                                                                                         placeholder={item.numero}/>
                                                                         </td>
                                                                                </>)
                                                                            }
                                                                         <td>
                                                                         </td>
                                                                     </tr>
                                                                 )
                                                             })
                                                         }
                                                    </tbody>
                                                     </table>
                                                     </div>
                                                            
                                                            </>):(<>
                                                             <div className="col-md-3">   
                                                            <p>Texto del botón</p>
                                                            <input type="text" className="form-control" 
                                                                disabled
                                                                placeholder="Button text" maxLength="20" 
                                                            />
                                                        </div>
                                                        {
                                                            this.state.action==="web"?(
                                                            <div className="col-md-3">   
                                                                <p>URL</p>
                                                                <input type="text" 
                                                                className="form-control" 
                                                                disabled
                                                                placeholder="URL" 
                                                                value={this.state.t_btnac_url}
                                                                onChange={(e)=>this.setState({
                                                                    t_btnac_url: e.target.value
                                                                })}/>
                                                            </div>
                                                            ):(
                                                                <>
                                                                <div className="col-md-3">   
                                                                    <p>Código del país</p>
                                                                    <input type="text" 
                                                                        disabled
                                                                        className="form-control"     placeholder="Country Code" maxLength="20" 
                                                                   />
                                                                </div>
                                                                <div className="col-md-3">   
                                                                    <p>Número de teléfono</p>
                                                                    <input type="text" 
                                                                        disabled
                                                                        className="form-control" placeholder="Phone Number" maxLength="20"
                                                                    />
                                                                </div>
                                                            </>
                                                            )
                                                        }
                                                            </>)
                                                        }
                                                       
                                                        
                                                    </div>
                                                        </>
                                                    ):(
                                                        <>
                                                             <div className="table-responsive"> 
                                                     <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                            <th>Opción</th>
                                                            <th>Token</th>                
                                                            </tr>
                                                        </thead>
                                                    <tbody>
                                                         {
                                                             btns.map((item,i)=>{
                                                                 return (
                                                                     <tr key={item.index}>
                                                                         <td>
                                                                         {item.description}
                                                                         </td>
                                                                         <td>
                                                                         {item.token}
                                                                         </td>
                                                                     </tr>
                                                                 )
                                                             })
                                                         }
                                                    </tbody>
                                                     </table>
                                                     </div>
                                                    </>
                                                )
                                            }    
                                            </>
                                        )
                                    }
                                        <br/>
                                        <h3>Variables</h3>
                                        <br/>
                                        {
                                            parameters.map((item,i)=>{
                                                return (
                                                    <div key={item.index}>
                                                        <div className="row"  >
                                                            <div className="col">
                                                                <label>{item.description}</label>
                                                            </div>
                                                            <div className="col">
                                                                <button 
                                                                disabled
                                                                className="btn btn-primary" 
                                                               >
                                                                    <span className="glyphicon glyphicon-trash"></span> 
                                                                </button>
                                                            </div>
                                                    </div>
                                                        <br/>
                                                    </div>
                                                )
                                            })
                                        }
                                    {
                                        this.state.disabled?(<>
                                        </>):(<>
                                            <button 
                                        disabled={(this.state.disabled)? "disabled" : ""} id="btncreate" className="paddingbutton btn btn-block btn-outline-primary"  
                                        onClick={(e)=>this.handleCreate(e,true)}>Aceptar</button>
                                        <button  
                                        disabled={(this.state.disabled)? "disabled" : ""} id="btncreate" className="paddingbutton btn btn-block btn-outline-danger"  
                                        onClick={(e)=>this.handleCreate(e,false)}>Rechazar</button>

                                       </>)
                                    }
                                       <button className="paddingbutton btn btn-block btn-outline-primary" onClick = {() => {this.setState({isopen:true})} }>Enviar Mensaje de Prueba</button>
                                     <a href="/template-list" className="paddingbutton btn btn-outline-info"  role="button" aria-disabled="true">Volver</a>  
                                        <div>
                                        <label>{this.state.mensaje}</label>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-sm-4">
                                    <br/>
                                    <br/>
                                    <div className="container" style={{backgroundImage:"url("+this.state.picture+")", width: "auto", marginLeft: "20px", paddingBottom:"20px",marginRight:"20px", minWidth:"350px"}}>
                                        <h4 style={{paddingTop:"15px"}}>Visualización</h4>
                                        <div className="row" style={{marginBottom: "8px",width: "auto"}}>
                                                <div className="col-sm" style={{paddingTop: "15px",paddingLeft: "15px", paddingRight: "30px"}}>
                                            <div className="alert-secondary" 
                                                style={{textAlign:"start" ,fontSize: "12px", paddingTop: "0px",paddingLeft: "10px",paddingRight: "10px", paddingBottom:"3px",borderRadius: this.state.border, backgroundColor:"white"}}>
                                                {
                                                    this.state.t_header.header_type==='none'?(
                                                        <></>
                                                    ):(
                                                        <>
                                                        {
                                                            this.state.t_header.header_type==='text'?(
                                                            <>
                                                                <span>{this.state.t_header.header_text}</span><br />
                                                            </>
                                                            ):(
                                                            <>
                                                            {
                                                                this.state.t_header.header_text!=''?(<>
                                                                <Image src={this.state.t_header.header_text} style={{height:"auto", width:"100%"}}></Image> 
                                                                <br/></>):(<></>)
                                                            }
                                                            </>
                                                            )
                                                        }
                                                        </>
                                                    )
                                                }
                                                <div>
                                                {this.state.t_body.body_text.split("\n").map((item,i)=>{
                                            return(<>
                                                {item}
                                                <br/>
                                            </>)
                                        })} 
                                                </div>
                                                <div className="row" >
                                                    <div className="col-sm-8">
                                                        <small className="text-muted">
                                                        {this.state.t_footer}
                                                        </small>
                                                    </div>
                                                    <div className="col-sm-4" style={{textAlign:"end", height:"auto"}}>
                                                        <small className="text-muted">
                                                        {show}
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                               
                                            {
                                                    this.state.t_buttons.buttons_type=='none'?(
                                                        <>
                                                        </>
                                                    ):(
                                                        <>
                                                        {
                                                            this.state.t_buttons.buttons_type=='callaction'?(
                                                            <>
                                                            {
                                                                btnsac.length>0?(<>
                                                                {
                                                                 btnsac.map((item,i)=>{
                                                                     
                                                                     return (
                                                                         <div key={i} className={"alert-secondary"} 
                                                                             style={{textAlign:"center",borderRight:"inset",borderLeft:"outset",marginTop:"2px",fontSize: "12px",padding:"5px", color:"blue",borderRadius: "10px 10px 10px 10px", backgroundColor:"white"}}>
                                                                             {
                                                                                item.tipo=='call'?(
                                                                                    <>
                                                                                        <span style={{paddingRight:"5px"}} className="glyphicon glyphicon-earphone"></span>
                                                                                        <label > {item.texto}</label>
                                                                                    </>
                                                                                ):(
                                                                                    <>
                                                                                    <label > {item.texto}</label>
                                                                                    </>
                                                                                )
                                                                            }   
                                                                         </div>
                                                                     )})
                                                             }
                                                                </>):(<>
                                                                    </>)
                                                            }
                                                           
                                                            </>
                                                            ):(
                                                                <>
                                                                <div className="row" style={{margin:"1px"}}>
                                                                {
                                                                    btns.map((item,i)=>{
                                                                        var estilo=" col-sm-6"
                                                                        if(i==2){
                                                                            estilo=" col-sm-12"
                                                                        }
                                                                        return (
                                                                            <div key={i} className={"alert-secondary"+ estilo} 
                                                                                style={{textAlign:"center",borderRight:"inset",borderLeft:"outset",marginTop:"2px",fontSize: "12px",padding:"5px", color:"blue",borderRadius: "10px 10px 10px 10px", backgroundColor:"white"}}>
                                                                                <label>{item.description}</label>
                                                                            </div>
                                                                        )})
                                                                }
                                                                <br/>
    
                                                                </div>
                                                                </>
                                                            )
                                                        }
                                                    </>
                                                    )
                                                }
    
                                        </div>
                                        
                                    </div>
                               </div>
                                </div>
                            </div>
    
                        </div>
                        <br/>
                 </div>
                 <Modal open = {this.state.isopen} close = { () => {this.setState({isopen:false})}}>
                    <div>
                        <h4>Mensaje de prueba</h4>
                        <br/>
                    <div className="row">
                            <div className="col-sm-3">
                                <h6>Cód. país</h6>
                            </div>
                            <div className="col-sm-5">
                            <h6>Teléfono</h6>
                            </div>
                            <div className="col-sm-4">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3">
                                <input type="number" className="form-control" 
                                style={{maxWidth:"50px", minWidth:"30px"}}
                                placeholder="Código" value={this.state.codpais}
                                onChange={(e)=>this.setState({
                                    codpais: e.target.value
                                })}
                                />
                            </div>
                            <div className="col-sm-5">
                                <input type="number" className="form-control" 
                                style={{maxWidth:"150px", minWidth:"100px"}}
                                placeholder="Teléfono" value={this.state.number}
                                onChange={(e)=>this.setState({
                                    number: e.target.value
                                })}
                                />
                            </div>
                            <div className="col-sm-4">
                            <button type="submit" 
                                className="paddingbutton btn btn-primary btn-block" 
                                onClick={(e)=>{
                                    e.preventDefault();
                                    
                                    if(this.state.number!==undefined && this.state.number!==""){
                                        if(this.state.codpais=="593"){
                                            if(this.state.number.length!=10){
                                                this.setState({
                                                    mensajetest:'Longitud de número incorrecto'
                                                })
                                                return false;
                                            }else{
                                                if(this.state.number.toString()[0]!='0'){
                                                    this.setState({
                                                        mensajetest:'Formato de número incorrecto. Debe tener cero como primer dígito'
                                                    })
                                                    return false;
                                                }
                                            }
                                        }else if(this.state.codpais=="57"){
                                            if(this.state.number.length!=10){
                                                this.setState({
                                                    mensajetest:'Longitud de número incorrecto'
                                                })
                                                return false;
                                            }else{
                                                if(this.state.number.toString()[0]!='3'){
                                                    this.setState({
                                                        mensajetest:'Formato de número incorrecto. El primer digito debe ser tres'
                                                    })
                                                    return false;
                                                }
                                            }
                                        }else if(this.state.codpais=="51"){
                                            if(this.state.number.length!=9){
                                                this.setState({
                                                    mensajetest:'Longitud de número incorrecto'
                                                })
                                                return false;
                                            }else{
                                                if(this.state.number.toString()[0]!='9'){
                                                    this.setState({
                                                        mensajetest:'Formato de número incorrecto. El primer digito debe ser nueve'
                                                    })
                                                    return false;
                                                }
                                            }
                                        }else{
                                            this.setState({
                                                mensajetest:'Ingrese código de pais'
                                            })
                                            return false;
                                        }
                                        if(testparameters.length!==parameters.length){
                                                    this.setState({
                                                        mensajetest:'Número de variables para la plantilla es incorrecto'
                                                    })
                                        }else{
                                            if(this.state.template.header.header_type!='none'){
                                                        if(this.state.template.header.header_type!='text'){
                                                            if(this.state.var11!=''){
                                                                this.setState({
                                                                    mensajetest:'Enviando mensaje',
                                                                })
                                                                this.EnviarTestMessage()                                        
                                                            }else{
                                                                this.setState({
                                                                    mensajetest:'En base a la plantilla de la campaña falta el url del archivo (imagen/pdf) a enviar',
                                                                })
                                                            }
                                                        }else{
                                                            this.setState({
                                                                    mensajetest:'Enviando mensaje',
                                                            })
                                                            this.EnviarTestMessage()
                                                        }
                                            }else{
                                                        this.setState({
                                                                mensajetest:'Enviando mensaje',
                                                        })
                                                        this.EnviarTestMessage()
                                            }
                                        }
                                    }else{
                                        this.setState({
                                            mensajetest:'Ingrese el número de telefono',
                                        })
                                    }
                                    
                                }}>Enviar</button>
                            </div>
                        </div>
                <br/>
                <div>
                {this.state.template.header.header_type!='none'&&(<>
                            {
                                this.state.template.header.header_type!='text'?(<>
                                    <h5>Agregar url del documento cabecera ({this.state.template.header.header_media})</h5>
                                        <input className="form-control" 
                                            style={{maxWidth:"auto", minWidth:"100px"}}
                                            placeholder="Url" value={this.state.var11}
                                            onChange={(e)=>this.setState({
                                                var11: e.target.value
                                        })}/>
                                </>):(<>
                                </>)
                            }
                            
                    </>)}
                </div>
                <br/>
                    <h5>Agregar valores a las variables del mensaje</h5>
                    <br/>
                    {
                        testparameters.map((item,i)=>{
                            return (
                                <div key={item.index}>
                                   <div className="row"  >
                                    <div className="col-sm-3"></div>
                                        <div className="col-sm-3">
                                            <label>{item.description}</label>
                                        </div>
                                        <div className="col-sm-3">
                                            <button className="btn btn-primary" 
                                                onClick={(e)=>{
                                                    e.preventDefault();
                                                    var par=[];
                                                    for(var i=0; i<this.state.contador;i++){
                                                        if(testparameters[i].index!==item.index){
                                                            par=par.concat(testparameters[i]);
                                                        }
                                                    }
                                                    this.setState({
                                                        testparameters:par
                                                    })
                                                }}>
                                                <span className="glyphicon glyphicon-trash"></span> 
                                            </button>
                                        </div>
                                        <div className="col-sm-3"></div>
                                    </div>
                                    <br/>
                                </div>
                            )
                        })
                    }
                        <div className="row">
                          <div className="col-sm-2"></div>
                                 <div className="col-sm-5">
                                     <input  
                                         type="text" 
                                         className="form-control" 
                                         placeholder="valor" 
                                         style={{minWidth:"100px"}}
                                         value={this.state.valpar}
                                         onChange={(e)=>this.setState({
                                             valpar: e.target.value
                                         })}/>
                                 </div>
                                 <div className="col-sm-3">
                                     <button 
                                     className="btn btn-primary" onClick={(e)=>{
                                         e.preventDefault()
                                         if(this.state.valpar!==''){
                                             if(testparameters.length<parameters.length){
                                                 const btn={
                                                     description:this.state.valpar,
                                                     index:parameters.length
                                                 }
                                                 this.setState({
                                                     testparameters:this.state.testparameters.concat(btn),
                                                     valpar:'',
                                                     mesaggepar:''
                                                 })
                                             }else{
                                                 this.setState({
                                                     mesaggepar:'No se puede exceder el número de variables de la plantilla'
                                                 })
                                             }
                                         }else{
                                             this.setState({
                                                 mesaggepar:'Campos vacios'
                                             })
                                         }
                                     }}>
                                         <span className="glyphicon glyphicon-plus"></span> 
                                     </button>
                                 </div>
                                 <div className="col-sm-2"></div>
                    </div>
                    <div>{this.state.mesaggepar}</div>
                <br/>
                    <label>{this.state.mensajetest}</label>
                    </div>                       
                 </Modal>
                </>
            );
        }
}