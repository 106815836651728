import React, { Component } from "react";
import { getTenant, getToken } from "../../../auth-helpers";
const conectormanagergenesys = require('../../../ServerFunctions/conectormanagergenesys.js');
export default class TenantConectorEdit extends Component {
constructor(props){
    super(props);
    this.state={
        id:props.location.state.conector.conector_id,
        tenant_id:props.location.state.conector.tenant_id,
        deployment_id:props.location.state.conector.deployment_id,
        nombre:props.location.state.conector.nombre,
        tipo:props.location.state.conector.tipo,
        client:props.location.state.conector.client_id,
        secret:props.location.state.conector.secret_client,
        disabled:false,
    }
    this.handleEdit=this.handleEdit.bind(this);
}


handleEdit(event){
    event.preventDefault();
    this.setState({
        disabled:true,
        mensaje:'Espere mientras se actualiza el Conector'
    })

    if(this.state.tipo==="web_messaging"){
        if(this.state.deployment_id=="" || this.state.tipo=="" ||
        this.state.nombre=="" ){
            this.setState({
                mensaje:'Campos vacios',
                disabled:false
            })
            return false
        }
    }else{
        if(this.state.deployment_id=="" || this.state.tipo=="" ||
        this.state.nombre=="" || this.state.client=="" || this.state.secret==""){
            this.setState({
                mensaje:'Campos vacios',
                disabled:false
            })
            return false
        }
    }
     
    var conector={
        conector_id:this.state.id,
        deployment_id:this.state.deployment_id,
        nombre:this.state.nombre,
        tipo:this.state.tipo,
        client_id:this.state.client,
        secret_client:this.state.secret
    }
  var authToken = getToken();
    var thisa=this;
    var tenant=getTenant();
    conectormanagergenesys.EditarGenesysConectorAdministrador(authToken,conector,tenant.TenantId, function(conectors) {
        if(conectors){
            if(conectors.status==="success"){
                thisa.setState({
                    mensaje:"Conector actualizado correctamente"
                })
                setTimeout(function(){
                    window.location.href="/conectorgenesys-list"
                }, 1000);
            }else{
                thisa.setState({
                    mensaje:"No se pudo actualizar el conector. Intentelo más tarde"
                })
                
                setTimeout(function(){
                    window.location.href="/conectorgenesys-list"
                }, 2000);
            }
        }else{
            thisa.setState({
                mensaje:"No se pudo actualizar el conector. Intentelo más tarde"
            })
            
            setTimeout(function(){
                window.location.href="/conectorgenesys-list"
            }, 2000);
        }
    }); 
}

render() {
    return (
        <>
        <div>
        <br/>
                <div className="template-inner" style={{maxWidth:"650px"}}>
                <h3>Editar Conector</h3>
                 <br/>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Nombre del conector</label>
                            <div className="col-sm-9">
                                <input 
                                    type="text" 
                                    disabled={(this.state.disabled)? "disabled" : "" }
                                    className="form-control" 
                                    placeholder="Nombre del conector" 
                                    value={this.state.nombre}
                                    onChange={(e)=>this.setState({
                                    nombre: e.target.value
                                })}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label  className="col-sm-3 col-form-label">Deployment ID</label>
                            <div className="col-sm-9">
                            <input  
                                type="text" 
                                disabled={(this.state.disabled)? "disabled" : "" }
                                className="form-control" 
                                placeholder="Deployment ID" 
                                value={this.state.deployment_id}
                                onChange={(e)=>this.setState({
                                deployment_id: e.target.value
                                })}/>
                                </div>
                        </div>
                        <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Tipo</label>
                       <div className="col-sm-9">
                            <select 
                            className="form-select"
                            disabled={(this.state.disabled)? "disabled" : "" }
                            value={this.state.tipo}
                            onChange={
                                (e)=>{
                                    this.setState({
                                        tipo: e.target.value
                            })}}
                            >
                            <option value='web_messaging' >Web Messaging</option>
                            <option value='open_messaging' >Open Messaging</option>
                        </select>
                            </div>
                        </div>
                        {
                           this.state.tipo=='open_messaging'?(<>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Client Id</label>
                                <div className="col-sm-9">
                                    <input 
                                        disabled={(this.state.disabled)? "disabled" : ""}
                                        type="text" 
                                            className="form-control" 
                                            placeholder="Genesys Client" 
                                            value={this.state.client}
                                            onChange={(e)=>this.setState({
                                                client: e.target.value
                                                })}
                                        />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Secret Client</label>
                                <div className="col-sm-9">
                                    <input 
                                    disabled={(this.state.disabled)? "disabled" : ""}
                                    type="password" 
                                        className="form-control" 
                                        placeholder="Genesys Secret" 
                                        value={this.state.secret}
                                        onChange={(e)=>this.setState({
                                            secret: e.target.value
                                            })}
                                    />
                                </div>
                            </div>
                           </>):(<></>)
                       }
                    <button className="paddingbutton btn btn-block btn-outline-primary" 
                    onClick={this.handleEdit}
                    disabled={(this.state.disabled)? "disabled" : "" }> Actualizar </button>
                    <a href="/conectorgenesys-list" className="paddingbutton btn btn-outline-info"  role="button" aria-disabled="true">Volver</a>  
                          <div>
                        <label>{this.state.mensaje}</label>
                    </div>
                </div>
                <br/>
            </div>
            </>
    );
}
}