import axios from "axios";
import React, { Component } from "react";
import { getTenant, getToken } from "../../../../auth-helpers";
const campañamanager = require('../../../../ServerFunctions/campaignmanager');

export default class TenantCampaignDelete extends Component {
constructor(props){
    super(props);
    console.log(props.location.state.campaign)
    this.state={
        name:props.location.state.campaign.Name,
        id:props.location.state.campaign.Id,
        created:props.location.state.campaign.CreationDate,
        appid:props.location.state.campaign.ApplicationId
    }
    this.handleDelete=this.handleDelete.bind(this);
}

componentDidMount(){
    var authToken = getToken();
    var thisa= this;
    var tenant=getTenant();
    this.setState({
        disabled:true
    })
     campañamanager.getcampañasys(authToken,this.state.id,tenant.TenantId,function(camp){
        if(camp){
            console.log(camp)
            thisa.setState({
                disabled:false,
                campaign_id:camp.campaign_id
            })
        }
    })
}
handleDelete(event){
    event.preventDefault();
    this.setState({
        disabled:true,
        mensaje:"Espere mientrase se elimina la campaña"
    })
    var authToken = getToken();
    var tenant=getTenant();
    var thisa=this;
    campañamanager.deletecampañasys(authToken,this.state.id,this.state.appid,this.state.campaign_id,tenant.TenantId,function(camp){
      if(camp){
          console.log(camp)
          thisa.setState({
              mensaje:"Campaña eliminada exitosamente",
          })
          setTimeout(function(){
              window.location.href="/campaign-list"
           },1000)
      }else{
          thisa.setState({
              mensaje:"No se pudo eliminar la campaña. intentelo más tarde.",
          })
          setTimeout(function(){
              window.location.href="/campaign-list"
           },2000)
      }
  })
    
}

    render() {
        return (
            <div className="auth-wrapper">
              <div className="auth-inner">
              <form role="form">
                <p>Esta seguro de eliminar la campaña: {this.state.name} creada el {this.state.created}?</p>
                <button type="submit" className="paddingbutton btn btn-primary btn-block" 
                disabled={(this.state.disabled)? "disabled" : ""}
                onClick={this.handleDelete}>Eliminar</button>
                <a href="/campaign-list" className="btn btn-secondary "  role="button" aria-disabled="true">Volver</a>  
                <div>
                    <label>{this.state.mensaje}</label>
                </div>
            </form>
            </div>
            </div>
        );
    }
}