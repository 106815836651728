import React, { Component } from "react";
import jwtDecode from "jwt-decode";
import {Link} from 'react-router-dom';
import { getTenant, getToken } from "../../../../auth-helpers";
const campañamanager = require('../../../../ServerFunctions/campaignmanager');
const usermanager = require('../../../../ServerFunctions/usermanager');
export default class TenantSegmentList extends Component {
constructor(props){
    super(props);
    this.state={
        segments:[],
        appid:''
    }    
}

componentDidMount(){
            var tenant=getTenant();
            var authToken = getToken();
            var thisa= this;
                campañamanager.ListSegmentosAdministrador(authToken,tenant.TenantId,function(segments){
                    thisa.setState({
                        segments:segments,
                        appid:tenant.PinPointId
                    })
                })
}

render() {
    var segments=this.state.segments;
    return (
    <div>
        <div id="cognitousers"  align="right">

            <Link
                className="btn btn-primary" title="Delete"
                to={{
                    pathname: "/segment-add",
                    state: { applicationid:this.state.appid }
                }}>
                <span className="glyphicon glyphicon-plus"></span> Agregar Lista de contactos
            </Link>
        </div>
        <div id="cognitousers" className="table-responsive">
        <table className="table table-striped">
            <thead>
                <tr>
                <th>Id</th>
                <th>Nombre</th>
                <th>País</th>
                <th>Fecha de creación</th>
                <th>Número de registros</th>
                <th>Acciones</th>
                </tr>
            </thead>
        <tbody>
            {
                segments?(
                    <>
                    {segments.map((item, i) => {
                        return (
                            <tr key={item.Id}>
                                <td>{item.segment_id}</td>
                                <td>{item.nombre}</td>
                                <td>
                                    {item.codigo=="593"?("EC"):(
                                        <>
                                            {
                                                item.codigo=="51"?("PE"):("COL")
                                            }
                                        </>)}
                                    </td>
                                <td>{item.fecha}</td>
                                <td>{item.registros}</td>
                                <td>
                                <div className="btn-group">
                                <Link
                                    className="paddingicon" title="Edit"
                                    to={{
                                        pathname: "/segment-edit",
                                        state: { segment: item }
                                    }}>
                                        <span className="glyphicon glyphicon-eye-open"></span>
                                    </Link>
                                    <Link
                                    className="paddingicon" title="Delete"
                                    to={{
                                        pathname: "/segment-delete",
                                        state: { segment: item }
                                    }}>
                                        <span className="glyphicon glyphicon-trash"></span>
                                    </Link>
                                </div>
                                </td>
                            </tr>
                        );
                    })}        
                </>
                ):(
                    <>
                    </>
                )
            }
        </tbody>
      </table>
      </div>
      </div>         
    );
}
}