import React, { Component } from "react";
import jwtDecode from "jwt-decode";
import {Link} from 'react-router-dom';
import { getTenant, getToken } from "../../../../auth-helpers";
const campañamanager = require('../../../../ServerFunctions/campaignmanager');
const usermanager = require('../../../../ServerFunctions/usermanager');
export default class TenantCampaignList extends Component {
constructor(props){
    super(props);
    this.state={
        campaigns:[]
    }    
}

componentDidMount(){ 
        var authToken = getToken();
        var thisa= this;
        var tenant=getTenant();
        if(authToken){
            campañamanager.ListCampaña(authToken,tenant.PinPointId,function(campaigns){
                thisa.setState({
                    campaigns:campaigns,
                    appid:tenant.PinPointId
                })
            })
        }
           
}

render() {
    var campaigns=this.state.campaigns;
    return (
    <div>
        <div id="cognitousers"  align="right">
            <Link
            className="btn btn-primary" title="Delete"
            to={{
                pathname: "/campaign-add",
                state: { applicationid:this.state.appid }
            }}>
            <span className="glyphicon glyphicon-plus"></span> Crear Campañas
            </Link>
            </div>
        <div id="cognitousers" className="table-responsive">
        <table className="table table-striped">
            <thead>
                <tr>
                <th>Nombre</th>
                <th>Id</th>
                <th>Fecha de Creación</th>
                <th>Calendarizado</th>
                <th>Estado</th>
                <th>Acciones</th>
                </tr>
            </thead>
        <tbody>
            {
                campaigns?(
                    <>
                    {campaigns.map((item, i) => {
                        return (
                            <tr key={item.Id}>
                                <td>{item.Name}</td>
                                <td>{item.Id}</td>
                                <td>{item.CreationDate.toLocaleString()}</td>
                                {
                                    item.Schedule.StartTime==="IMMEDIATE"?(
                                        <td>{item.Schedule.StartTime}</td>
                                    ):(
                                        <td>{item.Schedule.Frequency}</td>
                                    )
                                }
                                <td>{item.State.CampaignStatus}</td>
                                <td>
                                <div className="btn-group">
                                <Link
                                    className="paddingicon" title="View"
                                    to={{
                                        pathname: "/campaign-edit",
                                        state: { campaign: item }
                                    }}>
                                        <span className="glyphicon glyphicon-eye-open"></span>
                                    </Link>
                                     {
                                        item.State.CampaignStatus!="COMPLETED"?(
                                    <Link
                                        className="paddingicon" title="Delete"
                                        to={{
                                            pathname: "/campaign-delete",
                                            state: { campaign: item }
                                        }}>
                                        <span className="glyphicon glyphicon-trash"></span>
                                    </Link>
                                        ):(
                                            <></>
                                        )
                                    }
                                    
                                </div>
                                </td>
                            </tr>
                        );
                    })}        
                </>
                ):(
                    <>
                    </>
                )
            }
        </tbody>
      </table>
      </div>
      </div>         
    );
}
}