import React, { Component } from "react";
import { getToken } from "../../auth-helpers";
const conectormanager = require('../../ServerFunctions/conectormanager.js');
export default class ProviderDelete extends Component {
constructor(props){
    super(props);
    this.state={
        id:props.location.state.provider.provider_id,
        typeName:props.location.state.provider.tipo_nombre,
        typeChannel:props.location.state.provider.tipo_canal,
        typeProvider:props.location.state.provider.tipo_proveedor,
        typeVersion:props.location.state.provider.tipo_version,
        typeApi:props.location.state.provider.tipo_api,
        mensaje:'',
        disabled:false,
 
    }
    this.handleDelete=this.handleDelete.bind(this);
}

handleDelete(event){
    event.preventDefault();
    this.setState({
        disabled:true,
        mensaje:'Espere mientras se elimina el proveedor'
    })
    const thisa = this; 
    var authToken = getToken();
    conectormanager.EliminarProvider(this.state.id,authToken,function(tenant) {
        if(tenant){
            thisa.setState({
                mensaje:tenant.mensaje
            })
            setTimeout(function(){
                window.location.href="/provider-list"
            }, 1000);

        }else{
            thisa.setState({
                mensaje:'No se pudo eliminar el proveedor. Intentelo más tarde',
                disabled:false,
            })
            setTimeout(function(){
                window.location.href="/provider-list"
            }, 2000);

        }
    })
}

    render() {
        return (
            <div className="auth-wrapper">
              <div className="auth-inner">
              <form role="form">
                <p>Esta seguro de eliminar el proveedor: {this.state.typeName}?</p>
                <button type="submit" 
                   disabled={(this.state.disabled)? "disabled" : ""}
                   className="paddingbutton btn btn-block btn-outline-primary" onClick={this.handleDelete}>Eliminar</button>
                <a href="/provider-list" className="paddingbutton btn btn-outline-info"  role="button" aria-disabled="true">Volver</a>  
                <div>
                    <label>{this.state.mensaje}</label>
                </div>
            </form>
            </div>
            </div>
        );
    }
}