import React, { Component } from "react";
import jwtDecode from "jwt-decode";
import {Link} from 'react-router-dom';
import { getToken } from "../../../auth-helpers";
import LoadingOverlay from 'react-loading-overlay-ts';
import 'datatables.net-dt/js/dataTables.dataTables.min.js';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
const $ = require('jquery');
$.DataTable = require('datatables.net');
const campañagenesysmanager = require('../../../ServerFunctions/genesyscampaignmanager');
export default class SegmentGenesysList extends Component {
constructor(props){
    super(props);
    this.state={
        segments:[],
        isActive:true
    }    
}

componentDidMount(){
        var authToken = getToken();
        var thisa= this;
        var usr;
        if(authToken){
            var user = jwtDecode(authToken);
            usr={
                companyName:user['custom:companyName'],
                email:user['email'],
                appid:user['custom:appid']
            };
        }
        campañagenesysmanager.ListSegmentos(authToken,function(segments){
                if(segments){
                    thisa.setState({
                        segments:segments,
                        isActive:false
                    })
                }
              $('#templatesPC').DataTable();
            })
}

    render() {
        var segments=this.state.segments;
        var isActive=this.state.isActive;
        return (
            <LoadingOverlay
            active={isActive}
            spinner
            styles={{
              wrapper: {
                width: '100%',
                height: '100%',
                overflow: isActive ? 'hidden' : '',
              },
            }}
            text="Cargando ..."
          >
               <div>
            <div id="cognitousers"  align="right">

                <Link
                    className="btn btn-primary" title="Delete"
                    to={{
                        pathname: "/genesys-add",
                        state: { applicationid:this.state.appid }
                    }}>
                    <span className="glyphicon glyphicon-plus"></span> Cargar base
                </Link>
            </div>
            <div id="cognitousers" className="table-responsive">
            <table id="templatesPC" className="display"    style={{ width: '100%', float: 'left' }}>
                <thead>
                    <tr>
                    <th>Nombre Base</th>
                    <th>Campaña</th>
                    <th>Número de registros</th>
                    <th>Acciones</th>
                    </tr>
                </thead>
            <tbody>
                {
                    segments?(
                        <>
                        {segments.map((item, i) => {
                            return (
                                <tr key={item.campaign_id}>
                                    <td>{item.nombre}</td>
                                    <td>{item.campaign_name}</td>
                                    <td>{item.registros}</td>
                                    <td>
                                    <div className="btn-group">
                                    <Link
                                        className="paddingicon" title="Edit"
                                        to={{
                                            pathname: "/genesys-edit",
                                            state: { segment: item }
                                        }}>
                                            <span className="glyphicon glyphicon-eye-open"></span>
                                        </Link>
                                        <Link
                                        className="paddingicon" title="Delete"
                                        to={{
                                            pathname: "/genesys-delete",
                                            state: { segment: item }
                                        }}>
                                            <span className="glyphicon glyphicon-trash"></span>
                                        </Link>
                                    </div>
                                    </td>
                                </tr>
                            );
                        })}        
                    </>
                    ):(
                        <>
                        </>
                    )
                }
            </tbody>
          </table>
          </div>
          </div> 
          </LoadingOverlay>
               
        );
    }
}