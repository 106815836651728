import axios from "axios";
import React, { Component } from "react";
import { getToken } from "../../../auth-helpers";
const campañamanager = require('../../../ServerFunctions/campaignmanager');
export default class CampaignDelete extends Component {
constructor(props){
    super(props);
    this.state={
        name:props.location.state.campaign.Name,
        id:props.location.state.campaign.Id,
        created:props.location.state.campaign.CreationDate,
        appid:props.location.state.campaign.ApplicationId,
        campaign_id:"",
        disabled:true
    }
    this.handleDelete=this.handleDelete.bind(this);
}

componentDidMount(){
    var authToken = getToken();
    var thisa= this;
    console.log(this.state.id)
     campañamanager.ObtenerCampaña(authToken,this.state.id,function(camp){
        if(camp){
            console.log(camp)
            thisa.setState({
                disabled:false,
                campaign_id:camp.campaign_id
            })
        }
    })
}


handleDelete(event){
    event.preventDefault();
    this.setState({
        disabled:true,
        mensaje:"Espere mientrase se elimina la campaña"
    })
      var authToken = getToken();
      var thisa=this;
      campañamanager.EliminarCampaña(authToken,this.state.id,this.state.appid,this.state.campaign_id,function(camp){
        if(camp){
            console.log(camp)
            thisa.setState({
                mensaje:"Campaña eliminada exitosamente",
            })
            setTimeout(function(){
                window.location.href="/campaign-list"
             },1000)
        }else{
            thisa.setState({
                mensaje:"No se pudo eliminar la campaña. intentelo más tarde.",
            })
            setTimeout(function(){
                window.location.href="/campaign-list"
             },2000)
        }
    })
}

    render() {
        console.log(this.state.disabled)
        return (
            <div className="auth-wrapper">
              <div className="auth-inner">
              <form role="form">
                <p>Esta seguro de eliminar la campaña: {this.state.name} creada el {this.state.created}?</p>
                <button type="submit" className="paddingbutton btn btn-primary btn-block" 
                disabled={(this.state.disabled)? "disabled" : ""}
                onClick={this.handleDelete}>Eliminar</button>
                <a href="/campaign-list" className="btn btn-secondary "  role="button" aria-disabled="true">Volver</a>  
                <div>
                    <label>{this.state.mensaje}</label>
                </div>
            </form>
            </div>
            </div>
        );
    }
}