import React, { Component } from "react";
import { getToken } from "../../auth-helpers";
import jwtDecode from "jwt-decode";
const usermanager = require('../../ServerFunctions/usermanager');
export default class UserEdit extends Component {
constructor(props){
    super(props);
    this.state={
        first:props.location.state.user.firstName,
        last:props.location.state.user.lastName,
        email:props.location.state.user.userName,
        rol:props.location.state.user.role,
        disabled:false
    }
    this.handleCreate=this.handleCreate.bind(this);
}

componentDidMount(){
    var authToken = getToken();
    var usr=jwtDecode(authToken);
    this.setState({
        role:usr['custom:role']
    })
} 

handleCreate(event){
    event.preventDefault();
    this.setState({
            mensaje:"Espere mientras actualizamos el usuario",
            disabled:true
    })
    if(this.state.first===""||this.state.last===""||this.state.email===""){
        this.setState({
            mensaje:"Campos vacios",
            disabled:false
        })
        return;
    }
    var user={
      firstName:this.state.first,
      lastName: this.state.last,
      role: this.state.rol,
      userName:this.state.email
    }
    var authToken = getToken();
    var thisa=this;
    usermanager.EditarUser(authToken,user, function(users) {
        if(users){
                localStorage.setItem('userupdate','true');
                thisa.setState({
                    mensaje:"Usuario actualizado correctamente"
                })
                setTimeout(function(){
                    window.location.href="/user-list"
                }, 1000);
        }else{
            thisa.setState({
                disabled:false,
                mensaje:"No se pudo actualizar la información del usuario. Intentelo más tarde"
            })
        }
    });
}

    render() {
        return (
            <div className="auth-wrapper">
              <div className="auth-inner">
                <h2>Actualizar información de usuario</h2>
                <div className="form-group">
                <label class="form-label">Nombre</label>
                <div className="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">@</span>
                        </div>
                        <input  
                    disabled={(this.state.disabled)? "disabled" : ""}
                    type="text" 
                    className="form-control" 
                    placeholder="Nombre" 
                    value={this.state.first}
                    onChange={(e)=>this.setState({
                        first: e.target.value
                        })}/>
                    </div>
                    </div>

                <div className="form-group">
                    <label class="form-label">Apellido</label>
                    <div className="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">@</span>
                        </div>
                        <input 
                            type="text" 
                            disabled={(this.state.disabled)? "disabled" : ""}
                                    className="form-control" 
                            placeholder="Apellido" 
                            value={this.state.last}
                            onChange={(e)=>this.setState({
                                last: e.target.value
                                })}
                            />
                    </div>
                    
                </div>

                <div className="form-group">
                <label className="form-label">Rol</label>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <label className="input-group-text">Opciones</label>
                    </div>
                    <select 
                    className="form-control" 
                    disabled={(this.state.disabled)? "disabled" : ""}
                    value={this.state.rol}
                    onChange={(e)=>this.setState({
                        rol:e.target.value
                    })}>
                    {
                        this.state.role==="SystemAdmin"?(<>
                    <option value='SystemAdmin'>Administrador del sistema</option>
                        </>):(<></>)

                    }
                    <option value='TenantAdmin'>Administrador</option>
                    <option value='TenantUser'>Usuario</option>
                    </select>

                    </div>
                                    </div>
                <button type="submit" className="paddingbutton btn btn-block btn-outline-primary" 
                 disabled={(this.state.disabled)? "disabled" : ""}
                 onClick={this.handleCreate}>Actualizar</button>
                <a href="/user-list" className="paddingbutton btn btn-outline-info"  role="button" aria-disabled="true">Volver</a>  
                <div>
                    <br/>
                <label>{this.state.mensaje}</label>
                </div>
            </div>
            </div>
        );
    }
}