import React, { Component } from "react";
import { Image } from 'react-bootstrap';
import { getToken } from "../../../auth-helpers";
import fondoimage from '../../../fondo.jpg';
const templatemanager = require('../../../ServerFunctions/templatemanager.js');
const conectormanager = require('../../../ServerFunctions/conectormanager.js');
export default class SegmentEdit extends Component {
constructor(props){
    super(props);
    this.state={
       name:props.location.state.segment.nombre,
       id:props.location.state.segment.segment_id,
       created:props.location.state.segment.fecha,
       numreg:props.location.state.segment.registros,
       template_id:props.location.state.segment.template_id,
        btns:[],
        buttons:[],
        buttonsac:[],
        picture: fondoimage,
        border:"10px 10px 10px 10px",
        template:null,
        conector:null,
        n_conector:'',
        chsm_conector:'',
        num_conector:''
    }
    
    this.LoadTemplate= this.LoadTemplate.bind(this);
}

LoadTemplate(template){
    this.setState({
        mensaje:"Cargando conector"
    })
    if(template.buttons.buttons_type==="callaction"){
        this.setState({
            border:"10px 10px 0px 0px",
        })
    }else{
        this.setState({
            border:"10px 10px 10px 10px",
        })
    }

    
    this.setState({
        buttons:template.buttons.buttons_quick,
        buttonsac:template.buttons.buttons_action
    })
    var authToken = getToken();
    var thisa= this;
    this.setState({
        disabled:true
    })
    conectormanager.ObtenerConector(authToken,template.conector.conector_id,function(conector){
        if(conector){
            thisa.setState({
                n_conector:conector.nombre,
                chsm_conector:conector.costo_hsm,
                num_conector:conector.num_whatsapp,
                cod_conector:conector.cod_whatsapp,
                conector:conector,
                conector_id:conector.conector_id,
                disabled:false,
                mensaje:""
            })
        }
    })
    
}


componentDidMount(){
    var authToken = getToken();
    var thisa= this;
     templatemanager.ObtenerTemplate(authToken,this.state.template_id,function(template) {
        thisa.setState({
            template
        })
        thisa.LoadTemplate(template)
    });
}

    render() {
        var btns=this.state.buttons;
        var btnsac=this.state.buttonsac;
        var hora = new Date();
        var show=hora.getHours()+':'+hora.getMinutes()
        return (
            <div style={{textAlign:"start"}}>
                <br/>
                <form >
               <br/>
                <div className="template-inner">
                    <div className="row">
                        <h2>Lista de Contactos</h2>
                    </div>
                    <div className="row">
                        <div className="col-sm-5">
                            <label>Nombre</label>
                            <div >{this.state.name}</div>
                            <label>Id </label>
                            <div>{this.state.id}</div>
                        </div>
                        <div className="col-sm-4">
                            <label>Fecha de creación</label>
                            <div>{this.state.created}</div>
                        </div>
                        <div className="col-sm-3">
                            <label>Número de registros</label>
                            <div >{this.state.numreg}</div>
                        </div>
                    </div>
                    <br/>
                    <div style={{textAlign:"end"}}>
                    <a href="/segment-list" className="btn btn-secondary"  role="button" aria-disabled="true">Volver</a>  
                    </div>
                </div>
               <br/>
                <div className="template-inner table-responsive">
                <h2>PLANTILLA</h2>
                <br/>
                    <div className="row" style={{alignContent:"center"}}>
                                {this.state.template?(
                                    <>
                                    <div className="row">
                                        <div className="col-sm-5" style={{paddingRight:"30px"}}>
                                            <h4>Información del conector</h4>
                                                <div className="form-group row">
                                                    <label className="col-sm-8 col-form-label">Nombre</label>
                                                    <p className="col-sm-4 col-form-label">{this.state.n_conector}</p>
                                                </div>
                                                
                                                <div className="form-group row">
                                                    <label className="col-sm-8 col-form-label">País</label>
                                                    <p className="col-sm-4 col-form-label">{this.state.template.conector.codigo=="593"?("EC"):(
                                                                                <>
                                                                                {
                                                                                    this.state.template.conector.codigo=="51"?("PE"):("COL")
                                                                                }</>)}</p>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-sm-8 col-form-label"># Número</label>
                                                    <p className="col-sm-4 col-form-label">{this.state.num_conector}</p>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-sm-8 col-form-label">Costo por mensaje</label>
                                                    <p className="col-sm-4 col-form-label">{this.state.chsm_conector}</p>
                                                </div>
                                        </div>
                                        <div className="col-sm-7" >
                                            <br/>
                                            <div className="container" style={{backgroundImage:"url("+this.state.picture+")",minWidth:"250px", width: "auto", marginLeft: "20px", paddingBottom:"20px", maxWidth:"350px"}}>

                                            <h4 style={{paddingTop:"15px"}}>Visualización</h4>
                                            <div className="row" style={{marginBottom: "8px",width: "auto"}}>
                                                    <div className="col-sm" style={{paddingTop: "15px",paddingLeft: "15px", paddingRight: "30px"}}>
                                                        <div className="alert-secondary" 
                                                            style={{textAlign:"start" ,fontSize: "12px", paddingTop: "0px",paddingLeft: "10px",paddingRight: "10px", paddingBottom:"3px",borderRadius: this.state.border, backgroundColor:"white"}}>
                                                            {
                                                                this.state.template.header.header_type==='none'?(
                                                                    <></>
                                                                ):(
                                                                    <>
                                                                    {
                                                                        this.state.template.header.header_type==='text'?(
                                                                        <>
                                                                            <span>{this.state.template.header.header_text}</span><br />
                                                                        </>
                                                                        ):(
                                                                        <>
                                                                        {
                                                                            this.state.template.header.header_text!=''?(<>
                                                                            <Image src={this.state.template.header.header_text} style={{height:"auto", width:"100%"}}></Image> 
                                                                            <br/></>):(<></>)
                                                                        }
                                                                        </>
                                                                        )
                                                                    }
                                                                    </>
                                                                )
                                                            }
                                                            <div>
                                                            {this.state.template.body.body_text.split("\n").map((item,i)=>{
                                                                return(<>
                                                                    {item}
                                                                    <br/>
                                                                </>)
                                                            })} 
                                                            </div>
                                                            <div className="row" >
                                                                <div className="col-sm-8">
                                                                    <small className="text-muted">
                                                                    {this.state.template.body.footer}
                                                                    </small>
                                                                </div>
                                                                <div className="col-sm-4" style={{textAlign:"end", height:"auto"}}>
                                                                    <small className="text-muted">
                                                                    {show}
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                            
                                                        {
                                                                this.state.template.buttons.buttons_type=='none'?(
                                                                    <>
                                                                    </>
                                                                ):(
                                                                    <>
                                                                    {
                                                                        this.state.template.buttons.buttons_type=='callaction'?(
                                                                            <>
                                                                            {
                                                                                btnsac.length>0?(<>
                                                                                {
                                                                                 btnsac.map((item,i)=>{
                                                                                     
                                                                                     return (
                                                                                         <div key={i} className={"alert-secondary"} 
                                                                                             style={{textAlign:"center",borderRight:"inset",borderLeft:"outset",marginTop:"2px",fontSize: "12px",padding:"5px", color:"blue",borderRadius: "10px 10px 10px 10px", backgroundColor:"white"}}>
                                                                                             {
                                                                                                item.tipo=='call'?(
                                                                                                    <>
                                                                                                        <span style={{paddingRight:"5px"}} className="glyphicon glyphicon-earphone"></span>
                                                                                                        <label > {item.texto}</label>
                                                                                                    </>
                                                                                                ):(
                                                                                                    <>
                                                                                                    <label > {item.texto}</label>
                                                                                                    </>
                                                                                                )
                                                                                            }   
                                                                                         </div>
                                                                                     )})
                                                                             }
                                                                                </>):(<>
                                                                                    </>)
                                                                            }
                                                                           
                                                                            </>
                                                                            ):(
                                                                            <>
                                                                            <div className="row" style={{margin:"1px"}}>
                                                                            {
                                                                                btns.map((item,i)=>{
                                                                                        var estilo=" col-sm-12"
                                                                                        if(btns.length>1){
                                                                                        var estilo=" col-sm-6"
                                                                                        if(i==2){
                                                                                            estilo=" col-sm-12"
                                                                                        }
                                                                                    }
                                                                                    
                                                                                    return (
                                                                                        <div key={i} className={"alert-secondary"+ estilo} 
                                                                                            style={{textAlign:"center",borderRight:"inset",borderLeft:"outset",marginTop:"2px",fontSize: "12px",padding:"5px", color:"blue",borderRadius: "10px 10px 10px 10px", backgroundColor:"white"}}>
                                                                                            <label>{item.description.split("/")[0]}</label>
                                                                                        </div>
                                                                                    )})
                                                                            }
                                                                            <br/>

                                                                            </div>
                                                                            </>
                                                                        )
                                                                    }
                                                                </>
                                                                )
                                                            }

                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                     </>
                                ):(
                                    <></>
                                )}
                     </div>
                </div>
             
               </form>
            </div>
        );
    }
}