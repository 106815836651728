import React, { Component } from "react";
import { getToken } from "../../../auth-helpers";
import Papa from 'papaparse';// Encargado de parsear
import fondoimage from '../../../fondo.jpg';
import 'datatables.net-dt/js/dataTables.dataTables.min.js';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
const $ = require('jquery');
$.DataTable = require('datatables.net');
const campanagenesysmanager = require('../../../ServerFunctions/genesyscampaignmanager');
const { v4: uuidv4 } = require('uuid');

export default class SegmentGenesysAdd extends Component {
constructor(props){
    super(props); 
    this.state={ 
        filas:[],
        filt:[],
        cabeceras:[],
        name:"",
        campaign_name:"",
        picture: fondoimage,
        templates:[],
        border:"10px 10px 10px 10px",
        buttons:[],
        buttonsac:[],
        mensaje:"",
        msgdos:"",
        checkedId:"",
        template:null,
        conector:null,
        file:null,
        n_conector:'',
        chsm_conector:'',
        num_conector:'',
        disabled:false,
        codwhats:""
    }
    this.Cargar=this.Cargar.bind(this);
    this.updateData= this.updateData.bind(this);
    this.handleCreate= this.handleCreate.bind(this);
}


Cargar(csvfile){
    this.setState({
        filas:[],
        cabeceras:[]
    })
    Papa.parse(csvfile, {
        complete: this.updateData,
        header: true
      });
}



updateData(result) {
    var rdata = result.data;
    var data=[]
    var error="false";
    var existe=false;
    for(let i=0;i<rdata.length;i++){
        var telefono=""
        if(rdata[i].telefono!=undefined){
            telefono=rdata[i].telefono.trim()
        }
        if( rdata[i].TELEFONO!=undefined){
            telefono=rdata[i].TELEFONO.trim()
        }
        if(telefono!=""){
            rdata[i].telefono=telefono;
            data.push(rdata[i])
        }
    }
    this.setState({
        numfila:data.length,
        filt:data,
        filas:data.slice(0,10)
    })
    var cabeceras=[];
    
    for(var key in data[0]){
        cabeceras=cabeceras.concat(key);
        console.log(key)
        if(key===""){
            error="Existe una columna sin nombre en el archivo cargado"
        }else{
            if(key==="telefono" || key==="TELEFONO"){
                existe=true;
            }
        }
    }

    if(!existe){
        error="No existe la columna telefono o TELEFONO en el archivo cargado"
    }
    if(error==="false"){
        this.setState({
            cabeceras:cabeceras,
            msgdos:""
        })    
    }else{
        this.setState({
            msgdos:error,
            file:null
           })
    }
  }

  handleCreate(event){
    event.preventDefault();
    var rdata=this.state.filt;
    var data=[]
    this.setState({
        disabled:true,
        mensaje:''
    })
    if(this.state.campaign_name=="" || this.state.name=="" || this.state.file==null){
        this.setState({
            mensaje:'Parámetros incompletos. Ingrese el nombre de la base, la descripcion de la campaña y carge la base en un archivo csv',
            disabled:false
        })
        return false;
    }else{
        for(let i=0;i<rdata.length;i++){
            rdata[i].campana=this.state.campaign_name
            rdata[i].base_name=this.state.name
            data.push(rdata[i])
        }
        this.setState({
            disabled:true,
            mensaje:'Espere mientras cargamos la base de datos'
        })   
        var envios=Math.ceil(data.length/200);
        var registros=0
        var registrosfallidos=0
        for(let i=0;i<envios;i++){
            var token=getToken();
            var ndato=[]
            if(i==0){
                ndato=data.slice(0,200)
            }else{
                ndato=data.slice(i*200,(i+1)*200)
            }
            var thisa=this
            var token=getToken();
            campanagenesysmanager.InsertarBase(token,ndato,function(res){
                if(res){
                    registros=registros+res.registros
                    registrosfallidos=registrosfallidos+res.registrosfallidos
                    if(registros==data.length){
                        
                        var segmento={
                            "campaign_name":thisa.state.campaign_name,
                            "nombre":thisa.state.name,
                            "registros":registros
                        }
                        campanagenesysmanager.InsertarSegmento(token,segmento,function(data,err){
                            if(data){
                                thisa.setState({
                                    mensaje:"Se cargaron todos los registros: "+registros
                                })
                            }else{
                                thisa.setState({
                                    mensaje:"Error al generar registro de la base de datos."
                                })
                            }
                        })
                    }else{
                        thisa.setState({
                            mensaje:"Registros cargados: "+ registros+ " Registros fallidos: "+ registrosfallidos
                        })
                    }
                }else{
                    
                    thisa.setState({
                        mensaje:"Existen registros que no se han cargado. Intentelo nuevamente, si el problema persite comuniquese con el administrador "
                    })
                }
            });
        }
    }
  }


    render() {
        const filascsv= this.state.filas;
        const cabeceras= this.state.cabeceras;
        return (
            <div style={{textAlign:"start"}}>
                <br/>
                <form >
                <div className="template-inner">
                    <h2 className="btndescarga" >Cargar Base (Archivo CSV) </h2>
                    <h6 style={{opacity:0.5}} >Base de datos para campaña con genesys</h6>
                    <br/>
                    <div className="row">
                        <div className="col-sm-4">
                            <label>Nombre de Base de Datos</label>
                            <input type="text" className="form-control" 
                                disabled={(this.state.disabled)? "disabled" : ""}
                                placeholder="Nombre del archivo" value={this.state.name}
                                minLength="6"
                                maxLength="20"
                                onChange={(e)=>{
                                        this.setState({
                                            name: e.target.value,
                                            mensaje:""
                                        })
                                }
                            }
                            />
                        </div>
                        <div className="col-sm-4">
                            <label>Identificador de Campaña</label>
                            <input type="text" className="form-control" 
                                disabled={(this.state.disabled)? "disabled" : ""}
                                placeholder="Identificador de Campaña" value={this.state.campaign_name}
                                minLength="6"
                                maxLength="20"
                                onChange={(e)=>{
                                    this.setState({
                                        campaign_name: e.target.value.toLocaleLowerCase(),
                                        mensaje:""
                                    })
                                }
                            }
                            />
                        </div>
                        
                    </div>
                    <br/>
                    <div className="row">
                    <div className="col-sm-8">
                            <label>Cargar Lista de Contactos</label>
                            <input type="file" className="form-control"
                                disabled={(this.state.disabled)? "disabled" : ""}
                                onChange={(e)=>{
                                    if(e.target.files[0]){
                                        if(e.target.files[0].name.split(".")[1]!=="csv"){
                                            this.setState({
                                                file:null,
                                                mensaje:"Formato de archivo incorrecto",
                                                filas:[],
                                                msgdos:""
                                                })
                                        }else{
                                            this.setState({
                                                file: e.target.files[0],
                                                mensaje:"",
                                                msgdos:""
                                            })
                                            this.Cargar(e.target.files[0]);
                                        }
                                    }
                                }}
                            />
                            <label>{this.state.msg}</label>
                        </div>
                    </div>

                    <button
                    disabled={(this.state.disabled)? "disabled" : ""}
                    id="createuser" className="paddingbutton btn btn-primary btn-block" onClick={this.handleCreate}>Crear</button>
                    <a href="/genesys-list" className="btn btn-secondary "  role="button" aria-disabled="true">Volver</a>  
                    <div>
                    <label>{this.state.mensaje}</label>
                    </div>
                </div>
                <br/>
                {
                    filascsv.length===0?(
                        <></>
                    ):(
                        <> 
                          <div className="template-inner">
                            <h2>Lista de Contactos</h2>
                            
                            <div className="row">
                            <label>{this.state.msgdos}</label>
                            <div className="row">
                                {
                                    this.state.msgdos===""?(
                                        <>
                                            <label>Número de registros: {parseInt(this.state.numfila)}</label>
                                        </>
                                    ):(
                                        <></>
                                    )
                                }
                            </div>
                            <div id="cognitousers" className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>{
                                                cabeceras?(
                                                    <>
                                                        {
                                                            cabeceras.map((item,i)=>{
                                                                return(
                                                                    <th key={item}>{item}</th>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                ):(
                                                    <></>
                                                )
                                            }
                                        </tr>
                                    </thead>
                                <tbody>
                                    {
                                        filascsv?(
                                            <>
                                            {filascsv.map((item, a) => {
                                                return (
                                                    <tr key={a}>
                                                        {
                                                            cabeceras?(
                                                                <>
                                                                    {
                                                                        cabeceras.map((itema,i)=>{
                                                                            return(
                                                                                <th key={a+i}>{item[itema]}</th>
                                                                            )
                                                                        })
                                                                    }
                                                                </>
                                                            ):(
                                                                <></>
                                                            )
                                                        }
                                                    </tr>
                                                );
                                            })}        
                                        </>
                                        ):(
                                            <>
                                            </>
                                        )
                                    }
                                </tbody>
                            </table>
                            </div>
                            </div>
                        </div>
                    
                        </>
                    )
                }
                </form>
            </div>
        );
    }
}