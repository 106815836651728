import React, { Component } from "react";
import { getToken } from "../../auth-helpers";
const tenantmanager = require('../../ServerFunctions/tenantmanager');
export default class TenantAdd extends Component {
constructor(props){
    super(props);
    this.state={
        companyName:'',
        email:'',
        role:'TenantAdmin',
        first:'',
        last:'',
        accountName:'',
        ownerName: '',
        userName:'',
        mensaje:'',
        disabled:false
    }
    this.handleCreate=this.handleCreate.bind(this);
}

handleCreate(event){
    event.preventDefault();
    this.setState({
        mensaje:'Espere mientras creamos la nueva organización. Esto puede tardar varios minutos.',
        disabled:true
    })
   var tenantAdminData = {
        tenant_id: '',
        companyName: this.state.companyName,
        accountName: this.state.companyName,
        ownerName: this.state.email,
        email: this.state.email,
        userName: this.state.email,
        firstName: this.state.first,
        lastName: this.state.last,
        };
    const thisa = this; 
   if(this.state.companyName!=='' && this.state.email!=='' && this.state.first!==''&&this.state.last!==''){
    var authToken = getToken();
    tenantmanager.RegisterTenant(tenantAdminData,authToken,function(tenant) {
        if(tenant){
            thisa.setState({
                mensaje:'Nueva Organización creada'
            })
            var time=2000
            setTimeout(function(){
                console.log("CAMBIANDO")
                window.location.href="/tenant-list"
            }, time);
        }else{
            thisa.setState({
                mensaje:'Esta organización ya existe',
                disabled:false
            })
        }
    })
   }else{
        this.setState({
            mensaje:'Campos vacios',
            disabled:false
        })
   }
}

    render() {
        return (
            <div className="auth-wrapper">
              <div className="auth-inner">
           
                <h2>Crear nueva organización</h2>

                <div className="form-group">
                    <label>Nombre de organización</label>
                    <input  
                    disabled={(this.state.disabled)? "disabled" : ""}
                    type="text" 
                    className="form-control" 
                    placeholder="Nombre de organización" 
                    value={this.state.companyName}
                    onChange={(e)=>{
                        console.log(e.target.value)
                        if( /[^A-Za-z\d]/.test(e.target.value)) {
                            console.log("AQUI")
                            this.setState({
                                companyName: e.target.value.substring(0, e.target.value.length - 1),
                                mensaje:"No se aceptan espacios ni caracteres especiales."
                            })
                        }else{
                            this.setState({
                                companyName: e.target.value,
                                mensaje:""
                            })
                        }
                    }}/>
                </div>
                <br/>
                <h3>Crear administrador de la organización</h3>

                <div className="form-group">
                <label class="form-label">Nombre</label>
                <div className="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">@</span>
                        </div>
                        <input  
                            disabled={(this.state.disabled)? "disabled" : ""}
                            type="text" 
                            className="form-control" 
                            placeholder="Nombre" 
                            value={this.state.first}
                            onChange={(e)=>this.setState({
                        first: e.target.value
                        })}/>
                        </div>
                </div>
                <div className="form-group">
                <label class="form-label">Apellido</label>
                <div className="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">@</span>
                        </div>
                        <input 
                    disabled={(this.state.disabled)? "disabled" : ""}
                    type="text" 
                    className="form-control" 
                    placeholder="Apellido" 
                    value={this.state.last}
                    onChange={(e)=>this.setState({
                        last: e.target.value
                        })}
                    />
                    </div>
                    
                </div>
                <div className="form-group">
                <label className="form-label">Correo</label>
                <div className="input-group mb-3">
                <input 
                    disabled={(this.state.disabled)? "disabled" : ""}
                    type="email" 
                    className="form-control" 
                    placeholder="Correo electrónico" 
                    value={this.state.email}
                    onChange={(e)=>this.setState({
                        email: e.target.value
                        })}
                    />
                    <div className="input-group-append">
                    <span className="input-group-text" id="basic-addon2">@example.com</span>
                </div>
                    </div>
                    
                </div>

                <button disabled={(this.state.disabled)? "disabled" : ""} 
                className="paddingbutton btn btn-block btn-outline-primary" onClick={this.handleCreate}>Crear</button>
                <a href="/tenant-list" className="paddingbutton btn btn-outline-info " disabled={(this.state.disabled)? "disabled" : ""}  role="button" aria-disabled="true">Volver</a>  
                <div>
                    <br/>
                <label>{this.state.mensaje}</label>
                </div>
            </div>
            </div>
        );
    }
}