import React, { Component } from "react";
import { getToken } from "../../../auth-helpers";
import 'datatables.net-dt/js/dataTables.dataTables.min.js';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import { findAllByDisplayValue } from "@testing-library/react";
const $ = require('jquery');
$.DataTable = require('datatables.net');
const campañagenesysmanager = require('../../../ServerFunctions/genesyscampaignmanager');

export default class SegmentGenesysEdit extends Component {
constructor(props){
    super(props);
    this.state={
       name:props.location.state.segment.nombre,
       numreg:props.location.state.segment.registros,
       campana:props.location.state.segment.campaign_name,
    }
}

handleExport(event){
    event.preventDefault();
    var items=this.state.contactos;
    var agents=this.state.agentes;
    for(let i=0;i<agents.length;i++){
      items.push(agents[i])
    }
    var filename= this.state.campaignid;
    var headers={
      user_id:'Id',
      numero_contacto:'Contacto',
      medio_envio:'TipoEnvio',
      fecha_hora:'FechaInteraccion',
      conector_name:'Conector',
      template_name:'Template',
      estado_mensaje:"Estado",
      codigo_error:"Codigo",
      mensaje_error:"Mensaje",
      detalle_error:"Detalle",
    }
  var agentes=false;
  var campana=false;
    if(agents.length>0){
      headers.agent_id="AgenteId"
      headers.agente="Agente"
      agentes=true
    }
    if(items.length>0){
      headers.usuario="Usuario"
      campana=true
    }
    const jsonObject = JSON.stringify(items);
  const csv = this.convertToCSV(jsonObject,headers,agentes,campana);
    const exportName = filename + ".csv" || "export.csv";
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, exportName);
    } else {
        console.log("AQUI2")
        const link = document.createElement("a");
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportName);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
  }
  }
  convertToCSV(objArray,headers,agentes,campana) {
  const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  let str = "Id,Contacto,TipoEnvio,FechaEnvio(UTC),Conector,Template,EstadoEnvio,CodigoError,MensajeError,DetalleError"
  if(agentes){
  str=str+",AgenteId,Agente"
  }
  if(campana){
  str=str+",Usuario"
  }
  str=str+ "\r\n";
  for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in headers) {
          if (line != ""){
              line += ",";
          }
          var text =array[i][index];
          var tipo=array[i]["medio_envio"]
          if(headers[index]=="FechaEnvio(UTC)"){
            text= (new Date(text)).toLocaleString()
          }
          if(headers[index]=="Usuario"){
            if(tipo=="campaña"){
              text=array[i]["agente"]
            }
          }
          if(headers[index]=="Agente"){
            if(tipo=="campaña"){
              text="-"
            }
          }
          if(text===undefined){
            text="-"
        }else if(text===""){
            text="-"
        }
          line += text;
      }
      str += line + "\r\n";
  }
  
  return str;
  }

componentDidMount(){
    var authToken = getToken();
    var thisa= this;
    campañagenesysmanager.ObtenerInfo(authToken,this.state.campana,function(segments){
        if(segments){
            console.log(segments)
            thisa.setState({
                segments:segments,
                isActive:findAllByDisplayValue
            })
        }
      $('#CampaignPC').DataTable();
    })
  
}

    render() {
        var segmentos=this.state.segments
        return (
            <div style={{textAlign:"start"}}>
                <br/>
                <form >
               <br/>
                <div className="template-inner">
                    <div className="row">
                        <h2>Base de datos</h2>
                    </div>
                    <div className="row">
                        <div className="col-sm-5">
                            <label>Nombre</label>
                            <div >{this.state.name}</div>
                        </div>
                        <div className="col-sm-4">
                            <label>Campaña</label>
                            <div>{this.state.campana}</div>
                        </div>
                        <div className="col-sm-3">
                            <label>Número de registros</label>
                            <div >{this.state.numreg}</div>
                        </div>
                    </div>
                    <br></br>
                    <div className="row">
                        <div  className="col-sm-6" style={{textAlign:"start"}}>
                       </div>
                        <div  className="col-sm-6" style={{textAlign:"end"}}>
                        <a href="/genesys-list" className="btn btn-secondary"  role="button" aria-disabled="true">Volver</a>  
                        </div>
                    </div>
                </div>
               <br/>
               <div  className="template-inner">
                 
                    <br/>
                        <h3 style={{opacity:0.6}}>Registros totales asociados a la campaña: <b>{this.state.campana}</b> </h3>
                        <div className="row" style={{alignContent:"center"}}>
                        <div  
                        disabled={(this.state.disabled)? "disabled" : ""}
                        style={{maxHeight:"500px"}} className="table-responsive">
                           <table  id="CampaignPC"
                                className="display"
                                style={{ width: '100%', float: 'left' }}>
                                    <thead>
                                        <tr>
                                        <th>Telefono</th>
                                        <th>Base de Datos</th>
                                        <th>Nombre</th>
                                        <th>Apellido</th>
                                        <th>Producto</th>
                                        <th>Prima</th>
                                        <th>Información Bancaria</th>
                                        <th>Frecuencia</th>
                                        <th>Link</th>
                                        <th>RESPUESTA CAMPAÑA</th>
                                        <th>RESPUESTA PREGUNTA</th>
                                        <th>CORREO</th>
                                        </tr>
                                    </thead>
                                <tbody>
                                    {
                                        segmentos?(
                                            <>
                                            { segmentos.map((item, i) => {
                                                    return (
                                                        <>{
                                                                <>
                                                                     <tr key={item.segment_id}>
                                                                    <td>{item.telefono}</td>
                                                                    <td>{item.base_name}</td>
                                                                    <td>{item.NOMBRE}</td>
                                                                    <td>{item.APELLIDO}</td>
                                                                    <td>{item.PRODUCTO}</td>
                                                                    <td>{item.PRIMA}</td>
                                                                    <td>{item.INFOBANCARIA}</td>
                                                                    <td>{item.FRECUENCIA}</td>
                                                                    <td>{item.LINK}</td>
                                                                    <td>{item.RespuestaCampana}</td>
                                                                    <td>{item.RespuestaPregunta}</td>
                                                                    <td>{item.Correo}</td>
                                                                    </tr>
                                                            </>
                                                        }
                                                        </>
                                                       
                                                    );
                                            })}
                                            </>
                                        ):(
                                            <>
                                            </>
                                        )
                                    }
                                </tbody>
                                </table>
                        </div>
                     </div>
                        </div>
               
             
               </form>
            </div>
        );
    }
}