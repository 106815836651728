import { RemoteChunkSize } from "papaparse";
import React, { Component } from "react";
import { getToken } from "../../auth-helpers";
const conectormanager = require('../../ServerFunctions/conectormanager.js');
export default class ProvoderEdit extends Component {
constructor(props){
    super(props);
    this.state={
        id:props.location.state.provider.provider_id,
        typeName:props.location.state.provider.tipo_nombre,
        typeChannel:props.location.state.provider.tipo_canal,
        typeProvider:props.location.state.provider.tipo_proveedor,
        typeVersion:props.location.state.provider.tipo_version,
        typeApi:props.location.state.provider.tipo_api,
        disabled:false
    }
    this.handleEdit=this.handleEdit.bind(this);
}

handleEdit(event){
    event.preventDefault();
    this.setState({
        disabled:true,
        mensaje:'Espere mientras se actualiza el conector'
    })
    
    if(this.state.typeName=="" || this.state.typeChannel=="" || this.state.typeProvider=="" ||
    this.state.typeVersion==""  || this.state.typeApi=="" ){
        this.setState({
            mensaje:'Campos vacios',
            disabled:false
        })
        return
    }
    
    var provider={
        provider_id:this.state.id,
        tipo_nombre:this.state.typeName,
        tipo_canal:this.state.typeChannel,
        tipo_proveedor:this.state.typeProvider,
        tipo_version:this.state.typeVersion,
        tipo_api:this.state.typeApi,
    }
    var authToken = getToken();
    var thisa=this;
    conectormanager.EditarProvider(authToken,provider, function(provider) {
        if(provider){
            if(provider.status==="success"){
                thisa.setState({
                    mensaje:"Proveedor actualizado correctamente"
                })
                setTimeout(function(){
                    window.location.href="/provider-list"
                }, 1000);
    
            }else{
                thisa.setState({
                    mensaje:"No se pudo actualizar el proveedor. Intentelo más tarde",
                })
                setTimeout(function(){
                    window.location.href="/provider-list"
                }, 2000);
            }
        }else{
            thisa.setState({
                mensaje:"No se pudo actualizar el proveedor. Intentelo más tarde",
            })
            setTimeout(function(){
                window.location.href="/provider-list"
            }, 2000);
        }
    });
}

render() {
    return (
        <>
        <div>
        <br/>
            <div className="template-inner" style={{maxWidth:"650px"}}>
                <h2>Editar Proveedor</h2>
                 <br/>
                <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Nombre</label>
                    <div className="col-sm-9">
                    <input 
                    type="text" 
                    disabled={(this.state.disabled)? "disabled" : "" }
                    className="form-control" 
                    placeholder="Nombre" 
                    value={this.state.typeName}
                    onChange={(e)=>this.setState({
                        typeName: e.target.value
                        })}
                    />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Canal</label>
                    <div className="col-sm-9">
                    <input 
                    type="text" 
                    disabled={(this.state.disabled)? "disabled" : "" }
                    className="form-control" 
                    placeholder="Canal" 
                    value={this.state.typeChannel}
                    onChange={(e)=>this.setState({
                        typeChannel: e.target.value
                        })}
                    />
                    </div>
                </div>

                <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Proveedor</label>
                    <div className="col-sm-9">
                    <input 
                    type="text" 
                    className="form-control" 
                    disabled={(this.state.disabled)? "disabled" : "" }
                    placeholder="Proveedor" 
                    value={this.state.typeProvider}
                    onChange={(e)=>this.setState({
                        typeProvider: e.target.value
                        })}
                    />
                    </div>
                </div>
                
                <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Versión</label>
                    <div className="col-sm-9">
                    <input 
                    type="text" 
                    disabled={(this.state.disabled)? "disabled" : "" }
                    className="form-control" 
                    placeholder="Versión" 
                    value={this.state.typeVersion}
                    onChange={(e)=>this.setState({
                        typeVersion: e.target.value
                        })}
                    />
                    </div>
                </div>
                
                <div className="form-group row">
                    <label className="col-sm-3 col-form-label">API</label>
                    <div className="col-sm-9">
                    <input 
                    type="text" 
                    disabled={(this.state.disabled)? "disabled" : "" }
                        className="form-control" 
                    placeholder="Api" 
                    value={this.state.typeApi}
                    onChange={(e)=>this.setState({
                        typeApi: e.target.value
                        })}
                    />
                    </div>
                </div>
                <button className="paddingbutton btn btn-block btn-outline-primary" 
                onClick={this.handleEdit}
                disabled={(this.state.disabled)? "disabled" : "" }> Actualizar</button>
                <a href="/provider-list" className="paddingbutton btn btn-outline-info"  role="button" aria-disabled="true">Volver</a>  
                <div>
                    <label>{this.state.mensaje}</label>
                </div>
                </div>
                <br/>
            </div>
            </>

    );
}
}