import React, { Component} from "react";
import { getToken } from "../../auth-helpers";
import {Link} from 'react-router-dom';
import jwtDecode from 'jwt-decode'
const conectormanager = require('../../ServerFunctions/conectormanager.js');
export default class ProviderList extends Component {
constructor(props){
    super(props);
    this.state={
        providers:[],
        user:null
    }    
}

componentDidMount(){
    const token=getToken();
    if(token){
      var user = jwtDecode(token);
      if(user['custom:role']==="SystemAdmin" || user['custom:role']==="TenantAdmin" ){
        this.setState({
            user:user
        })
        var authToken = getToken();
        var thisa= this;
        conectormanager.ListProviders(authToken,function(providers) {
            thisa.setState({
                providers
            })
        });
    }else{
        window.location.href="/"
      }
    }
}

    render() {
        var providers=this.state.providers;
        return (
            <>
            <div>
            <div id="cognitousers"  align="right">
                <a className="btn btn-primary" href="/provider-add">
                    <span className="glyphicon glyphicon-plus"></span> Agregar Provedor
                </a>
            </div>
            <div id="cognitousers" className="table-responsive">
            <table className="table table-striped">
                <thead>
                    <tr>
                    <th>Nombre</th>
                    <th>Canal </th>
                    <th>Proveedor </th>
                    <th>Versión </th>
                    <th>API </th>
                    <th>Acciones</th>
                    </tr>
                </thead>
            <tbody>
                {
                    providers?(
                        <>
                        {providers.map((item, i) => {
                            return (
                                <tr key={item.provider_id}>
                                    <td>{item.tipo_nombre}</td>
                                    <td>{item.tipo_canal}</td>
                                    <td>{item.tipo_proveedor}</td>
                                    <td>{item.tipo_version}</td>
                                    <td>{item.tipo_api}</td>
                                <td>
                                    <div className="btn-group">
                                        
                                        <Link
                                        className="paddingicon" title="Edit"
                                        to={{
                                            pathname: "/provider-edit",
                                            state: { provider: item }
                                        }}>
                                            <span className="glyphicon glyphicon-pencil"></span>
                                        </Link>
                                        <Link
                                        className="paddingicon" title="Delete"
                                        to={{
                                            pathname: "/provider-delete",
                                            state: { provider: item }
                                        }}>
                                            <span className="glyphicon glyphicon-trash"></span>
                                        </Link>
                                    </div>
                                    </td>
                                </tr>
                            );
                        })}
                        </>
                    ):(
                        <>
                        </>
                    )
                }
            </tbody>
            </table>
            </div>
            </div>         

          </>

        );
    }
}