import React, { Component } from "react";
import { getTenant, getToken } from "../../../auth-helpers";
const conectormanagergenesys = require('../../../ServerFunctions/conectormanagergenesys');
export default class TenantConectorEdit extends Component {
constructor(props){
    super(props);
    this.state={
        conector_genesys_id:props.location.state.conector.conector_genesys_id,
        conector_genesys_name:props.location.state.conector.conector_genesys_name,
        conector_servicio_id:props.location.state.conector.conector_servicio_id,
        conector_servicio_name:props.location.state.conector.conector_servicio_name,
        disabled:true,
        conectores_genesys:[{
            conector_id:props.location.state.conector.conector_genesys_id,
            nombre:props.location.state.conector.conector_genesys_name
        }],
        conectores_servicio:[{
            conector_id:props.location.state.conector.conector_servicio_id,
            nombre:props.location.state.conector.conector_servicio_name
        }]
    }
}

    render() {
        return (
            <>
            <div>
            <br/>
            <div className="template-inner" style={{maxWidth:"650px"}}>
                <h3>Conectores</h3>
                <br/>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Seleccionar conector genesys</label>
                            <div className="col-sm-9">
                            <select 
                            className="form-select"
                            disabled={(this.state.disabled)? "disabled" : "" }
                            >
                                    <option value={this.state.conector_genesys_name} >{this.state.conector_genesys_name}</option>
                        </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Seleccionar conector de servicio</label>
                            <div className="col-sm-9">
                            <select 
                            className="form-select"
                            disabled={(this.state.disabled)? "disabled" : "" }
                            >
                                <option  value={this.state.conector_servicio_name} >{this.state.conector_servicio_name}</option>
                        </select>
                            </div>
                        </div>

                        <a href="/conectorgenesysconexion-list" className="paddingbutton btn btn-outline-info"  role="button" aria-disabled="true">Volver</a>  
                        <div>
                            <label>{this.state.mensaje}</label>
                        </div>
                    </div>
                    <br/>
                </div>
                </>
        );
    }
}