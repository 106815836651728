import React, { Component } from "react";
import { getTenant, getToken } from "../../../auth-helpers";
const conectormanagergenesys = require('../../../ServerFunctions/conectormanagergenesys');
export default class TenantConectorDelete extends Component {
constructor(props){
    super(props);
    this.state={
        conector_genesys_id:props.location.state.conector.conector_genesys_id,
        conector_genesys_name:props.location.state.conector.conector_genesys_name,
        conector_servicio_id:props.location.state.conector.conector_servicio_id,
        conector_servicio_name:props.location.state.conector.conector_servicio_name,
        disabled:true,
        mensaje:'',
        disabled:false,
    }
    this.handleDelete=this.handleDelete.bind(this);
}
 
handleDelete(event){
    this.setState({
        disabled:true,
        mensaje:'Espere mientras se elimina la conexion'
    })
    var authToken = getToken();
    var thisa= this;
    var tenant=getTenant();
    var conector_genesys_id=this.state.conector_genesys_id
    var conector_servicio_id=this.state.conector_servicio_id
    if(conector_genesys_id=="" || conector_genesys_id=="a" || conector_servicio_id=="" || conector_servicio_id=="a"){
        alert("Seleccione un conector de genesys y un conector de servicio")
        this.setState({
            disabled:false,
            mensaje:''
        })
    }else{
        conectormanagergenesys.EliminarConexionConectoresAdministrador(authToken,conector_servicio_id,conector_genesys_id,tenant.TenantId,function(conector){
            if(conector){
                alert("Conectores desasociados correctamente")
                setTimeout(function(){
                    window.location.href="/conectorgenesysconexion-list"
                }, 2000);
            }else{
                thisa.setState({
                    disabled:false,
                    mensaje:"No se pudo eliminar la conexión. Intentelo más tarde"
                })
                
                setTimeout(function(){
                    window.location.href="/conectorgenesysconexion-list"
                }, 2000);
            }
        })
    }
   
}

render() {
    var tenant=getTenant();
    return (
        <div className="auth-wrapper">
          <div className="auth-inner">
          <form role="form">
            <p>Esta seguro de elimar el conector: {this.state.conector_genesys_name} - {this.state.conector_servicio_name} de la organización {tenant.CompanyName }?</p>
            <button type="submit" 
               disabled={(this.state.disabled)? "disabled" : ""}
               className="paddingbutton btn btn-block btn-outline-primary" onClick={this.handleDelete}>Eliminar</button>
            <a href="/conectorgenesysconexion-list" className="paddingbutton btn btn-outline-info"  role="button" aria-disabled="true">Volver</a>  
            <div>
                <label>{this.state.mensaje}</label>
            </div>
        </form>
        </div>
        </div>
    );
}
}