import React, { Component } from "react";
import {Link} from 'react-router-dom';
import { getTenant } from "../../../auth-helpers";
import { getToken } from "../../../auth-helpers";
const usermanager = require('../../../ServerFunctions/usermanager');
export default class TenantUserList extends Component {
constructor(props){
    super(props);
    this.state={
        users:[]
    }    
}

componentDidMount(){
    localStorage.removeItem('userupdate');
        var authToken = getToken();
        var thisa= this;
        var tenant= getTenant();
        usermanager.ListUserAdministrador(authToken,tenant,function(users) {
            thisa.setState({
                users
            })
        });
}

    render() {
        var usuarios=this.state.users;
        return (
        <div>
            <div id="cognitousers"  align="right">
                <a className="btn btn-primary" href="/user-add">
                    <span className="glyphicon glyphicon-plus"></span> Agregar Usuario
                </a>
            </div>
            <div id="cognitousers" className="table-responsive">
            <table className="table table-striped">
                <thead>
                    <tr>
                    <th>Nombre</th>
                    <th>Apellido</th>
                    <th>Correo electrónico</th>
                    <th>Rol</th>
                    <th>Estado</th>
                    <th>Fecha de creación</th>
                    <th>Acciones</th>
                    </tr>
                </thead>
            <tbody>
                {
                    usuarios?(
                        <>
                        {usuarios.map((item, i) => {
                            return (
                                <tr key={item.userName}>
                                    <td>{item.firstName}</td>
                                    <td>{item.lastName}</td>
                                    <td>{item.userName}</td>
                                    {
                                        item.role==="TenantAdmin"?(<>
                                        <td>Administrador</td>
                                        </>):(<>
                                        <td>Usuario</td>
                                        </>)
                                    }
                                    <td>{item.enabled ? 'Active' : 'Inactive'}</td>
                                    <td>{item.dateCreated.toLocaleString()}</td>
                                    <td>
                                    <div className="btn-group">
                                    <Link
                                        className="paddingicon" title="Edit"
                                        to={{
                                            pathname: "/user-edit",
                                            state: { user: item }
                                        }}>
                                            <span className="glyphicon glyphicon-pencil"></span>
                                        </Link>
                                        <Link
                                        className="paddingicon" title="Delete"
                                        to={{
                                            pathname: "/user-delete",
                                            state: { user: item }
                                        }}>
                                            <span className="glyphicon glyphicon-trash"></span>
                                        </Link>
                                        <Link
                                        className="paddingicon" title="Delete"
                                        to={{
                                            pathname: "/user-enabled",
                                            state: { user: item }
                                        }}>
                                            <span className="glyphicon glyphicon-check"></span>
                                        </Link>
                                    </div>
                                    </td>
                                </tr>
                            );
                        })}        
                    </>
                    ):(
                        <>
                        </>
                    )
                }
            </tbody>
          </table>
          </div>
          </div>         
        );
    }
}