import React, { Component } from "react";
import jwtDecode from "jwt-decode";
import {Link} from 'react-router-dom';
import { getToken } from "../../../auth-helpers";
import LoadingOverlay from 'react-loading-overlay-ts';
import 'datatables.net-dt/js/dataTables.dataTables.min.js';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
const $ = require('jquery');
$.DataTable = require('datatables.net');
const campañamanager = require('../../../ServerFunctions/campaignmanager');
const usermanager = require('../../../ServerFunctions/usermanager');
export default class CampaignList extends Component {
constructor(props){
    super(props);
    this.state={
        campaigns:[],
        isActive:true
    }    
}
 
componentDidMount(){
        var authToken = getToken();
        var thisa= this;
        var usr;
        if(authToken){
            var user = jwtDecode(authToken);
            usr={
                companyName:user['custom:companyName'],
                email:user['email'],
                appid:user['custom:appid']
            };
        }
            campañamanager.ListCampaña(authToken,usr.appid,function(campaigns){
                thisa.setState({
                    campaigns:campaigns,
                    appid:usr.appid,
                    isActive:false
                })
              $('#templatesPC').DataTable();

            })
}

    render() {
        var campaigns=this.state.campaigns;
        var isActive=this.state.isActive;
        return (
            <LoadingOverlay
            active={isActive}
            spinner
            styles={{
              wrapper: {
                width: '100%',
                height: '100%',
                overflow: isActive ? 'hidden' : '',
              },
            }}
            text="Cargando ..."
          >
        <div>
            <div id="cognitousers"  align="right">
                <Link
                className="btn btn-primary" title="Delete"
                to={{
                    pathname: "/campaign-add",
                    state: { applicationid:this.state.appid }
                }}>
                <span className="glyphicon glyphicon-plus"></span> Crear Campañas
                </Link>
                </div>
            <div id="cognitousers" className="table-responsive">
            <table id="templatesPC" className="display"    style={{ width: '100%', float: 'left' }}>
                <thead>
                    <tr>
                    <th>Nombre</th>
                    <th>Fecha de Creación</th>
                    <th>Calendarizado</th>
                    <th>Estado</th>
                    <th>Acciones</th>
                    </tr>
                </thead>
            <tbody>
                {
                    campaigns?(
                        <>
                        {campaigns.map((item, i) => {
                            return (
                                <tr key={item.Id}>
                                    <td>{item.Name}</td>
                                    <td>{item.CreationDate.toLocaleString()}</td>
                                    {
                                        item.Schedule.StartTime==="IMMEDIATE"?(
                                            <td>{item.Schedule.StartTime}</td>
                                        ):(
                                            <td>{item.Schedule.Frequency}</td>
                                        )
                                    }
                                    <td>{item.State.CampaignStatus}</td>
                                    <td>
                                    <div className="btn-group">
                                         {
                                            item.State.CampaignStatus!="COMPLETED"?(
                                                <>
                                                <Link
                                                className="paddingicon" title="View"
                                                to={{
                                                    pathname: "/campaign-edit",
                                                    state: { campaign: item,habilitar:true }
                                                }}>
                                                    <span className="glyphicon glyphicon-eye-open"></span>
                                                </Link>
                                            <Link
                                            className="paddingicon" title="Delete"
                                            to={{
                                                pathname: "/campaign-delete",
                                                state: { campaign: item }
                                            }}>
                                            <span className="glyphicon glyphicon-trash"></span>
                                        </Link>
                                        </>
                                            ):(
                                                <>
                                                <Link
                                                className="paddingicon" title="View"
                                                to={{
                                                    pathname: "/campaign-edit",
                                                    state: { campaign: item,habilitar:true }
                                                }}>
                                                    <span className="glyphicon glyphicon-eye-open"></span>
                                                </Link></>
                                            )
                                        }
                                        
                                    </div>
                                    </td>
                                </tr>
                            );
                        })}        
                    </>
                    ):(
                        <>
                        </>
                    )
                }
            </tbody>
          </table>
          </div>
          </div>  
          </LoadingOverlay>       
        );
    }
}