
import React, { Component } from "react";
import jwtDecode from "jwt-decode";
import { getTenant, getToken } from "../../../auth-helpers";
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';

import { Line, Pie, Doughnut, Bar, Radar } from 'react-chartjs-2';
const campañamanager = require('../../../ServerFunctions/campaignmanager');
const conectormanager = require('../../../ServerFunctions/conectormanager.js');
const { v4: uuidv4 } = require('uuid');


export default class TenantReport extends Component {
constructor(props){
    super(props);
    this.state={
      campaigns:[],
      visible:'none',
      fdate:'block',
      fcampaign:'none',
      visibled:'none',
      value:'date',
      conectores_list_value:[],
      conectores_list_name:[],
      conectores_list_price:[],
      conectores_list_value_a:[],
      conectores_list_name_a:[],
      conectores_list_price_a:[],
      title:'Messages sent in the month',
      cnt_campaña_env:0,
      cnt_campaña_err:0,
      cnt_agente_env:0,
      cnt_agente_err:0,
      cost_valor:0,
      contactos:[],
      agentes:[]
    }    
    this.Load= this.Load.bind(this);
    this.handleExport=this.handleExport.bind(this)
    this.handleSearch=this.handleSearch.bind(this)
}

componentDidMount(){
        var authToken = getToken();
        var thisa= this;
        var tenant=getTenant();
        var usr;
            campañamanager.listcampaign(authToken,tenant.PinPointId,function(campaigns){
                thisa.setState({
                    campaigns:campaigns,
                    appid:tenant.PinPointId
                })
            })
          
            conectormanager.Tenantlistconector(authToken,tenant.TenantId,function(conectors) {
                thisa.setState({
                    conectors
                })
            })
        
    var authToken = getToken();
    var thisa= this;
    var cnt_envio=0;
    var cnt_error=0;
    var cnt_campaña=0;
    var cnt_agente=0;
    var cnt_agente_env=0;
    var cnt_agente_err=0;
    var cnt_campaña_env=0;
    var cnt_campaña_err=0;
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    /*campañamanager.getcontactosdatesys(authToken,lastDay,firstDay,tenant.TenantId,function(contactos){
        if(contactos){
            var enviado=contactos.filter(contact=>contact.estado==='enviado')
            cnt_envio=enviado.length;
            var error=contactos.filter(contact=>contact.estado==='error')
            cnt_error=error.length;
            var campana=contactos.filter(contact=>contact.envio==='campaña')
            cnt_campaña=campana.length;
            var agente=contactos.filter(contact=>contact.envio==='agente')
            cnt_agente=agente.length;
            /////////////////////////////
            var enviadoc=contactos.filter(contact=>contact.estado==='enviado' && contact.envio==='campaña')
            cnt_campaña_env=enviadoc.length;
            var errorc=contactos.filter(contact=>contact.estado==='error' && contact.envio==='campaña')
            cnt_campaña_err=errorc.length;
           
            var enviadoa=contactos.filter(contact=>contact.estado==='enviado' && contact.envio==='agente')
            cnt_agente_env=enviadoa.length;
            var errora=contactos.filter(contact=>contact.estado==='error' && contact.envio==='agente')
            cnt_agente_err=errora.length;
            var conectores_list_name=[]
            var conectores_list_cost=[]
            var conectores_list_value=[]
            var conectores_list_price=[]
            thisa.state.conectors.forEach(element => {
            var conect_list=contactos.filter(contact=>contact.conector_id===element.conector_id && contact.estado==='enviado')
                
            conectores_list_name.push(element.nombre);
            conectores_list_cost.push(element.costo_hsm);
            conectores_list_value.push(conect_list.length);
            conectores_list_price.push((parseFloat(conect_list.length)*parseFloat(element.costo_hsm)).toFixed(3));
            });
           thisa.setState({
               cnt_enviod:cnt_envio,
               cnt_errord:cnt_error,
               cnt_campañad:cnt_campaña,
               cnt_agented:cnt_agente,
               cnt_campaña_errd:cnt_campaña_err,
               cnt_campaña_envd:cnt_campaña_env,
               cnt_agente_envd:cnt_agente_env,
               cnt_agente_errd:cnt_agente_err,
               checkedId:'',
               visibled:'block',
               visible:'none',
               conectores_list_name,
               conectores_list_price,
               conectores_list_value,
               conectores_list_cost,
               contactos
           })
        }
    })*/
}

Load(campaign){
    this.setState({
        visible:'block'
    })
    var authToken = getToken();
    var tenant= getTenant();
    var thisa= this;
    var cnt_envio=0;
    var cnt_error=0;
    var cnt_campaña=0;
    var cnt_agente=0;
    var cnt_agente_env=0;
    var cnt_agente_err=0;
    var cnt_campaña_env=0;
    var cnt_campaña_err=0;

    campañamanager.getcontactossys(authToken,campaign.Id,tenant.TenantId,function(contactos){
        if(contactos){
            var enviado=contactos.filter(contact=>contact.estado==='enviado')
            cnt_envio=enviado.length;
            var error=contactos.filter(contact=>contact.estado==='error')
            cnt_error=error.length;
            var campana=contactos.filter(contact=>contact.envio==='campaña')
            cnt_campaña=campana.length;
            var agente=contactos.filter(contact=>contact.envio==='agente')
            cnt_agente=agente.length;
            /////////////////////////////
            var enviadoc=contactos.filter(contact=>contact.estado==='enviado' && contact.envio==='campaña')
            cnt_campaña_env=enviadoc.length;
            var errorc=contactos.filter(contact=>contact.estado==='error' && contact.envio==='campaña')
            cnt_campaña_err=errorc.length;
            //////////////////////////////////////////////////////
            var enviadoa=contactos.filter(contact=>contact.estado==='enviado' && contact.envio==='agente')
            cnt_agente_env=enviadoa.length;
            var errora=contactos.filter(contact=>contact.estado==='error' && contact.envio==='agente')
            cnt_agente_err=errora.length;
            var costo= thisa.state.conectors.filter(conector=>conector.conector_id==contactos[0].conector_id)
            var cost_valor=parseFloat(costo[0].costo_hsm)*parseFloat(cnt_envio);  
           thisa.setState({
               cnt_envio:cnt_envio,
               cnt_error:cnt_error,
               cnt_campaña:cnt_campaña,
               cnt_agente:cnt_agente,
               cnt_campaña_err:cnt_campaña_err,
               cnt_campaña_env:cnt_campaña_env,
               cnt_agente_env:cnt_agente_env,
               cnt_agente_err:cnt_agente_err,
               contactos:contactos,
               cost_valor:cost_valor.toFixed(3),
               con_name:costo[0].nombre,
               contactos
           })
        }
    })
}

handleSearch(e){
    e.preventDefault();
    var authToken = getToken();
    var tenant=getTenant();
    var thisa= this;
    var cnt_agente_env=0;
    var cnt_agente_err=0;
    var cnt_campaña_env=0;
    var cnt_campaña_err=0;
    
    campañamanager.getcontactosdatesys(authToken,this.state.EndDate+'T23:59:59.000Z',this.state.StartDate+'T00:00:00.000Z',tenant.TenantId,function(contactos){
      if(contactos){
        var enviadoc=contactos.filter(contact=>contact.estado==='enviado')
        cnt_campaña_env=enviadoc.length;
        var errorc=contactos.filter(contact=>contact.estado==='error')
        cnt_campaña_err=errorc.length;
       
        var conectores_list_name=[]
        var conectores_list_cost=[]
        var conectores_list_value=[]
        var conectores_list_price=[]
        thisa.state.conectors.forEach(element => {
        var conect_list=contactos.filter(contact=>contact.conector_id===element.conector_id && contact.estado==='enviado')
            
        conectores_list_name.push(element.nombre);
        conectores_list_cost.push(element.costo_hsm);
        conectores_list_value.push(conect_list.length);
        conectores_list_price.push((parseFloat(conect_list.length)*parseFloat(element.costo_hsm)).toFixed(3));
        });
       thisa.setState({
           cnt_campañad:contactos.length,
           cnt_campaña_errd:cnt_campaña_err,
           cnt_campaña_envd:cnt_campaña_env,
           checkedId:'',
           visibled:'block',
           visible:'none',
           conectores_list_name,
           conectores_list_price,
           conectores_list_value,
           conectores_list_cost,
           title:'',
           contactos
       })
    }
    })

    campañamanager.getcontactosdatesysagent(authToken,this.state.EndDate+'T23:59:59.000Z',this.state.StartDate+'T00:00:00.000Z',tenant.TenantId,function(contactos){
      if(contactos){
        var enviadoa=contactos.filter(contact=>contact.estado==='enviado' )
        cnt_agente_env=enviadoa.length;
        var errora=contactos.filter(contact=>contact.estado==='error' )
        cnt_agente_err=errora.length;
        var conectores_list_name_a=[]
        var conectores_list_cost_a=[]
        var conectores_list_value_a=[]
        var conectores_list_price_a=[]
        thisa.state.conectors.forEach(element => {
        var conect_list=contactos.filter(contact=>contact.conector_id===element.conector_id && contact.estado==='enviado')
            
        conectores_list_name_a.push(element.nombre);
        conectores_list_cost_a.push(element.costo_hsm);
        conectores_list_value_a.push(conect_list.length);
        conectores_list_price_a.push((parseFloat(conect_list.length)*parseFloat(element.costo_hsm)).toFixed(3));
        });
       thisa.setState({
           cnt_agented:contactos.length,
           cnt_agente_envd:cnt_agente_env,
           cnt_agente_errd:cnt_agente_err,
           checkedId:'',
           visibled:'block',
           visible:'none',
           conectores_list_name_a,
           conectores_list_price_a,
           conectores_list_value_a,
           conectores_list_cost_a,
           title:'',
           agentes:contactos
       })
    }
})
}
handleExport(event){
    event.preventDefault();

    var items=this.state.contactos;
    var agents=this.state.agentes;
    for(let i=0;i<agents.length;i++){
      items.push(agents[i])
    }
    var filename= uuidv4();
    var headers={
      conversation_id:'IDConexion',
      user_id:'Id',
      contacto:'Contacto',
      envio:'TipoEnvio',
      fecha_hora:'FechaInteraccion',
      conector_name:'Conector',
      template_name:'Template',
      estado:"Estado",
      codigo:"Codigo",
      mensaje:"Mensaje",
      detalle:"Detalle",
      agent_id:"AgenteId",
      agente:"Agente",
    }
    const jsonObject = JSON.stringify(items);
    const csv = this.convertToCSV(jsonObject,headers);
    const exportName = filename + ".csv" || "export.csv";
   const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
     navigator.msSaveBlob(blob, exportName);
    } else {
        console.log("AQUI2")
        const link = document.createElement("a");
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportName);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}
convertToCSV(objArray,headers) {
    const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "IDConexion,Id,Contacto,TipoEnvio,FechaInteraccion,Conector,Template,Estado,Codigo,Mensaje,Detalle,AgenteId,Agente"+ "\r\n";
   for (let i = 0; i < array.length; i++) {
       console.log(i)
        let line = "";
        for (let index in headers) {
            if (line != ""){
                line += ",";
            }
            var text =array[i][index];
            if(text===undefined){
                text="-"
            }else if(text===""){
                text="-"
            }
            
            line += text;
        }
        str += line + "\r\n";
    }
    return str;
   }
    render() {
      var values=[]
      var prices=[]
      for( let i=0;i<this.state.conectores_list_value.length;i++){
        values.push(parseInt(this.state.conectores_list_value[i])+parseInt(this.state.conectores_list_value_a[i]))
        prices.push(parseFloat(this.state.conectores_list_price[i])+parseFloat(this.state.conectores_list_price_a[i]))
      }
        var campaigns=this.state.campaigns;
        const CheckBox = ({campaign}) => (
            <input
              id={campaign.Id}
              onChange={(e) => {
                  e.preventDefault()
                this.setState({
                    checkedId:campaign.Id,
                    campaign:campaign
                })
                this.Load(campaign)
              }}
              checked={this.state.checkedId === campaign.Id}
              type="checkbox"
            />
          )
          const genPieData=()=>{
            return {
              datasets: [
                {
                  data: [parseInt(this.state.cnt_campaña_env)+parseInt(this.state.cnt_agente_env),parseInt(this.state.cnt_campaña_err)+parseInt(this.state.cnt_agente_err)],
                  backgroundColor: [
                    '#77dd77',
                    '#ff6961',
                  ],
                  label: 'Campaña',
                },
              ],
              labels: ['Enviados', 'Error'],
            };
          };
          const genPieDatas=()=>{
            return {
              datasets: [
                {
                  data: [this.state.cnt_agente,this.state.cnt_campaña],
                  backgroundColor: [
                    '#fdfd69',
                    '#84b6f4',
                  ],
                  label: 'Campaña',
                },
              ],
              labels: ['Agentes', 'Campaña'],
            };
          };

          const genPieDatacam=()=>{
            return {
              datasets: [
                {
                  data: [this.state.cnt_campaña_env,this.state.cnt_campaña_err],
                  backgroundColor: [
                    '#77dd77',
                    '#ff6961',
                  ],
                  label: 'Campaña',
                },
              ],
              labels: ['Enviados', 'Error'],
            };
          };
          const genPieDatasagen=()=>{
            return {
              datasets: [
                {
                  data: [this.state.cnt_agente_env,this.state.cnt_agente_err],
                  backgroundColor: [
                    '#77dd77',
                    '#ff6961',
                  ],
                  label: 'Campaña',
                },
              ],
              labels: ['Enviados', 'Error'],
            };
          };
          const genPieDatad=()=>{
            return {
              datasets: [
                {
                  data: [parseInt(this.state.cnt_campaña_envd)+parseInt(this.state.cnt_agente_envd),parseInt(this.state.cnt_campaña_errd)+parseInt(this.state.cnt_agente_errd)],
                  backgroundColor: [
                    '#77dd77',
                    '#ff6961',
                  ],
                  label: 'Campaña',
                },
              ],
              labels: ['Enviados', 'Error'],
            };
          };
          const genPieDatasd=()=>{
            return {
              datasets: [
                {
                  data: [this.state.cnt_agented,this.state.cnt_campañad],
                  backgroundColor: [
                    '#fdfd69',
                    '#84b6f4',
                  ],
                  label: 'Campaña',
                },
              ],
              labels: ['Agentes', 'Campaña'],
            };
          };

          const genPieDatacamd=()=>{
            return {
              datasets: [
                {
                  data: [this.state.cnt_campaña_envd,this.state.cnt_campaña_errd],
                  backgroundColor: [
                    '#77dd77',
                    '#ff6961',
                  ],
                  label: 'Campaña',
                },
              ],
              labels: ['Enviados', 'Error'],
            };
          };
          const genPieDatasagend=()=>{
            return {
              datasets: [
                {
                  data: [this.state.cnt_agente_envd,this.state.cnt_agente_errd],
                  backgroundColor: [
                    '#77dd77',
                    '#ff6961',
                  ],
                  label: 'Campaña',
                },
              ],
              labels: ['Enviados', 'Error'],
            };
          };

const genLineData = (moreData = {}, moreData2 = {}) => {
  return {
    labels: this.state.conectores_list_name,
    datasets: [
      {
        label: 'Costo',
        backgroundColor: '#77dd77',
        borderColor: '#77dd77',
        borderWidth: 1,
        data: prices,
        ...moreData2,
      },
    ],
  };
};
const genLineDatas = () => {
    return {
        datasets: [
          {
            data: values,
            backgroundColor: [
              '#77dd77',
              '#84b6f4',
              '#ff6961',
              '#fdcae1'
            ],
            label: 'Campaña',
          },
        ],
        labels: this.state.conectores_list_name,
      };
  };
        return (
        <>       
        <div id="repcampaign">
            <br/>
            <h2>Reportes por Campaña</h2>
            <div id="cognitousers" className="table-responsive" style={{maxHeight:"300px"}}>
            <table className="table table-striped">
                <thead>
                <tr>
                    <th></th>
                    <th>Nombre</th>
                    <th>Id</th>
                    <th>Fecha de creación</th>
                    <th>Tipo de envío</th>
                    <th>Estado</th>
                    </tr>
                </thead>
            <tbody>
                {
                    campaigns?(
                        <>
                        {campaigns.map((item, i) => {
                            return (
                                <tr key={item.Id}>
                                    <td>
                                    <CheckBox campaign={item} />
                                    </td>
                                    <td>{item.Name}</td>
                                    <td>{item.Id}</td>
                                    <td>{item.CreationDate.toLocaleString()}</td>
                                    {
                                        item.Schedule.StartTime==="IMMEDIATE"?(
                                            <td>{item.Schedule.StartTime}</td>
                                        ):(
                                            <td>{item.Schedule.Frequency}</td>
                                        )
                                    }
                                    <td>{item.State.CampaignStatus}</td>
                                </tr>
                            );
                        })}        
                    </>
                    ):(
                        <>
                        </>
                    )
                }
            </tbody>
          </table>
            </div>
            <br/>
            <div style={{display:(this.state.visible)}} >
          <Row>
            <Col xl={3} lg={6} md={6}>
            <Card>
                <CardHeader>Sent messages. {this.state.con_name} :$ {this.state.cost_valor}</CardHeader>
                <CardBody>
                <Pie data={genPieData} />
                </CardBody>
            </Card>
            </Col>

            <Col xl={3} lg={6} md={6}>
            <Card>
                <CardHeader>Medio de Envio</CardHeader>
                <CardBody>
                <Pie data={genPieDatas} />
                </CardBody>
            </Card>
            </Col>
            <Col xl={3} lg={6} md={6}>
            <Card>
                <CardHeader>Enviados por campaña</CardHeader>
                <CardBody>
                <Pie data={genPieDatacam} />
                </CardBody>
            </Card>
            </Col>

            <Col xl={3} lg={6} md={6}>
            <Card>
                <CardHeader>Enviados por agentes</CardHeader>
                <CardBody>
                <Pie data={genPieDatasagen} />
                </CardBody>
            </Card>
            </Col>
        </Row>
          </div>       
        </div>
        <div>
            <br/>
            <h2>Reportes por fecha</h2>
            <br/>
            <div className="row" style={{maxWidth:"800px", marginLeft:"20px"}}>
                <div className="col-sm-4" >
                    <label style={{textAlign:"end"}}>Fecha inicial</label>
                    <input 
                        onChange={(e)=>this.setState({
                            StartDate: e.target.value
                        })}
                    type="date" className="form-control"></input>
                </div>
                <div className="col-sm-4" >
                    <label style={{textAlign:"end"}}>Fecha final</label>
                    <input 
                        onChange={(e)=>this.setState({
                            EndDate: e.target.value
                        })}
                    type="date" className="form-control"></input>
                </div>   
                <div className="col-sm-4">
                <br/>
                <button className="paddingbutton btn btn-primary btn-block"
                onClick={this.handleSearch}> Buscar</button>
            </div>            
            </div>
            <br/>
            <div style={{display:(this.state.visibled)}} >
            <h2>{this.state.title}</h2>
            <br/>
            <div className="row">
                <div className="col-sm-3">
                <button  className="paddingbutton btn btn-primary btn-block" 
                onClick={this.handleExport}>Exportar Lista de contactos</button>
                </div>
            </div>
            <br/>
                <Row>
                <Col xl={3} lg={6} md={6}>
                <Card>
                    <CardHeader>Conectores</CardHeader>
                    <CardBody>
                    <Doughnut data={genLineDatas()} />
                    </CardBody>
                </Card>
                </Col>
                <Col xl={6} lg={6} md={6}>
                <Card>
                    <CardHeader>Conectores</CardHeader>
                    <CardBody>
                    <Bar data={genLineData()} />
                    </CardBody>
                </Card>
                </Col>
            </Row>
            <Row>
                    <Col xl={3} lg={6} md={6}>
                    <Card>
                        <CardHeader>Mensajes Enviados.</CardHeader>
                        <CardBody>
                        <Pie data={genPieDatad} />
                        </CardBody>
                    </Card>
                    </Col>

                    <Col xl={3} lg={6} md={6}>
                    <Card>
                        <CardHeader>Medio de Envío</CardHeader>
                        <CardBody>
                        <Pie data={genPieDatasd} />
                        </CardBody>
                    </Card>
                    </Col>
                    <Col xl={3} lg={6} md={6}>
                    <Card>
                        <CardHeader>Mensajes enviados por campaña</CardHeader>
                        <CardBody>
                        <Pie data={genPieDatacamd} />
                        </CardBody>
                    </Card>
                    </Col>

                    <Col xl={3} lg={6} md={6}>
                    <Card>
                        <CardHeader>Mensajes enviados por agentes</CardHeader>
                        <CardBody>
                        <Pie data={genPieDatasagend} />
                        </CardBody>
                    </Card>
                    </Col>
                </Row>
                <br/>
                </div>       
        </div>
         </>
        );
    }
}