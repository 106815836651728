import React, { Component } from "react";
import { getTenant, getToken } from "../../../auth-helpers";
const conectormanagergenesys = require('../../../ServerFunctions/conectormanagergenesys.js');
export default class TenantConectorDelete extends Component {
constructor(props){
    super(props);
    this.state={
        id:props.location.state.conector.conector_id,
        nombre:props.location.state.conector.nombre,
        tipo:props.location.state.conector.tipo,
        deployment_id:props.location.state.conector.deployment_id,
        mensaje:'',
        disabled:false,
    }
    this.handleDelete=this.handleDelete.bind(this);
}
 
handleDelete(event){
    event.preventDefault();
    this.setState({
        disabled:true,
        mensaje:'Espere mientras se elimina el conector'
    })
    const thisa = this; 
    var authToken = getToken();
        var tenant=getTenant();
        console.log(tenant.TenantId)
    conectormanagergenesys.EliminarGenesysConectorAdministrador(this.state.id,tenant.TenantId,authToken,function(tenant) {
        if(tenant){
            thisa.setState({
                mensaje:tenant.mensaje
            })
            setTimeout(function(){
               window.location.href="/conectorgenesys-list"
            }, 2000);
        }else{
            thisa.setState({
                mensaje:'No se pudo eliminar el conector. Intentelo más tarde'
            })
            setTimeout(function(){
              window.location.href="/conectorgenesys-list"
            }, 2000);
        }
    })
}

render() {
    var tenant=getTenant();
    
    return (
        <div className="auth-wrapper">
          <div className="auth-inner">
          <form role="form">
          <p>Esta seguro de elimar el conector de genesys: {this.state.nombre} con deployment id: {this.state.deployment_id}  de la organización {tenant.CompanyName }?</p>
            <button type="submit" 
               disabled={(this.state.disabled)? "disabled" : ""}
               className="paddingbutton btn btn-block btn-outline-primary" onClick={this.handleDelete}>Eliminar</button>
            <a href="/conectorgenesys-list" className="paddingbutton btn btn-outline-info"  role="button" aria-disabled="true">Volver</a>  
            <div>
                <label>{this.state.mensaje}</label>
            </div>
        </form>
        </div>
        </div>
    );
}
}