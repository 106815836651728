import axios from 'axios';
//local
//const url_plantilla="http://localhost:3050/"
//const url_segment="http://localhost:/"
//pruebas
//const url_plantilla="https://cuymtpz4r2.execute-api.us-east-2.amazonaws.com/dev/"
//const url_segment="https://au95g314f4.execute-api.us-east-2.amazonaws.com/dev/"

//producción
const url_plantilla="https://0ghmyt12g8.execute-api.us-east-2.amazonaws.com/prod/"
const url_segment="https://cj3pqyeckb.execute-api.us-east-2.amazonaws.com/prod/"
//
export function CrearTemplate(token,template,callback) {
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.post( 
      url_plantilla+`template`,
      template,
      config
    ).then(res=>{
        callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}

export function EliminarTemplate(template_id,token,callback) {
    const config = {
        headers: { Authorization: `${token}` }
    };
            axios.delete( 
                url_plantilla+`template/`+template_id,
                config
              ).then(res=>{
                res.data.mensaje='Plantilla eliminada exitosamente.'
                callback(res.data)
              }).catch(err=>{
                  callback(null)
              });
}

export function EditarTemplate(token,template,callback) {
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.put( 
        url_plantilla+`template`,
      template,
      config
    ).then(res=>{
        callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}
export function ListTemplate(token,callback){
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.get( 
        url_plantilla+`templates`,
      config
    ).then(res=>{
        callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}

export function ObtenerTemplate(token,id,callback){
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.get( 
        url_plantilla+`template/`+id,
      config
    ).then(res=>{
        callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}

export function ObtenerTemplateAdministrador(token,id,tenantId,callback){
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.get( 
        url_plantilla+`template/system/`+id+"/"+tenantId,
      config
    ).then(res=>{
        callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}

export function CrearTemplateAdministrador(token,tenantId,template,callback) {
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.post( 
        url_plantilla+`template/system/`+tenantId,
      template,
      config
    ).then(res=>{
        callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}

export function EliminarTemplateAdministrador(template_id,tenantId,token,callback) {
    const config = {
        headers: { Authorization: `${token}` }
    };
        axios.delete( 
            url_plantilla+`template/system/`+template_id+'/'+tenantId,
            config
          ).then(res=>{
              callback(res.data)
          }).catch(err=>{
              callback(null)
          });
}

export function EditarTemplateUser(token,template,callback) {
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.put( 
        url_plantilla+`template/user`,
      template,
      config
    ).then(res=>{
        callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}
  
export function EditarTemplateAdministrador(token,template,tenantId,callback) {
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.put( 
        url_plantilla+`template/system/`+tenantId,
      template,
      config
    ).then(res=>{
        callback(res.data)
    }).catch(err=>{
        console.log(err)
        callback(null)
    });
}

export function ListTemplateAdministrador(token,tenantId,callback){
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.get( 
        url_plantilla+`templates/system/`+tenantId,
      config
    ).then(res=>{
        callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}
