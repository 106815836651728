import React, { Component } from "react";
import { getTenant, getToken } from "../../../auth-helpers.js";
import fondoimage from '../../../fondo.jpg';
import { Image } from "react-bootstrap";
const conectormanager = require('../../../ServerFunctions/conectormanager.js');
const templatemanager = require('../../../ServerFunctions/templatemanager.js');
export default class TenantTemplateAdd extends Component {
constructor(props){
    super(props);
    this.state={
        user:null,
        border:"10px 10px 10px 10px",
        picture: fondoimage,
        tipo:'none',
        headerlength:0,
        bodylength:0,
        footerlenght:0,
        contador:0,
        action:'web',
        tipobtn:'none',
        maxlengt:60,
        buttons:[],
        buttonsac:[],
        parameters:[],
        valbtn:'',
        mesagge:'',
        valpar:'',
        mesaggepar:'',
        t_namespace:'',
        t_template:'',
        t_conector:'',
        t_template_name:'',
        t_msg_language:'es',
        t_msg_type:'Account update',
        t_header_type:'',
        t_header_text:'',
        t_body_text:'',
        t_footer:'',
        t_buttons_type:'',
        t_btnac_action:'',
        t_btnac_text:'',
        t_btnac_url:'',
        t_btnac_countrycode:'',
        t_btnac_phonenumber:'',
        t_btnqu_varone:'',
        t_btnqu_vartwo:'',
        t_btnqu_varthree:'',
        t_parameterone:'',
        t_parametertwo:'',
        t_parameterthree:'',
        t_parameterfour:'',
        t_parameterfive:'',
        t_parametersix:'',
        condisabled:false,
        t_parameterseven:'',
        t_parametereight:'',
        t_parameternine:'',
        t_parameterten:'',
        t_conector:'',
        conectors:[],
        contador:0,
        codwhats:'593'

    }
    this.Mostrar=this.Mostrar.bind(this)
    this.handleCreate=this.handleCreate.bind(this);
}

handleCreate(event){
    event.preventDefault();
    this.setState({
        disabled:true,
        mensaje:"Espere mientras creamos la plantilla"
    })
    if(this.state.condisabled){
        if(this.state.t_namespace==""){
            this.setState({
                mensaje:"Campos vacios",
                disabled:false
            })
            return 
        }
    }
    if(this.state.t_conector=="" ||
    this.state.t_template=="" ||
    this.state.t_template_name=="" ||
    this.state.t_msg_language=="" ||
    this.state.t_msg_type=="" ||
    this.state.t_body_text==""){
        this.setState({
            mensaje:"Campos vacios",
            disabled:false
        })
        return
    }
    if(this.state.tipo!='none'){
        if(this.state.t_header_text==""){
            this.setState({
                mensaje:"Campos vacios",
                disabled:false
            })
            return  
        }
    }
    if(this.state.parameters.length!=this.state.contador){
        this.setState({
            mensaje:"Falta asignar nombres a las variables agregadas en el cuerpo del mensaje",
            disabled:false
        })  
        return
    }
    if(this.state.t_body_text.slice(-5).includes("{{")||this.state.t_body_text.slice(-5).includes("}}")){
        this.setState({
            mensaje:"Un parametro no puede estar al final del cuerpo del mensaje.",
            disabled:false
        })
        return
    }

    const template = {
        template:this.state.t_template,
        template_name:this.state.t_template_name,
        msg_language:this.state.t_msg_language,
        msg_type:this.state.t_msg_type,
        header:{
            header_type:this.state.tipo,
            header_media:this.state.media,
            header_text:this.state.t_header_text
        },
        conector:{
            conector_id:this.state.t_conector,
            namespace:this.state.t_namespace,
            codigo:this.state.codwhats
        },
        buttons:{
            buttons_type:this.state.tipobtn,
            buttons_action:this.state.buttonsac,
            buttons_quick:this.state.buttons
        },
        body:{
            body_text:this.state.t_body_text,
            footer:this.state.t_footer,
            parameters:this.state.parameters
        },
        verificacion:"pendiente",
    };
   
    if(template.header_type==='none'){
        template.header_text=''
    }
   
        var authToken = getToken();
        var thisa= this;
        var tenant=getTenant();
        templatemanager.CrearTemplateAdministrador(authToken,tenant.TenantId,template,function(result) {
            if(result){
                thisa.setState({
                    mensaje:'Plantilla creada correctamente'
                })
                setTimeout(function(){
                    window.location.href="/template-list"
                }, 1000);
    
            }else{
                thisa.setState({
                    mensaje:'No se puede crear la plantilla. Intentelo más tarde'
                })
                setTimeout(function(){
                    window.location.href="/template-list"
                }, 2000);
            }
        });
}

componentDidMount(){
    var authToken = getToken();
        var thisa= this;
        var tenant=getTenant();
        conectormanager.ListConectorAdministrador(authToken,tenant.TenantId,function(conectors) {
            thisa.setState({
                conectors
            })
        })
}

Mostrar(con){
    if(con!=""){
        var conect= this.state.conectors.filter(cnctr=>cnctr.conector_id==con)
        if(conect[0].provider_name!=="Twilio"){
            if(conect[0].namespace!=null && conect[0].namespace!=undefined || conect[0].namespace!=""){
                this.setState({
                    condisabled:true,
                    t_namespace:conect[0].namespace
                })
            }
            
        }else{
            this.setState({
                condisabled:false,
                t_namespace:""
            })
        }
    }
    
}

    render() {
        var btns=this.state.buttons;
        var btnsac=this.state.buttonsac;
        var parameters=this.state.parameters;
        var hora = new Date();
        var show=hora.getHours()+':'+hora.getMinutes()
        var conectors=this.state.conectors;
        return (
            <>
             <div >
                     <br/>
                     <div className="template-inner">
                         <h2>Detalles de la plantilla</h2>
                         <br/>
                         <div className="row">
                             <div className="col">
                             <label>Nombre</label>
                             <input type="text" className="form-control" 
                             disabled={(this.state.disabled)? "disabled" : ""}
                             placeholder="Nombre" value={this.state.t_template_name}
                                 onChange={(e)=>this.setState({
                                     t_template_name: e.target.value
                                     })}
                             />
                             </div>
                             <div className="col">
                             <label>Template </label>
                             <input type="text" className="form-control" 
                             disabled={(this.state.disabled)? "disabled" : ""}
                             placeholder="Template" value={this.state.t_template}
                                 onChange={(e)=>this.setState({
                                     t_template: e.target.value
                                 })}
                             />
                             </div>
                             {
                            this.state.condisabled?(
                            <>
                                <div className="col">
                                    <label>Namespace</label>
                                    <input type="text" className="form-control"
                                        disabled={(this.state.disabled)? "disabled" : ""}
                                        placeholder="Namespace" value={this.state.t_namespace}
                                        onChange={(e)=>this.setState({
                                            t_namespace: e.target.value
                                        })}
                                    />
                                </div>                                
                         </>):(<></>)
                         }
                         </div>
                         <br/>
                       <div className="row">
                           <div className="col-sm-6">
                           <label>País</label>
                           <select 
                                className="form-select"
                                disabled={(this.state.disabled)? "disabled" : "" }
                                value={this.state.codwhats}
                                onChange={
                                   (e)=>{
                                        this.setState({
                                            codwhats:e.target.value,
                                            t_conector:""
                                        })}}
                            >
                                <option value='' >Seleccionar país</option>
                                <option value='593' >Ecuador</option>
                                <option value='51' >Perú</option>
                                <option value='57' >Colombia</option>
                            </select>
                           </div>
                           <div className="col-sm-6">
                           <label>Conector</label>
                         <select 
                            className="form-select"
                            disabled={(this.state.disabled)? "disabled" : "" }
                            value={this.state.t_conector}
                            onChange={(e)=>{
                                    this.setState({
                                        t_conector: e.target.value
                                    })
                                    this.Mostrar(e.target.value)
                                }
                            }
                            >
                            {
                                conectors?(
                                    <>{
                                    conectors.map((item, i) => {
                                            return (
                                                <>
                                                {
                                                    item.cod_whatsapp==this.state.codwhats?(<>
                                                        <option key={item.conector_id} value={item.conector_id} >{item.nombre}</option>
                                                    </>):(<>
                                                    </>)
                                                }
                                                </>
                                            )
                                        })
                                    }
                                    </>
                                ):(
                                    <>
                                        <option value='' >No existen conectores creados</option>
                                    </>
                                )
                                
                            }
                            <option value='' >Escoger Conector</option>
                        </select>
                         
                           </div>
                         </div>
                     
                     </div>
                     <br/>
                     <div className="template-inner table-responsive">
                         <h2>Estructura del mensaje</h2>
                         <br/>
                         <div className="row">
                             <div className="col-sm-8">
                             <div className="row">
                                     <div className="col">   
                                         <label>Lenguaje</label>
                                         <select className="form-select"
                                         disabled={(this.state.disabled)? "disabled" : ""}
                                         value={this.state.t_msg_language}
                                         onChange={(e)=>this.setState({
                                             t_msg_language: e.target.value
                                         })}>
                                             <option value="es">Español</option>
                                             <option value="en">Ingles</option>
                                         </select>
                                     </div>
                                     <div className="col">
                                         <label>Tipo</label>
                                         <select className="form-select"
                                         disabled={(this.state.disabled)? "disabled" : ""}
                                         value={this.state.t_msg_type}
                                         onChange={(e)=>this.setState({
                                             t_msg_type: e.target.value
                                         })} >
                                             <option value="Account update">Account update</option>
                                             <option value="Auto-Reply">Auto-Reply</option>
                                             <option value="Personal finance update">Personal finance update</option>
                                             <option value="Update on tickets">Update on tickets</option>
                                             <option value="Update on alerts">Update on alerts</option>
                                             <option value="Troubleshooting">Troubleshooting</option>
                                             <option value="Reserve update">Reserve update</option>
                                             <option value="Transportation update">Transportation update</option>
                                             <option value="Date update">Date update</option>
                                             <option value="Payment update">Payment update</option>
                                             <option value="Shipping update">Shipping update</option>
                                         </select>
                                     </div>
                                 </div>
                             <div >   
                                 <br/>
                                 {
                                this.state.condisabled?(
                                <>
                                 <h4>Cabecera (Opcional)</h4>
                                 <small className="text-muted">
                                 Agregue un título o elija qué tipo de medio usará para este encabezado
                                 </small>
                                 <br/>
                                 <br/>
                                 <div className="row">
                                     <div className="col-md-4">   
                                         <select 
                                             style={{width:"auto"}}
                                             className="form-select"
                                             defaultValue={this.state.tipo}
                                         disabled={(this.state.disabled)? "disabled" : ""}
    
                                             onChange={(e)=>{
                                             if(e.target.value=='media'){
                                                 this.setState({
                                                     maxlengt:300
                                                 })
                                             }else{
                                                 this.setState({
                                                     maxlengt:60
                                                 })
                                             }
                                             this.setState({
                                             tipo:e.target.value,
                                             })
                                         }
                                         }>
                                         <option value='none'>None</option>
                                         <option value='text'>Text</option>
                                         <option value='media'>Media</option>
                                         </select>
                                     <br/>
                                     </div>
                                     <div className="row">   
                                         {
                                             this.state.tipo!=="none"&&(
                                             <>
                                             {
                                                 this.state.tipo!=="text"?(
                                                     <>
                                                     <div className="col-md-4">   
                                                         <select 
                                                         style={{maxWidth:"150px"}}
                                                             className="form-select"
                                                             disabled={(this.state.disabled)? "disabled" : ""}       
                                                             defaultValue={this.state.tipo}
                                                             maxlengt="300"
                                                             onChange={(e)=>{
                                                                 this.setState({
                                                                     media:e.target.value,
                                                                 })
                                                             }}>
                                                             <option value='image'>Imagen</option>
                                                             <option value='file'>Archivo</option>
                                                         </select>
                                                         <br/>
                                                     </div>
                                                 <label>Nombre del archivo</label>
                                                     </>
                                                 ):(
                                                     <>
                                                    <label>Texto de cabecera</label>
                                                     </>
                                                 )
                                             }
                                     <div>
                                     <input 
                                         type="text" 
                                         className="form-control" 
                                         disabled={(this.state.disabled)? "disabled" : ""}
                                         maxLength={this.state.maxlengt}
                                         value={this.state.t_header_text}
                                         onChange={(e)=>this.setState({
                                             t_header_text:e.target.value,
                                             headerlength:e.target.value.length,
                                         })}/>
                                         <small className="text-muted">
                                         Caracteres {
                                             this.state.headerlength
                                             }/{this.state.maxlengt}
                                         </small>
                                         </div>
                                     </>
                                     )
                                 }    
                                 </div>
                                 </div>
                                 </>):(<></>)}    <br/>
                                     <h4>Cuerpo</h4>
                                     <small className="text-muted">Ingrese el texto de su mensaje en el idioma que ha seleccionado</small>
                                     <br/>
                                     <br/>
                                     <div className="row">
                                         <textarea type="text" 
                                         maxLength="1024"
                                         style={{height:"100px"}}
                                         disabled={(this.state.disabled)? "disabled" : ""}
                                         className="form-control textarea" 
                                         value={this.state.t_body_text}
                                             onChange={(e)=>{
                                                 var abc= e.target.value.split('}')
                                                 this.setState({
                                                     t_body_text: e.target.value,
                                                     bodylength:e.target.value.length,
                                                     contador: (parseInt(abc.length)-1)/2
                                                     })
                                                 }
                                         }/>
    
                                             <div className="row">
                                                 <div className="col">
                                                     <small className="text-muted">
                                                     Caracteres {this.state.bodylength}/1024
                                                     </small>
                                                 </div>
                                                 <div className="col">
                                                 <button disabled={(this.state.disabled)? "disabled" : ""}  className="btn btn-primary" style={{marginTop:"5px"}} 
                                                 onClick={(e)=>{
                                                     e.preventDefault()
                                                     if(!this.state.disabled){
                                                         if(this.state.contador<10){
                                                             var cont=parseInt(this.state.contador)+1
                                                             this.setState({
                                                                 contador:cont,
                                                                 t_body_text: this.state.t_body_text + '{{'+cont+'}}',
                                                             })
                                                         }
                                                     }
                                                     
                                                     
                                                 }}>
                                                 <span className="glyphicon glyphicon-plus"></span> Agregar variable
                                                 </button>
                                                 </div>
                                             </div>
                                             
                                     </div>
                                     <br/>
                                     {
                                this.state.condisabled?(
                                <>
                                     <h4>Pie del mensaje (Opcional)</h4>
                                         <div className="row">
                                             <div className="col">   
                                                 <small className="text-muted">Agrega una línea corta de texto al final de tu plantilla de mensaje</small>
                                                 <input type="text" 
                                                     className="form-control"
                                         disabled={(this.state.disabled)? "disabled" : ""}
                                         maxLength='60' 
                                                     value={this.state.t_footer}
                                                     onChange={(e)=>{
                                                         this.setState({
                                                             t_footer: e.target.value,
                                                             footerlenght:e.target.value.length
                                                         })
                                                     }
                                                 }/>
                                                 <small className="text-muted">Caracteres {this.state.footerlenght}/60</small>
                                             </div>
                                         </div>
                                  </>):(<></>)}       <br/>
                                     <h4>Botones (Opcional)</h4>
                                     <div className="row">
                                         <div className="form-check form-check-inline">
                                         <select 
                                             style={{width:"150px"}}
                                             className="form-select"
                                             defaultValue={this.state.tipobtn}
                                             disabled={(this.state.disabled)? "disabled" : ""}
                                             onChange={(e)=>{
                                                 this.setState({
                                                 tipobtn:e.target.value,
                                                    })
                                                if(e.target.value=='callaction'){
                                                    this.setState({
                                                        border:"10px 10px 0px 0px"
                                                    })
                                                }else{
                                                    this.setState({
                                                        border:"10px 10px 10px 10px"
                                                    })
                                                }
                                            }
                                             }>
                                                 <option value='none'>None</option>
                                                 <option value='callaction'>Call to Action</option>
                                                 <option value='quick'>Quick</option>
                                         </select>
                                         </div>
                                     </div>
                                     <br/>
                                     {
                                         this.state.tipobtn==='none'?(
                                             <></>
                                         ):(
                                             <>
                                             {
                                                 this.state.tipobtn==='callaction'?(
                                                     <>
                                                     <div className="row">
                                                     <div className="col-md-3">   
                                                         <p>Acción</p>
                                                         <select 
                                                         className="form-select" 
                                                         disabled={(this.state.disabled)? "disabled" : ""}
                                                         defaultValue={this.state.t_btnac_action}
                                                         onChange={(e)=>this.setState({
                                                             action:e.target.value,
                                                         })}>
                                                             <option value='web'>Visitar sitio web</option>
                                                             <option value='call'>Llamada a un número de teléfono</option>
                                                         </select>
                                                     </div>
                                                     <div className="col-md-3">   
                                                         <p>Texto del botón</p>
                                                         <input type="text" className="form-control" 
                                                            disabled={(this.state.disabled)? "disabled" : ""}
                                                            placeholder="Button text" maxLength="20" value={this.state.t_btnac_text}
                                                         onChange={(e)=>this.setState({
                                                             t_btnac_text: e.target.value
                                                         })}/>
                                                     </div>
                                                     {
                                                         this.state.action==="web"?(
                                                         <div className="col-md-3">   
                                                             <p>URL</p>
                                                             <input type="text" 
                                                             className="form-control" 
                                                            disabled={(this.state.disabled)? "disabled" : ""}
                                                            placeholder="URL" 
                                                             value={this.state.t_btnac_url}
                                                             onChange={(e)=>this.setState({
                                                                 t_btnac_url: e.target.value
                                                             })}/>
                                                         </div>
                                                         ):(
                                                             <>
                                                             <div className="col-md-3">   
                                                                 <p>Código del país</p>
                                                                 <input type="text" 
                                         disabled={(this.state.disabled)? "disabled" : ""}
                                         className="form-control" placeholder="Country Code" maxLength="20" value={this.state.t_btnac_countrycode}
                                                                 onChange={(e)=>this.setState({
                                                                     t_btnac_countrycode: e.target.value
                                                                 })}/>
                                                             </div>
                                                             <div className="col-md-3">   
                                                                 <p>Número de teléfono</p>
                                                                 <input type="text" 
                                         disabled={(this.state.disabled)? "disabled" : ""}
                                         className="form-control" placeholder="Phone Number" maxLength="20" value={this.state.t_btnac_phonenumber}
                                                                 onChange={(e)=>this.setState({
                                                                     t_btnac_phonenumber: e.target.value
                                                                 })}/>
                                                             </div>
                                                         </>
                                                         )
                                                     }
                                                     
                                                     <div className="col">
                                                             <br/>
                                                             <a className="btn btn-primary" onClick={(e)=>{
                                                                 e.preventDefault();
                                                                if(this.state.t_btnac_text!==''){
                                                                    if(this.state.action=="web"){
                                                                        if(this.state.t_btnac_url!==''){
                                                                            if(btnsac.length<2){
                                                                                if(btnsac.length==1){
                                                                                    if(btnsac[0].tipo==this.state.action){
                                                                                        this.setState({
                                                                                            mesagge:'Solo se puede tener un botón call to action de cada tipo',
                                                                                            t_btnac_text:'',
                                                                                            mesagge:'',
                                                                                            action:'web',
                                                                                            t_btnac_countrycode:'',
                                                                                            t_btnac_url:'',
                                                                                            t_btnac_phonenumber:''
                                                                                        })
                                                                                        return
                                                                                    }
                                                                                }
                                                                                
                                                                                const btn={
                                                                                    texto:this.state.t_btnac_text,
                                                                                    index: btnsac.length,
                                                                                    tipo: this.state.action||'',
                                                                                    codigo: this.state.t_btnac_countrycode||'',
                                                                                    numero:this.state.t_btnac_phonenumber||'',
                                                                                    url:this.state.t_btnac_url||''
                                                                                }
                                                                                this.setState({
                                                                                buttonsac:this.state.buttonsac.concat(btn),
                                                                                    t_btnac_text:'',
                                                                                    mesagge:'',
                                                                                    action:'web',
                                                                                    t_btnac_countrycode:'',
                                                                                    t_btnac_url:'',
                                                                                    t_btnac_phonenumber:''
                                                                                })
                                                                            }else{
                                                                                this.setState({
                                                                                    mesagge:'Se puede tener máximo 2 botones',
                                                                                    t_btnac_text:'',
                                                                                    mesagge:'',
                                                                                    action:'web',
                                                                                    t_btnac_countrycode:'',
                                                                                    t_btnac_url:'',
                                                                                    t_btnac_phonenumber:''
                                                                                })
                                                                            }
                                                                        }else{
                                                                            this.setState({
                                                                                mesagge:'Faltan parametros para crear el boton'
                                                                            })                    
                                                                        }
                                                                    }else{
                                                                        if(this.state.t_btnac_countrycode!=='' && this.state.t_btnac_phonenumber!==''){
                                                                            if(btnsac.length<2){
                                                                                if(btnsac.length==1){
                                                                                    if(btnsac[0].tipo==this.state.action){
                                                                                        this.setState({
                                                                                            mesagge:'Solo se puede tener un botón call to action de cada tipo',
                                                                                            t_btnac_text:'',
                                                                                            mesagge:'',
                                                                                            action:'web',
                                                                                            t_btnac_countrycode:'',
                                                                                            t_btnac_url:'',
                                                                                            t_btnac_phonenumber:''
                                                                                        })
                                                                                        return
                                                                                    }
                                                                                }
                                                                                const btn={
                                                                                    texto:this.state.t_btnac_text,
                                                                                    index: btnsac.length,
                                                                                    tipo: this.state.action||'',
                                                                                    codigo: this.state.t_btnac_countrycode||'',
                                                                                    numero:this.state.t_btnac_phonenumber||'',
                                                                                    url:this.state.t_btnac_url||''
                                                                                }
                                                                                this.setState({
                                                                                buttonsac:this.state.buttonsac.concat(btn),
                                                                                    t_btnac_text:'',
                                                                                    mesagge:'',
                                                                                    action:'web',
                                                                                    t_btnac_countrycode:'',
                                                                                    t_btnac_url:'',
                                                                                    t_btnac_phonenumber:''
                                                                                })
                                                                            }else{
                                                                                this.setState({
                                                                                    mesagge:'Se puede tener máximo 2 botones',
                                                                                    t_btnac_text:'',
                                                                                    mesagge:'',
                                                                                    action:'web',
                                                                                    t_btnac_countrycode:'',
                                                                                    t_btnac_url:'',
                                                                                    t_btnac_phonenumber:''
                                                                                })
                                                                            }
                                                                        }else{
                                                                            this.setState({
                                                                                mesagge:'Faltan parametros para crear el boton'
                                                                            })                    
                                                                        }
                                                                    }
                                                                    
                                                                    
                                                                }else{
                                                                    this.setState({
                                                                        mesagge:'Campo vacio'
                                                                    })
                                                                }
                                                            }}>
                                                                <span className="glyphicon glyphicon-plus"></span> Agregar botón
                                                            </a>
                                                  </div>
                                                
                                                     <p>{this.state.mesagge}</p>
                                                     <div className="table-responsive"> 
                                                 <table className="table table-striped">
                                                <tbody>
                                                     {
                                                         btnsac.map((item,i)=>{
                                                             return (
                                                                 <tr key={item.index}>
                                                                     <td>
                                                                        Texto: 
                                                                        <input type="text" className="form-control" 
                                                                            disabled
                                                                            placeholder={item.texto}/>
                                                                     </td>
                                                                     <td>
                                                                        Tipo: 
                                                                     <input type="text" className="form-control" 
                                                                            disabled
                                                                            placeholder={item.tipo}/>
                                                                     </td>
                                                                        {
                                                                            item.tipo=="web"?(<>
                                                                     <td>

                                                                                    URL:
                                                                                     <input type="text" className="form-control" 
                                                                                     disabled
                                                                                     placeholder={item.url}/>
                                                                     </td>
                                                                     <td></td>
                                                                            </>):(<>
                                                                                <td>
                                                                                Código de país: 
                                                                                <input type="text" className="form-control" 
                                                                                     disabled
                                                                                     placeholder={item.codigo}/>
                                                                     </td>
                                                                     <td>
                                                                                Número de teléfono: 
                                                                                <input type="text" className="form-control" 
                                                                                     disabled
                                                                                     placeholder={item.numero}/>
                                                                     </td>
                                                                            </>)
                                                                        }
                                                                     <td>
                                                                        <br/>
                                                                     <button 
                                                                             disabled={(this.state.disabled)? "disabled" : ""}
                                                                             className="btn btn-primary" onClick={(e)=>{
                                                                                 e.preventDefault();
                                                                            var par=[];
                                                                             for(var i=0; i<btnsac.length;i++){
                                                                             if(btnsac[i].index!==item.index){
                                                                                     par=par.concat(btnsac[i]);
                                                                                 }
                                                                             }
                                                                             this.setState({
                                                                                 buttonsac:par
                                                                             })
                                                                         }}>
                                                                         <span className="glyphicon glyphicon-trash"></span> 
                                                                         </button>
                                                                     </td>
                                                                 </tr>
                                                             )
                                                         })
                                                     }
                                                </tbody>
                                                 </table>
                                                 </div>
                                             </div>
                                                     </>
                                                 ):(
                                                     <>
                                                         <div className="row">
                                                             <div className="col">
                                                             <label>Texto del botón</label>
                                                             <input  
                                                                 type="text" 
                                                                 className="form-control" 
                                                                 disabled={(this.state.disabled)? "disabled" : ""}
                                                                 placeholder="Button Text" 
                                                                 value={this.state.valbtn}
                                                                 onChange={(e)=>this.setState({
                                                                     valbtn: e.target.value
                                                                     })}/>
                                                             </div>
                                                             <div className="col">
                                                             <label>Token de la opción </label>
                                                            <input  
                                                                type="text" 
                                                                className="form-control" 
                                                                disabled={(this.state.disabled)? "disabled" : ""}
                                                                placeholder="Token de la opción" 
                                                                value={this.state.tknbtn}
                                                                onChange={(e)=>this.setState({
                                                                    tknbtn: e.target.value
                                                                    })}/>
                                                            </div>
                                                             <div className="col">
                                                                 <br/>
                                                                 <a className="btn btn-primary" onClick={(e)=>{
                                                                     e.preventDefault();
                                                                    if(this.state.valbtn!==''){
                                                                        if(btns.length<3){
                                                                            const btn={
                                                                                description:this.state.valbtn,
                                                                                index: btns.length,
                                                                                token: this.state.tknbtn
                                                                            }
                                                                            this.setState({
                                                                                buttons:this.state.buttons.concat(btn),
                                                                                valbtn:'',
                                                                                mesagge:'',
                                                                                tknbtn:''
                                                                            })
                                                                        }else{
                                                                            this.setState({
                                                                                mesagge:'Se puede tener máximo 3 botones'
                                                                            })
                                                                        }
                                                                        
                                                                    }else{
                                                                        this.setState({
                                                                            mesagge:'Campo vacio'
                                                                        })
                                                                    }
                                                                }}>
                                                                    <span className="glyphicon glyphicon-plus"></span> Agregar botón
                                                                </a>
                                                             </div>
                                                         </div>
                                                         <p>{this.state.mesagge}</p>
                                                         <div className="table-responsive"> 
                                                 <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                        <th>Opción</th>
                                                        <th>Token</th>                
                                                        </tr>
                                                    </thead>
                                                <tbody>
                                                     {
                                                         btns.map((item,i)=>{
                                                             return (
                                                                 <tr key={item.index}>
                                                                     <td>
                                                                     {item.description}
                                                                     </td>
                                                                     <td>
                                                                     {item.token}
                                                                     </td>
                                                                     <td>
                                                                     <button 
                                                                             disabled={(this.state.disabled)? "disabled" : ""}
                                                                             className="btn btn-primary" onClick={(e)=>{
                                                                                 e.preventDefault();
                                                                            var par=[];
                                                                             for(var i=0; i<btns.length;i++){
                                                                             if(btns[i].index!==item.index){
                                                                                     par=par.concat(btns[i]);
                                                                                 }
                                                                             }
                                                                             this.setState({
                                                                                 buttons:par
                                                                             })
                                                                         }}>
                                                                         <span className="glyphicon glyphicon-trash"></span> 
                                                                         </button>
                                                                     </td>
                                                                 </tr>
                                                             )
                                                         })
                                                     }
                                                </tbody>
                                                 </table>
                                                 </div>
                                                     </>
                                                 )
                                             }    
                                             </>
                                         )
                                     }
                                     <br/>
                                     <h3>Variables</h3>
                                     <br/>
                                     {
                                         parameters.map((item,i)=>{
                                             return (
                                                 <div key={item.index}>
                                                     <div className="row"  >
                                                     <div className="col">
                                                             <label>{item.index}</label>
                                                         </div><div className="col">
                                                             <label>{item.description}</label>
                                                         </div>
                                                         <div className="col">
                                                             <button 
                                                             disabled={(this.state.disabled)? "disabled" : ""}
                                                             className="btn btn-primary" 
                                                             onClick={(e)=>{
                                                                 e.preventDefault();
                                                                 var par=[];
                                                                 parameters.splice(item.index-1,1)
                                                                 for(var i=0; i<parameters.length;i++){
                                                                         var pari={
                                                                             index:i+1,
                                                                             description:parameters[i].description
                                                                         }
                                                                         par=par.concat(pari);
                                                                 }  
                                                                 this.setState({
                                                                     parameters:par
                                                                 })
                                                             }}>
                                                                 <span className="glyphicon glyphicon-trash"></span> 
                                                             </button>
                                                         </div>
                                                 </div>
                                                     <br/>
                                                 </div>
                                             )
                                         })
                                     }
                                     <div className="row">
                                     <div className="col">
                                         <label>Variables</label>
                                         <input  
                                             type="text" 
                                             className="form-control" 
                                             placeholder="Parameter text" 
                                             value={this.state.valpar}
                                             disabled={(this.state.disabled)? "disabled" : ""}
                                             onChange={(e)=>{
                                                if ( /[^A-Za-z\d]/.test(e.target.value)) {
                                                    this.setState({
                                                        valpar: e.target.value.substring(0, e.target.value.length - 1),
                                                        mesaggepar:"Ingrese solo letras y caracteres numéricos"
                                                    })
                                                }else{
                                                    this.setState({
                                                        valpar: e.target.value.toLocaleLowerCase(),
                                                        mesaggepar:""
                                                    })
                                                }
                                            }
                                             }/>
                                     </div>
                                     <div className="col">
                                         <br/>
                                         <button 
                                         disabled={(this.state.disabled)? "disabled" : ""}
                                         className="btn btn-primary" onClick={(e)=>{
                                            e.preventDefault()
                                         if(this.state.valpar!==''){
                                             if(parameters.length<10){
                                                 if(this.state.contador>parameters.length){
                                                    const btn={
                                                        description:this.state.valpar,
                                                        index:parseInt(parameters.length)+1
                                                    }
                                                    this.setState({
                                                        parameters:this.state.parameters.concat(btn),
                                                        valpar:'',
                                                        mesaggepar:''
                                                    })
                                                 }else{
                                                    this.setState({
                                                        mesaggepar:'El número de variables no coincide con la cantidad de variables en el cuerpo del mensaje'
                                                    })    
                                                 }
                                                 
                                             }else{
                                                 this.setState({
                                                     mesaggepar:'Se puede tener máximo 10 variables'
                                                 })
                                             }
                                         }else{
                                             this.setState({
                                                 mesaggepar:'Campo vacio'
                                             })
                                         }
                                         }}>
                                             <span className="glyphicon glyphicon-plus"></span> Agregar variable
                                         </button>
                                     </div>
                                     <p>{this.state.mesaggepar}</p>                               
                                 </div>
                                     <button disabled={(this.state.disabled)? "disabled" : ""} id="btncreate" className="paddingbutton btn btn-block btn-outline-primary" 
                                     onClick={this.handleCreate}>Crear</button>
                                     <a href="/template-list" className="paddingbutton btn btn-outline-info"  role="button" aria-disabled="true">Volver</a>  
                                     <div>
                                     <label>{this.state.mensaje}</label>
                                     </div>
                                 </div>
                             </div>
                             
                             <div className="col-sm-4">
                                 <br/>
                                 <br/>
                                 <div className="container" style={{backgroundImage:"url("+this.state.picture+")", width: "auto", marginLeft: "20px", paddingBottom:"20px",marginRight:"20px", minWidth:"350px"}}>
                                     <h4 style={{paddingTop:"15px"}}>Visualización</h4>
                                     <div className="row" style={{marginBottom: "8px",width: "auto"}}>
                                             <div className="col-sm" style={{paddingTop: "15px",paddingLeft: "15px", paddingRight: "30px"}}>
                                         <div className="alert-secondary" 
                                             style={{textAlign:"start" ,fontSize: "12px", paddingTop: "0px",paddingLeft: "10px",paddingRight: "10px", paddingBottom:"3px",borderRadius: this.state.border, backgroundColor:"white"}}>
                                             {
                                                 this.state.tipo==='none'?(
                                                     <></>
                                                 ):(
                                                     <>
                                                     {
                                                         this.state.tipo==='text'?(
                                                         <>
                                                             <span>{this.state.t_header_text}</span><br />
                                                         </>
                                                         ):(
                                                         <>
                                                         {
                                                             this.state.t_header_text!=''?(<>
                                                             <Image src={this.state.t_header_text} style={{height:"auto", width:"100%"}}></Image> 
                                                             <br/></>):(<></>)
                                                         }
                                                         </>
                                                         )
                                                     }
                                                     </>
                                                 )
                                             }
                                             <div>
                                             {this.state.t_body_text} 
                                             </div>
                                             <div className="row" >
                                                 <div className="col-sm-8">
                                                     <small className="text-muted">
                                                     {this.state.t_footer}
                                                     </small>
                                                 </div>
                                                 <div className="col-sm-4" style={{textAlign:"end", height:"auto"}}>
                                                     <small className="text-muted">
                                                     {show}
                                                     </small>
                                                 </div>
                                             </div>
                                         </div>
                                            
                                         {
                                                 this.state.tipobtn=='none'?(
                                                     <>
                                                     </>
                                                 ):(
                                                     <>
                                                     {
                                                         this.state.tipobtn=='callaction'?(
                                                         <>
                                                          {
                                                             btnsac.map((item,i)=>{
                                                                 
                                                                 return (
                                                                     <div key={i} className={"alert-secondary"} 
                                                                         style={{textAlign:"center",borderRight:"inset",borderLeft:"outset",marginTop:"2px",fontSize: "12px",padding:"5px", color:"blue",borderRadius: "10px 10px 10px 10px", backgroundColor:"white"}}>
                                                                         {
                                                                            item.tipo=='call'?(
                                                                                <>
                                                                                    <span style={{paddingRight:"5px"}} className="glyphicon glyphicon-earphone"></span>
                                                                                    <label > {item.texto}</label>
                                                                                </>
                                                                            ):(
                                                                                <>
                                                                        <label > {item.texto}</label>
                                                                                </>
                                                                            )
                                                                        }   
                                                                     </div>
                                                                 )})
                                                         }
                                                         </>
                                                         ):(
                                                             <>
                                                            <div className="row" style={{margin:"1px"}}>
                                                         {
                                                             btns.map((item,i)=>{
                                                                 var estilo=" col-sm-6"
                                                                 if(i==2){
                                                                     estilo=" col-sm-12"
                                                                 }
                                                                 return (
                                                                     <div key={i} className={"alert-secondary"+ estilo} 
                                                                         style={{textAlign:"center",borderRight:"inset",borderLeft:"outset",marginTop:"2px",fontSize: "12px",padding:"5px", color:"blue",borderRadius: "10px 10px 10px 10px", backgroundColor:"white"}}>
                                                                         <label>{item.description}</label>
                                                                     </div>
                                                                 )})
                                                         }
                                                         <br/>

                                                         </div>
                                                             </>
                                                         )
                                                     }
                                                 </>
                                                 )
                                             }
    
                                     </div>
                                     
                                 </div>
                            </div>
                             </div>
                         </div>
    
                     </div>
                     <br/>
              </div>
             </>
            );
    }
}