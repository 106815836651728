import React, { Component } from "react";
import { getToken } from "../../auth-helpers";
const usermanager = require('../../ServerFunctions/usermanager');

export default class UserDelete extends Component {
constructor(props){
    super(props);
    this.state={
        email:props.location.state.user.userName,
        mensaje:'',
        disabled:false
    }
    this.handleDelete=this.handleDelete.bind(this);
} 

handleDelete(event){
    event.preventDefault();
    this.setState({
        disabled:true,
        mensaje:"Espere mientras eliminamos el usuario"
    })
      var authToken = getToken();
      var thisa=this;
      usermanager.EliminarUser(authToken,this.state.email, function(users) {
        if(users){
            if(users.status==="success"){
                thisa.setState({
                    mensaje:"Usuario eliminado correctamente"
                })
                setTimeout(function(){
                    window.location.href="/user-list"
                }, 1000);
            }else{
                thisa.setState({
                disabled:false,
                mensaje:"No se pudo eliminar el usuario. Intentelo más tarde"
                })
            }
        }else{
            thisa.setState({
                disabled:false,
                mensaje:"No se pudo eliminar el usuario. Intentelo más tarde"
            })
        }
      });
}

    render() {
        return (
            <div className="auth-wrapper">
              <div className="auth-inner">
              <form >
                <p>Esta seguro de eliminar el usuario: {this.state.email}?</p>
                <button type="submit" className="paddingbutton btn btn-block btn-outline-primary" 
                disabled={(this.state.disabled)? "disabled" : ""}
                onClick={this.handleDelete}>Eliminar</button>
                <a href="/user-list" className="paddingbutton btn btn-outline-info"  role="button" aria-disabled="true">Volver</a>  
                <div>
                    <label>{this.state.mensaje}</label>
                </div>
            </form>
            </div>
            </div>
        );
    }
}