import React, { Component } from "react";
import { getToken } from "../../auth-helpers";
const usermanager = require('../../ServerFunctions/usermanager');
export default class UserEnable extends Component {
constructor(props){
    super(props);
    this.state={
        email:props.location.state.user.userName,
        enabled:props.location.state.user.enabled,
        user:props.location.state.user,
        mensaje:'',
        disabled:false
    }
    this.handleEnable=this.handleEnable.bind(this);
}
 
handleEnable(event){
    event.preventDefault();
        this.setState({
            disabled:true,
            mensaje:"Espere mientras actualizamos el usuario."
        })
      var authToken = getToken();
      var thisa=this;
      var user={
        userName:this.state.email,
        enable:!this.state.enabled
      }
      usermanager.EnabledUser(authToken,user, function(users) {
        if(users){
                localStorage.setItem('userupdate','true');
                thisa.setState({
                    mensaje:"Información de usurario actualizada correctamente"
                })
                setTimeout(function(){
                    window.location.href="/user-list"
                }, 1000);
        }else{
            thisa.setState({
                disabled:false,
                mensaje:"No se pudo actualizar la información del usuario. Intentelo más tarde."
            })
        }
      });
    
}

    render() {
        return (
            <div className="auth-wrapper">
              <div className="auth-inner">
              <form >
                <p>Esta seguro de {this.state.enabled? 'deshabilitar' : 'habilitar' } el usuario: {this.state.email}?</p>
                <button type="submit" className="paddingbutton btn btn-block btn-outline-primary" 
                 disabled={(this.state.disabled)? "disabled" : ""}
                 onClick={this.handleEnable}>{this.state.enabled? 'Deshabilitar' : 'Habilitar' }</button>
                <a href="/user-list" className="paddingbutton btn btn-outline-info"  role="button" aria-disabled="true">volver</a>  
                <div>
                <label>{this.state.mensaje}</label>
                </div>
            </form>
            </div>
            </div>
        );
    }
}