import React, { Component} from "react";
import { getTenant, getToken } from "../../../auth-helpers";
import {Link} from 'react-router-dom';
import jwtDecode from 'jwt-decode'
const templatemanager = require('../../../ServerFunctions/templatemanager.js');
export default class TenantTemplateList extends Component {
constructor(props){
    super(props);
    this.state={
        templates:[],
        user:null
    }    
}

componentDidMount(){
    var authToken = getToken();
    var thisa= this;
    var usr;
    if(authToken){
        var user = jwtDecode(authToken);
        usr={
            companyName:user['custom:companyName'],
            email:user['email'],
            role:user['custom:role']
        };
        this.setState({
            user:usr
        })
    }
        var tenant=getTenant();
        templatemanager.ListTemplateAdministrador(authToken,tenant.TenantId,function(templates) {
            templates.sort(function (a, b) {
                if (a.codigo> b.codigo) {
                    return 1;
                  }
                  if (a.codigo < b.codigo) {
                    return -1;
                  }
                  // a must be equal to b
                  return 0;
                
              });
              thisa.setState({
                templates
            })
        });
}

render() {
    var templates=this.state.templates;
    return (
        <>
        <div>
            <div id="cognitousers"  align="right">
                <a className="btn btn-primary" href="/template-add">
                    <span className="glyphicon glyphicon-plus"></span> Agregar Plantilla
                </a>
            </div>
            <div id="cognitousers" className="table-responsive">
            <table className="table table-striped">
                <thead>
                    <tr>
                    <th>#</th>
                    <th>Nombre</th>
                    <th>País</th>
                    <th>Validado</th>
                    <th>Acciones</th>
                    </tr>
                </thead>
            <tbody>
                {
                    templates?(
                        <>
                        { templates.map((item, i) => {
                            return (
                                <tr key={item.template_id}>
                                    <td>{i+1}</td>
                                    <td>{item.template_name}</td>
                                    <td>
                                        {
                                            item.conector.codigo==="593"?("EC"):(
                                            <>
                                                {
                                                   item.conector.codigo==="51"?("PE"):("COL")
                                                }
                                            </>)
                                        }
                                        </td>
                                    <td>{item.verificacion}</td>
                                    
                                    <td>
                                    <div className="btn-group">
                                        {
                                            this.state.user?(<>
                                            {
                                                item.verificacion==="pendiente"?(<>
                                                    {
                                                        this.state.user.role==="SystemAdmin"?(
                                                            <>
                                                                <Link
                                                                className="paddingicon" title="Edit"
                                                                to={{
                                                                    pathname: "/template-edit",
                                                                    state: { template: item, edit:false }
                                                                }}>
                                                                    <span className="glyphicon glyphicon-pencil"></span>
                                                                </Link>
                                                            </>
                                                        ):(
                                                            <>
                                                            <Link
                                                                className="paddingicon" title="Edit"
                                                                to={{
                                                                    pathname: "/template-edit",
                                                                    state: { template: item,edit:false }
                                                                }}>
                                                                    <span className="glyphicon glyphicon-eye-open"></span>
                                                            </Link>
                                                            </>
                                                        )
                                                    }
                                                </>):(<>
                                                    <Link
                                                        className="paddingicon" title="View"
                                                        to={{
                                                            pathname: "/template-edit",
                                                            state: { template: item,edit:true }
                                                        }}>
                                                            <span className="glyphicon glyphicon-eye-open"></span>
                                                        </Link>
                                                </>)
                                            }
                                            </>):(<></>)
                                        }
                                        <Link
                                        className="paddingicon" title="Delete"
                                        to={{
                                            pathname: "/template-delete",
                                            state: { template: item }
                                        }}>
                                            <span className="glyphicon glyphicon-trash"></span>
                                        </Link>
                                    </div>
                                    </td>
                                </tr>
                            );
                        })}
                        </>
                    ):(
                        <>
                        </>
                    )
                }
            </tbody>
            </table>
            </div>
            </div>         

      </>

    );
}
}