import React, { Component } from "react";
import { getTenant, getToken } from "../../../auth-helpers";
const conectormanager = require('../../../ServerFunctions/conectormanager.js');
export default class TenantConectorEdit extends Component {
constructor(props){
    super(props);
    this.state={
        id:props.location.state.conector.conector_id,
        aplicationid:props.location.state.conector.aplication_id,
        privatekey:props.location.state.conector.private_key,
        baseurl:props.location.state.conector.base_url,
        messageurl:props.location.state.conector.message_url,
        numwhats:props.location.state.conector.num_whatsapp,
        codwhats:props.location.state.conector.cod_whatsapp,
        con_name:props.location.state.conector.nombre,
        hsmcost:props.location.state.conector.costo_hsm,
        sessioncost:props.location.state.conector.costo_sesion,
        sessiontype:props.location.state.conector.tipo_sesion,
        disabled:false,
        provider_id:props.location.state.conector.provider_id,
        provider_name:props.location.state.conector.provider_name,
        namespace:props.location.state.conector.namespace,
        token:props.location.state.conector.token_conector,
        providers:[],
        pais:true
    }
    this.handleEdit=this.handleEdit.bind(this);
}


componentDidMount(){
    var authToken = getToken();
        var thisa= this;
        conectormanager.ListProviders(authToken,function(providers) {
            thisa.setState({
                providers
            })
        });
}
handleEdit(event){
    event.preventDefault();
    this.setState({
        disabled:true,
        mensaje:'Espere mientras se actualiza el Conector'
    })
    
    if(this.state.aplicationid=="" || this.state.privatekey=="" ||
    this.state.baseurl=="" || this.state.messageurl=="" ||
    this.state.numwhats=="" || this.state.hsmcost=="" || this.state.sessioncost=="" ||
    this.state.con_name=="" || this.state.sessiontype=="" || this.state.codwhats==""
    ){
        this.setState({
            mensaje:'Campos vacios',
            disabled:false
        })
        return
    }
    if(this.state.numwhats.length==11 ||this.state.numwhats.length==10 || this.state.numwhats.length==9){
        if(this.state.numwhats.charAt(0)!="0"){
            this.setState({
                mensaje:'Número de whatsApp incorrecto',
                disabled:false
            })
            return
        }
    }else{
        this.setState({
            mensaje:'Número de whatsApp incorrecto',
            disabled:false
        })
        return
    }

    if((this.state.provider_name.trim()=="Vonage" || this.state.provider_name.trim()=="vonage") && (this.state.namespace=='')){
        this.setState({
            mensaje:'Las plantillas con el proveedor vonage requieren del namespace',
            disabled:false
        })
        return
    }

    var whatsapp=''
    if(this.state.provider_name.trim()=="Vonage" || this.state.provider_name.trim()=="vonage"){
        whatsapp=this.state.codwhats+this.state.numwhats.slice(1)
    }else{
        whatsapp="whatsapp:+"+this.state.codwhats+this.state.numwhats.slice(1)
    }
    var conector={
        conector_id:this.state.id,
        aplication_id:this.state.aplicationid,
        private_key:this.state.privatekey,
        base_url:this.state.baseurl,
        message_url:this.state.messageurl,
        num_whatsapp:this.state.numwhats,
        cod_whatsapp:this.state.codwhats,
        nombre:this.state.con_name,
        costo_hsm:this.state.hsmcost,
        costo_sesion:this.state.sessioncost,
        tipo_sesion:this.state.sessiontype,
        provider_name:this.state.provider_name,
        provider_id:this.state.provider_id,
        namespace:this.state.namespace,
        token_conector:this.state.token||"",
        whatsapp:whatsapp
    }
     var authToken = getToken();
    var thisa=this;
    var tenant=getTenant();
    conectormanager.EditarConectorAdministrador(authToken,conector,tenant.TenantId, function(conectors) {
        if(conectors){
            if(conectors.status==="success"){
                thisa.setState({
                    mensaje:"Conector actualizado correctamente"
                })
                setTimeout(function(){
                    window.location.href="/conector-list"
                }, 1000);
            }else{
                thisa.setState({
                    mensaje:"No se pudo actualizar el conector. Intentelo más tarde"
                })
                
                setTimeout(function(){
                    window.location.href="/conector-list"
                }, 2000);
            }
        }else{
            thisa.setState({
                mensaje:"No se pudo actualizar el conector. Intentelo más tarde"
            })
            
            setTimeout(function(){
                window.location.href="/conector-list"
            }, 2000);
        }
    }); 
}

render() {
    var providers=this.state.providers
    return (
        <>
        <div>
        <br/>
                <div className="template-inner" style={{maxWidth:"650px"}}>
                    <h3>Agregar Conector</h3>
        <br/>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Nombre del conector</label>
                        <div className="col-sm-9">
                            <input 
                            type="text" 
                            disabled={(this.state.disabled)? "disabled" : "" }
                            className="form-control" 
                            placeholder="Nombre del conector" 
                            value={this.state.con_name}
                            onChange={(e)=>this.setState({
                                con_name: e.target.value
                                })}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Proveedor</label>
                        <div className="col-sm-9">
                        <select 
                        className="form-select"
                            disabled={(this.state.disabled)? "disabled" : "" }
                            value={this.state.provider_id}
                        onChange={
                            (e)=>{
                                this.setState({
                                    provider_id: e.target.value,
                                    provider_name: e.target[e.target.selectedIndex].text

                        })}}
                        >
                        <option value='' >Escoger Proveedor</option>
                        {
                            providers.length>0?(
                                <>{
                                providers.map((item, i) => {
                                        return (
                                            <option key={item.provider_id} value={item.provider_id} >{item.tipo_nombre}</option>
                                        )
                                    })
                                }
                                </>
                            ):(
                                <>
                                    <option value='' >No existen Proveedores</option>
                                </>
                            )                                
                        }
                    </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label  className="col-sm-3 col-form-label">Aplication ID</label>
                        <div className="col-sm-9">
                        <input  
                            type="text" 
                            disabled={(this.state.disabled)? "disabled" : "" }
                            className="form-control" 
                            placeholder="Aplication ID" 
                            value={this.state.aplicationid}
                            onChange={(e)=>this.setState({
                            aplicationid: e.target.value
                            })}/>
                            </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Private Key</label>
                        <div className="col-sm-9">
                            <input  
                            type="text" 
                            disabled={(this.state.disabled)? "disabled" : "" }
                            className="form-control" 
                            placeholder="Private Key" 
                            value={this.state.privatekey}
                            onChange={(e)=>this.setState({
                            privatekey: e.target.value
                            })}/>
                        </div>
                    </div>
                    {
                            (this.state.provider_name=="Vonage" || this.state.provider_name=="vonage")?(<>
                                <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Namespace</label>
                                <div className="col-sm-9">
                                    <input  
                                    type="text" 
                                    disabled={(this.state.disabled)? "disabled" : "" }
                                    className="form-control" 
                                    placeholder="Namespace" 
                                    value={this.state.namespace}
                                    onChange={(e)=>this.setState({
                                    namespace: e.target.value
                                    })}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Token V1</label>
                                <div className="col-sm-9">
                                    <input  
                                    type="text" 
                                    disabled={(this.state.disabled)? "disabled" : "" }
                                    className="form-control" 
                                    placeholder="Token" 
                                    value={this.state.token}
                                    onChange={(e)=>this.setState({
                                    token: e.target.value
                                    })}/>
                                </div>
                            </div>
                           
                            </>):(<></>)

                        }
                    <div className="form-group row" >
                        <label className="col-sm-3 col-form-label">URL Base</label>
                        <div className="col-sm-9">
                            <input 
                            disabled={(this.state.disabled)? "disabled" : "" }
                            type="text" 
                            className="form-control" 
                            placeholder="URL Base" 
                            value={this.state.baseurl}
                            onChange={(e)=>this.setState({
                                baseurl: e.target.value
                                })}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Url de mensaje</label>
                        <div className="col-sm-9">
                            <input 
                            disabled={(this.state.disabled)? "disabled" : "" }
                            type="text" 
                            className="form-control" 
                            placeholder="Url de mensaje" 
                            value={this.state.messageurl}
                            onChange={(e)=>this.setState({
                                messageurl: e.target.value
                                })}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                    <label className="col-sm-3 col-form-label"># WhatsApp</label>
                            <div className="col-sm-9">
                                <div className="row">
                                    <div className="col-sm-4">
                                    <select 
                                        className="form-select"
                                        disabled={(this.state.pais)? "disabled" : "" }
                                        value={this.state.codwhats}
                                        defaultValue={this.state.codwhats}
                                        style={{paddingRight:"10px", maxWidth:"175px"}}
                                        >
                                                 <option value='' >Seleccionar país</option>
                                                <option value='54' >Argentina</option>
                                                <option value='591' >Bolivia</option>
                                                <option value='55' >Brasil</option>
                                                <option value='56' >Chile</option>
                                                <option value='57' >Colombia</option>
                                                <option value='593' >Ecuador</option>
                                                <option value='595' >Paraguay</option>
                                                <option value='51' >Perú</option>
                                                <option value='598' >Uruguay</option>
                                                <option value='58' >Venezuela</option>
                                    </select>
                                    </div>
                                    <div className="col-sm-8">
                                    <input 
                                        disabled={(this.state.disabled)? "disabled" : "" }
                                        type="number" 
                                        className="form-control" 
                                        placeholder="# WhatsApp" 
                                        value={this.state.numwhats}
                                        onChange={(e)=>this.setState({
                                        numwhats: e.target.value
                                        })}
                                    />
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Costo HSM </label>
                        <div className="col-sm-9">
                        <input 
                        type="number" 
                        disabled={(this.state.disabled)? "disabled" : "" }
                        className="form-control" 
                        placeholder="Costo HSM " 
                        value={this.state.hsmcost}
                        onChange={(e)=>this.setState({
                            hsmcost: e.target.value
                            })}
                        />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Costo de sesión</label>
                        <div className="col-sm-9">
                        <input 
                        type="number" 
                        disabled={(this.state.disabled)? "disabled" : "" }
                        className="form-control" 
                        placeholder="Costo de sesión" 
                        value={this.state.sessioncost}
                        onChange={(e)=>this.setState({
                            sessioncost: e.target.value
                            })}
                        />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label"> Tipo de sesión</label>
                        <div className="col-sm-9">
                            <input 
                            disabled={(this.state.disabled)? "disabled" : "" }
                            type="text" 
                            className="form-control" 
                            placeholder="Tipo de sesión" 
                            value={this.state.sessiontype}
                            onChange={(e)=>this.setState({
                                sessiontype: e.target.value
                                })}
                            />
                        </div>
                    <div/>
                    
                    </div>
                    <button className="paddingbutton btn btn-block btn-outline-primary" 
                    onClick={this.handleEdit}
                    disabled={(this.state.disabled)? "disabled" : "" }> Actualizar </button>
                    <a href="/conector-list" className="paddingbutton btn btn-outline-info"  role="button" aria-disabled="true">Volver</a>  
                    <div>
                        <label>{this.state.mensaje}</label>
                    </div>
                </div>
                <br/>
            </div>
            </>
    );
}
}