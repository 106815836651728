import jwtDecode from "jwt-decode";
import React, { Component } from "react";
import {Link} from 'react-router-dom';
import { getToken } from "../../auth-helpers";
import LoadingOverlay from 'react-loading-overlay-ts';
import 'datatables.net-dt/js/dataTables.dataTables.min.js';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import MyPDF from '../../Documentos/Manual_Usuario.pdf'
const $ = require('jquery');
$.DataTable = require('datatables.net');
const usermanager = require('../../ServerFunctions/usermanager');
export default class UserList extends Component {
constructor(props){
    super(props);
    this.state={
        users:[],
        role:'',
        isActive:true,
        email:""
    }
}

componentDidMount(){
        var authToken = getToken();
        var usr=jwtDecode(authToken);
        this.setState({
            role:usr['custom:role'],
            email:usr['email']
            
        })
        var thisa= this;
        if(authToken){
            usermanager.ListUser(authToken,function(users) {
                thisa.setState({
                    users,
                    isActive:false,
                })
                $('#usersPC').DataTable();
            });
        }
}

    render() {
        var usuarios=this.state.users;
        var isActive=this.state.isActive;
        return (
            <LoadingOverlay
            active={isActive}
            spinner
            styles={{
              wrapper: {
                width: '100%',
                height: '100%',
                overflow: isActive ? 'hidden' : '',
              },
            }}
            text="Cargando ..."
          >
              <div >
                
              
                  {
                      this.state.role==="TenantUser"?(<>
                      <a
                        type="button"
                        href={MyPDF}
                        download="Manual_Usuario.pdf"
                        class="btn btn-outline-secondary btndescarga"
                        style={{ float: 'right' }}
                        miatooltip="Descargar módelo de lista de contacto"
                    >
                    <p>Descargar manual de usuario</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  fill="currentColor"
                  class="bi bi-gear-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708z"></path>
                </svg>
                <span class="visually-hidden">Button</span>
              </a>
              </>):(<>
                        <div id="cognitousers"  align="right">
                        <a className="btn btn-primary" href="/user-add" style={{marginTop:'5px', marginRight:'5px'}}>
                            <span className="glyphicon glyphicon-plus"></span> Agregar Usuario
                        </a>
                        <a
                        type="button"
                        href={MyPDF}
                        download="Manual_Usuario.pdf"
                        class="btn btn-outline-secondary btndescarga"
                        style={{ float: 'right' }}
                        miatooltip="Descargar módelo de lista de contacto"
                    >
                    <p>Descargar manual de usuario</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  fill="currentColor"
                  class="bi bi-gear-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708z"></path>
                </svg>
                <span class="visually-hidden">Button</span>
              </a>
                        </div>
              </>
                      )
                       
                  }
                  
            <div id="cognitousers" className="table-responsive">
            <table id="usersPC"
            className="display"
            style={{ width: '100%', float: 'left' }}>
                <thead>
                    <tr>
                    <th>Nombre</th>
                    <th>Apellido</th>
                    <th>Correo electrónico</th>
                    <th>Rol</th>
                    <th>Estado</th>
                    <th>Fecha de creación</th>
                    <th>Acciones</th>
                    </tr>
                </thead>
            <tbody>
                {
                    usuarios?(
                        <>
                        {usuarios.map((item, i) => {
                            return (
                                <tr key={item.userName}>
                                    <td>{item.firstName}</td>
                                    <td>{item.lastName}</td>
                                    <td>{item.userName}</td>
                                    <td>{item.role ==="TenantAdmin" ? 'Administrador':(<>{item.role==="SystemAdmin"?'Super Administrador':'Usuario'}</>)}</td>
                                    <td>{item.enabled ? 'Active' : 'Inactive'}</td>
                                    <td>{item.dateCreated.toLocaleString()}</td>
                                    <td>
                                    <div className="btn-group">
                                        {
                                            this.state.email===item.userName?(
                                                <>
                                                -
                                                </>
                                            ):(
                                                <>
                                                 {
                                            this.state.role==="SystemAdmin" ?(
                                                <>
                                                    <Link
                                                        className="paddingicon" title="Edit"
                                                        to={{
                                                            pathname: "/user-edit",
                                                            state: { user: item }
                                                        }}>
                                                            <span className="glyphicon glyphicon-pencil"></span>
                                                        </Link>
                                                        <Link
                                                        className="paddingicon" title="Delete"
                                                        to={{
                                                            pathname: "/user-delete",
                                                            state: { user: item }
                                                        }}>
                                                            <span className="glyphicon glyphicon-trash"></span>
                                                        </Link>
                                                        <Link
                                                        className="paddingicon" title="Enabled"
                                                        to={{
                                                            pathname: "/user-enabled",
                                                            state: { user: item }
                                                        }}>
                                                            <span className="glyphicon glyphicon-check"></span>
                                                        </Link>
                                                </>
                                            ):(
                                                <>
                                                {
                                                    this.state.role==="TenantAdmin"?(
                                                        <>
                                                        {
                                                    item.role!=="SystemAdmin"?(
                                                        <><Link
                                                        className="paddingicon" title="Edit"
                                                        to={{
                                                            pathname: "/user-edit",
                                                            state: { user: item }
                                                        }}>
                                                            <span className="glyphicon glyphicon-pencil"></span>
                                                        </Link>
                                                        <Link
                                                        className="paddingicon" title="Delete"
                                                        to={{
                                                            pathname: "/user-delete",
                                                            state: { user: item }
                                                        }}>
                                                            <span className="glyphicon glyphicon-trash"></span>
                                                        </Link>
                                                        <Link
                                                        className="paddingicon" title="Enabled"
                                                        to={{
                                                            pathname: "/user-enabled",
                                                            state: { user: item }
                                                        }}>
                                                            <span className="glyphicon glyphicon-check"></span>
                                                        </Link>
                                                        </>
                                                    ):(
                                                        <>
                                                        -
                                                        </>
                                                    )
                                                }</>
                                                    ):(<>
                                                    -</>)
                                                }
                                                </>
                                            )
                                        }
                                                </>
                                            )
                                        }
                                    </div>
                                    </td>
                                </tr>
                            );
                        })}        
                    </>
                    ):(
                        <>
                        
                        </>
                    )
                }
            </tbody>
          </table>
          </div>
          </div> 
          </LoadingOverlay>
                
        );
    }
}