import React, { Component } from "react";
import { getTenant, getToken } from "../../../auth-helpers";
const conectormanager = require('../../../ServerFunctions/conectormanager.js');
export default class TenantConectorDelete extends Component {
constructor(props){
    super(props);
    this.state={
        id:props.location.state.conector.conector_id,
        aplicationid:props.location.state.conector.aplication_id,
        privatekey:props.location.state.conector.private_key,
        baseurl:props.location.state.conector.base_url,
        messageurl:props.location.state.conector.message_url,
        numwhats:props.location.state.conector.num_whatsapp,
        mensaje:'',
        disabled:false,
    }
    this.handleDelete=this.handleDelete.bind(this);
}
 
handleDelete(event){
    event.preventDefault();
    this.setState({
        disabled:true,
        mensaje:'Espere mientras se elimina el conector'
    })
    const thisa = this; 
    var authToken = getToken();
        var tenant=getTenant();
    conectormanager.EliminarConectarAdministrador(this.state.id,tenant.TenantId,authToken,function(tenant) {
        if(tenant){
            thisa.setState({
                mensaje:tenant.mensaje
            })
            setTimeout(function(){
                window.location.href="/conector-list"
            }, 2000);
        }else{
            thisa.setState({
                mensaje:'No se pudo eliminar el conector. Intentelo más tarde'
            })
            setTimeout(function(){
                window.location.href="/conector-list"
            }, 2000);
        }
    })
}

render() {
    var tenant=getTenant();
    return (
        <div className="auth-wrapper">
          <div className="auth-inner">
          <form role="form">
            <p>Esta segura de elimar el conector: {this.state.aplicationid} de la organización {tenant.CompanyName }?</p>
            <button type="submit" 
               disabled={(this.state.disabled)? "disabled" : ""}
               className="paddingbutton btn btn-block btn-outline-primary" onClick={this.handleDelete}>Eliminar</button>
            <a href="/conector-list" className="paddingbutton btn btn-outline-info "  role="button" aria-disabled="true">Volver</a>  
            <div>
                <label>{this.state.mensaje}</label>
            </div>
        </form>
        </div>
        </div>
    );
}
}