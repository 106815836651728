import React from 'react';
import { useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
const usermanager = require('../../ServerFunctions/usermanager');
const Password = () => {
  const [email, setEmail] = useState(window.localStorage.getItem('email'));
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [companyname, setCompanyName] = useState(
    window.localStorage.getItem('org')
  );
  const [saber] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [iscode, setIsCode] = useState(
    window.localStorage.getItem('iscode') === 'true'
  );
  const onSubmit = (event) => {
    event.preventDefault();
    setIsActive(true);
    if (email === '' || companyname === '') {
      setIsActive(false);
    } else {
      var user = {
        userName: email,
        companyName: companyname,
      };
      usermanager.GetCode(user, function (users) {
        if (users.mensaje === 'No existe') {
          setIsActive(false);
          alert(
            'No se encontro el usuario. \n\n Verifique su dirección de correo electrónico y organización e inténtelo de nuevo.'
          );
        } else {
          if (!users.status) {
            setIsActive(false);
            setIsCode(true);
            window.localStorage.setItem('iscode', true);
            window.localStorage.setItem('org', companyname);
            window.localStorage.setItem('email', email);
            alert(
              'Se ha enviado el código para actualizar su contraseña a su dirección de correo electrónico.'
            );
          } else {
            setIsActive(false);
            alert(
              'No se pudo enviar el código de actualización de contraseña. Intentelo nuevamente.\n\n Si el problema persiste solicite una nueva contraseña con el administrador de la plataforma.'
            );
          }
        }
      });
    }
  };

  const handleRecuperar = (event) => {
    event.preventDefault();
    setIsActive(true);
    if (email === '' || companyname === '' || password === '' || code === '') {
      setIsActive(false);
    } else {
      var user = {
        userName: email,
        companyName: companyname,
        code: code,
        password: password,
      };
      usermanager.NewPassword(user, function (users) {
        if (users.mensaje === 'No existe') {
          setIsActive(false);
          alert(
            'No se encontro el usuario. \n\n Verifique su dirección de correo electrónico y organización e inténtelo de nuevo.'
          );
        } else {
          if (!users.status) {
            setIsActive(false);
            window.localStorage.clear();
            alert(
              'Se ha actualizado exitosamente la contraseña. Inicie Sesión '
            );
            window.location.href = '/';
          } else {
            setIsActive(false);
            alert(
              'No se pudo actualizar la contraseña.\n' +
                users.msg +
                '\n Intentelo nuevamente.'
            );
          }
        }
      });
    }
  };

  const handleSalir = (event) => {
    event.preventDefault();
    setIsActive(true);
    window.localStorage.clear();
    window.location.href = '/';
  };

  const handleCodigo = (event) => {
    event.preventDefault();
    setIsActive(false);
    setIsCode(true);
    window.localStorage.setItem('iscode', true);
  };
  return (
    <LoadingOverlay
      active={isActive}
      spinner
      styles={{
        wrapper: {
          width: '100%',
          height: '100%',
          overflow: isActive ? 'hidden' : '',
        },
      }}
      text="Cargando ..."
    >
      <div className="auth-wrapper">
        <div className="auth-inner">
          <form onSubmit={onSubmit.bind(this)}>
            <br />
            <div className="input-group input-group-lg ">
              <span class="input-group-text txtemail" id="basic-addon1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-envelope-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z" />
                </svg>
              </span>
              <input
                type="email"
                className="form-control txtemail"
                placeholder="Dirección de correo electrónico"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <span
              className="msgEmail"
              style={{ color: 'red', display: 'none' }}
            >
              Introduzca su correo electrónico
            </span>
            {iscode ? (
              <>
                <br />
                <div className="input-group input-group-lg ">
                  <span class="input-group-text txtcode" id="basic-addon1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-file-lock2-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7 6a1 1 0 0 1 2 0v1H7V6z" />
                      <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm-2 6v1.076c.54.166 1 .597 1 1.224v2.4c0 .816-.781 1.3-1.5 1.3h-3c-.719 0-1.5-.484-1.5-1.3V8.3c0-.627.46-1.058 1-1.224V6a2 2 0 1 1 4 0z" />
                    </svg>
                  </span>
                  <input
                    className="form-control txtPsw"
                    placeholder="Código"
                    value={code}
                    autocomplete="new-password"
                    onChange={(e) => setCode(e.target.value)}
                  />
                </div>
                <span
                  className="msgcode"
                  style={{ color: 'red', display: 'none' }}
                >
                  Introduzca el código
                </span>
                <br />
                <div className="input-group input-group-lg ">
                  <span class="input-group-text txtPsw" id="basic-addon1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-file-lock2-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7 6a1 1 0 0 1 2 0v1H7V6z" />
                      <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm-2 6v1.076c.54.166 1 .597 1 1.224v2.4c0 .816-.781 1.3-1.5 1.3h-3c-.719 0-1.5-.484-1.5-1.3V8.3c0-.627.46-1.058 1-1.224V6a2 2 0 1 1 4 0z" />
                    </svg>
                  </span>
                  <input
                    type="password"
                    className="form-control txtpassw"
                    placeholder="Nueva Contraseña"
                    value={password}
                    autocomplete="new-password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <span
                  className="msgPassword"
                  style={{ color: 'red', display: 'none' }}
                >
                  Introduzca su contraseña
                </span>
              </>
            ) : (
              <></>
            )}
            <br />
            <div className="input-group input-group-lg ">
              <span class="input-group-text txtorga" id="basic-addon1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-building"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"
                  />
                  <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z" />
                </svg>
              </span>
              <input
                type="text"
                className="form-control txtorga"
                placeholder="Ingrese su organización"
                value={companyname}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>
            <span className="msgOrga" style={{ color: 'red', display: 'none' }}>
              Introduzca su organización
            </span>
            <br />
            {iscode ? (
              <>
                <button
                  onClick={handleRecuperar.bind(this)}
                  className="btn btn-primary btn-block btn-login paddingbutton"
                  title="Recuperar Contraseña"
                >
                  Actualizar Contraseña
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={onSubmit.bind(this)}
                  className="btn btn-primary btn-block btn-login paddingbutton"
                  title="Recuperar Contraseña"
                >
                  Enviar código de recuperación
                </button>
                <br />
                <br />
                <div style={{ textAlign: 'center', fontSize: '12px' }}>
                  <a href="/" onClick={handleCodigo.bind(this)}>
                    Tengo un código
                  </a>
                </div>
              </>
            )}

            <label style={{ color: 'red' }}>{saber}</label>
          </form>
          <p className="forgot-password text-right">
            <button
              type="button"
              class="btn btn-secondary"
              onClick={handleSalir.bind(this)}
              style={{ fontSize: '15px' }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                class="bi bi-box-arrow-right"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"
                ></path>
                <path
                  fill-rule="evenodd"
                  d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
                ></path>
              </svg>{' '}
              Salir
            </button>
          </p>
        </div>
      </div>
    </LoadingOverlay>
  );
};
export default Password;
