import React from "react";
import { useState } from "react";
import { setRefresh, setToken } from "../auth-helpers";

const usermanager = require('../ServerFunctions/usermanager');
const Confirm=()=> {
    const [passworda,setPassworda]=useState("");
    const [passwordb,setPasswordb]=useState("");
    const [passwordc,setPasswordc]=useState("");
    const [saber,setSaber]=useState("");
    const [disabled,setDisabled]=useState(false);

 const onSubmit = event=>{
    event.preventDefault();
    setDisabled(true)
    setSaber("Espere mientras actualizamos su contraseña.")
    if(passwordb!==passwordc){
        setSaber("Las contraseñas no coinciden.");
        setDisabled(false)
      }else if(passwordb.length<8){
        setSaber("La contraseña debe tener 8 o más caracteres");
        setDisabled(false)
      }else if(passworda===""||passwordb===""||passwordc===""){
        setSaber("Debe ingresar una nueva contraseña");
        setDisabled(false)
      }else{
        var access=window.localStorage.getItem('changepassword').split('/');
       console.log(access)
        var user={
            email:access[0],
            userName:access[0],
            companyName:access[1],
            password:passworda,
            newPassword:passwordb,
        } 
        usermanager.Login(user, function(users) {

            if(users.code==='InvalidPasswordException'){
                setSaber(users.message);
                setDisabled(false)
            }else{
                if(users.message==='Logued'){
                    window.localStorage.removeItem('changepassword');
                    setToken(users.token);
                    setRefresh(users.refresh)
                    window.location.href='/'
                }else{
                    setDisabled(false)
                    setSaber(users.message)
                }
            }
        });
    }
 };

 const volver = event=>{
    window.localStorage.clear();
    window.location.href="/"
 }
 
 return (
  <div className="auth-wrapper">
    <div className="auth-inner">
      <form onSubmit={onSubmit.bind(this)}>
        <h3>Cambie su contraseña antes de continuar.</h3>
          <div className="form-group">
            <label>Contraseña actual</label>
            <div className="input-group input-group-lg ">
              <span class="input-group-text txtPsw" id="basic-addon1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-file-lock2-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M7 6a1 1 0 0 1 2 0v1H7V6z" />
                  <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm-2 6v1.076c.54.166 1 .597 1 1.224v2.4c0 .816-.781 1.3-1.5 1.3h-3c-.719 0-1.5-.484-1.5-1.3V8.3c0-.627.46-1.058 1-1.224V6a2 2 0 1 1 4 0z" />
                </svg>
              </span>
              <input
                type="password"
                className="form-control txtpassw"
                placeholder="Contraseña Actual"
                disabled={(disabled)? "disabled" : ""}
                autocomplete="new-password"
                value={passworda}
                onChange={(e)=>setPassworda(e.target.value)} />
            </div>
          </div>
          <div className="form-group">
            <label>Nueva contraseña</label>
            <div className="input-group input-group-lg ">
              <span class="input-group-text txtPsw" id="basic-addon1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-file-lock2-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M7 6a1 1 0 0 1 2 0v1H7V6z" />
                  <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm-2 6v1.076c.54.166 1 .597 1 1.224v2.4c0 .816-.781 1.3-1.5 1.3h-3c-.719 0-1.5-.484-1.5-1.3V8.3c0-.627.46-1.058 1-1.224V6a2 2 0 1 1 4 0z" />
                </svg>
              </span>
              <input
                type="password"
                className="form-control txtpassw"
                placeholder="Nueva Contraseña"
                disabled={(disabled)? "disabled" : ""}
                value={passwordb}
                autocomplete="new-password"
                onChange={(e)=>setPasswordb(e.target.value)} />
            </div>
          </div>
          <div className="form-group">
            <label>Repita la nueva contraseña</label>
            <div className="input-group input-group-lg ">
              <span class="input-group-text txtPsw" id="basic-addon1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-file-lock2-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M7 6a1 1 0 0 1 2 0v1H7V6z" />
                  <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm-2 6v1.076c.54.166 1 .597 1 1.224v2.4c0 .816-.781 1.3-1.5 1.3h-3c-.719 0-1.5-.484-1.5-1.3V8.3c0-.627.46-1.058 1-1.224V6a2 2 0 1 1 4 0z" />
                </svg>
              </span>
              <input
                type="password"
                className="form-control txtpassw"
                placeholder="Nueva Contraseña"
                disabled={(disabled)? "disabled" : ""}
                value={passwordc}
                autocomplete="new-password"
                onChange={(e)=>setPasswordc(e.target.value)} />
            </div>
          </div>
          <button 
            onClick={onSubmit.bind(this)} 
            disabled={(disabled)? "disabled" : ""}
            className="paddingbutton btn btn-primary btn-block">Actualizar</button>
          <button 
            onClick={volver.bind(this)} 
            disabled={(disabled)? "disabled" : ""}
            className="btn btn-secondary ">Volver</button>
          <div>
            <label>{saber}</label>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Confirm;