//Configure Environment
import axios from 'axios';
const { v4: uuidv4 } = require('uuid');

//desarrollo
//const url_contactos="http://localhost:/"

//desarrollo
//const url_contactos="https://xkug7bbvq5.execute-api.us-east-2.amazonaws.com/dev/"

//producción
const url_contactos="https://zt0gcku369.execute-api.us-east-2.amazonaws.com/prod/";

export function ObtenerContactos(token,appid,callback){
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.get( 
        url_contactos+`table/`+appid,
        config
    ).then(res=>{
         callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}

export function ObtenerContactosAgente(token,appid,callback){
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.get( 
        url_contactos+`agente/`+appid,
        config
    ).then(res=>{
         callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}

export function ObtenerContactosFecha(token,end,start,lastKey,callback){
    const config = {
        headers: { Authorization: `${token}` }
    };
    var body={
        "fechainicio":start,
        "fechafin":end
    }
    
    if(lastKey!==undefined){
        console.log(lastKey)
        body.LastEvaluatedKey=lastKey
    }
    axios.post( 
        url_contactos+`table/date`,
        body,
        config
    ).then(res=>{
        console.log(res.data)
         callback(res.data)
    }).catch(err=>{
        console.log(err);
        callback(null)
    });
}

export function ObtenerContactosFechaAgente(token,end,start,lastKey,callback){
    const config = {
        headers: { Authorization: `${token}` }
    };
    var body={
        "fechainicio":start,
        "fechafin":end
    }
    if(lastKey!==undefined){
        console.log(lastKey)
        body.LastEvaluatedKey=lastKey
    }
    axios.post( 
        url_contactos+`agente/date`,
        body,
        config
    ).then(res=>{
        console.log("AQUIIIIIIIIIIIIIIIIIIIIIII")
        console.log(res.data)
         callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}

export function ObtenerContactosTemplate(token,templateid,lastkey,callback){
    const config = {
        headers: { Authorization: `${token}` }
    };
    var body={
     }
    if(lastkey!==undefined){
        body.LastEvaluatedKey=lastkey
    }
    axios.post( 
        url_contactos+`table/template/`+templateid,
        body,
        config
    ).then(res=>{
        callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}

export function ObtenerContactosTemplateAgente(token,templateid,lastkey,callback){
    const config = {
        headers: { Authorization: `${token}` }
    };
    var body={
    }
   if(lastkey!==undefined){
       body.LastEvaluatedKey=lastkey
   }
    axios.post( 
        url_contactos+`agente/template/`+templateid,
        body,
        config
    ).then(res=>{
        console.log(res.data)
         callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}

export function ObtenerContactosAdministrador(token,appid,tenantid,lastkey,callback){
    const config = { 
        headers: { Authorization: `${token}` }
    };
    axios.get( 
        url_contactos+`sys/table/`+appid+'/'+tenantid,
        config
    ).then(res=>{
         callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}

export function ObtenerContactosAgenteAdministrador(token,appid,tenantid,callback){
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.get( 
        url_contactos+`sys/agente/`+appid+'/'+tenantid,
        config
    ).then(res=>{
         callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}

export function ObtenerContactosFechaAdministrador(token,end,start,tenant,lastkey,callback){
    const config = {
        headers: { Authorization: `${token}` }
    };
    var body={
        "fechainicio":start,
        "fechafin":end
    }
    axios.post( 
        url_contactos+`table/sys/date/`+tenant,
        body,
        config
    ).then(res=>{
         callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}

export function ObtenerContactosFechaAgenteAdministrador(token,end,start,tenant,callback){
    const config = {
        headers: { Authorization: `${token}` }
    };
    var body={
        "fechainicio":start,
        "fechafin":end
    }
    axios.post( 
        url_contactos+`agente/sys/date/`+tenant,
        body,
        config
    ).then(res=>{
         callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}


