import React, { Component } from "react";
import { getToken, getUser } from "../auth-helpers";
import logo from "../Documentos/icono.png"

export default class Home extends Component {

    constructor(props){
        super(props);
        this.state={
            first:"",
            last:"",
            email:"",
            rol:"",
            tenant:""
        }
    }

    componentDidMount(){
        var token=getToken()
        var user=getUser(token)
        this.setState({
            first:user.given_name,
            last:user.family_name,
            email:user.email,
            rol:user["custom:role"],
            tenant:user["custom:companyName"]
        })
    }

    render() {
        return (
           <>
           <div className="auth-wrapper ">
                <div className="auth-inner" style={{ textAlign:"center" }}>
                    <h3>
                        Bienvenido a {this.state.tenant}
                    </h3>
                    <img
                        alt=""
                        src={logo}
                        width="60"
                        height="60"
                        className="d-inline-block align-top"
                        />
                        <br/>
                        <br/>
                    <h4>
                        {this.state.first+" "+this.state.last}
                    </h4>
                   
                    <h4>
                    {this.state.rol==="SystemAdmin"?("Administrador"):(<>
                        {this.state.rol==="TenantAdmin"?("Administrador"):("Usuario")}
                        </>)}
                    </h4>
                    <h5>
                    {this.state.email}
                    </h5>
                </div> 
           </div>
           </>
        );
    }
}