import React, { Component } from "react";
import { getTenant, getToken } from "../../../auth-helpers";
const conectormanagergenesys = require('../../../ServerFunctions/conectormanagergenesys.js');
export default class TenantConectorAdd extends Component {
constructor(props){
    super(props);
    this.state={
        deployment_id:'',
        tipo:'web_messaging',
        nombre:'',
        disabled:false,
        client:'',
        secret:''
    }
    this.handleCreate=this.handleCreate.bind(this);
}

handleCreate(event){
    event.preventDefault();
    this.setState({
        disabled:true,
        mensaje:'Espere mientras se crea el Conector'
    })

    if(this.state.tipo==="web_messaging"){
        if(this.state.deployment_id=="" || this.state.tipo=="" ||
        this.state.nombre=="" ){
            this.setState({
                mensaje:'Campos vacios',
                disabled:false
            })
            return false
        } 
    }else{
        if(this.state.deployment_id=="" || this.state.tipo=="" ||
        this.state.nombre=="" || this.state.client=="" || this.state.secret==""){
            this.setState({
                mensaje:'Campos vacios',
                disabled:false
            })
            return false
        }
    }
    var conector={
        deployment_id:this.state.deployment_id,
        nombre:this.state.nombre,
        tipo:this.state.tipo,
        client_id:this.state.client,
        secret_client:this.state.secret
    }
    
    var authToken = getToken();
    var thisa= this;
    var tenant=getTenant();
    conectormanagergenesys.CrearGenesysConectorAdministrador(authToken,conector,tenant.TenantId,function(conectors) {
        if(conectors){
            thisa.setState({
                mensaje:"Conector creado correctamente"
            })
            setTimeout(function(){
                window.location.href="/conectorgenesys-list"
            }, 1000);

        }else{
            thisa.setState({
                disabled:false,
                mensaje:"No se pudo crear el conector. Intentelo más tarde"
            })
            
            setTimeout(function(){
                window.location.href="/conectorgenesys-list"
            }, 2000);
        }
    });
}

    render() {
        var providers=this.state.providers
        return (
            <>
            <div>
            <br/>
                    <div className="template-inner" style={{maxWidth:"650px"}}>
            <h3>Agregar Conector de Genesys</h3>
                <br/>
                <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Nombre del conector</label>
                    <div className="col-sm-9">
                        <input 
                            type="text" 
                            disabled={(this.state.disabled)? "disabled" : "" }
                            className="form-control" 
                            placeholder="Nombre del conector" 
                            value={this.state.nombre}
                            onChange={(e)=>this.setState({
                            nombre: e.target.value
                        })}
                        />
                    </div>
                </div>
                        <div className="form-group row">
                            <label  className="col-sm-3 col-form-label">Deployment ID</label>
                            <div className="col-sm-9">
                            <input  
                                type="text" 
                                disabled={(this.state.disabled)? "disabled" : "" }
                                className="form-control" 
                                placeholder="Deployment ID" 
                                value={this.state.deployment_id}
                                onChange={(e)=>this.setState({
                                deployment_id: e.target.value
                                })}/>
                                </div>
                        </div>
                        <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Tipo</label>
                       <div className="col-sm-9">
                            <select 
                            className="form-select"
                            disabled={(this.state.disabled)? "disabled" : "" }
                            value={this.state.tipo}
                            onChange={
                                (e)=>{
                                    this.setState({
                                        tipo: e.target.value
                            })}}
                            >
                            <option value='web_messaging' >Web Messaging</option>
                            <option value='open_messaging' >Open Messaging</option>
                        </select>
                            </div>
                        </div>
                       {
                           this.state.tipo=='open_messaging'?(<>
                            <div className="form-group">
                    <label>Identificador del cliente de Genesys</label>
                    <input 
                        disabled={(this.state.disabled)? "disabled" : ""}
                        type="text" 
                            className="form-control" 
                            placeholder="Genesys Client" 
                            value={this.state.client}
                            onChange={(e)=>this.setState({
                                client: e.target.value
                                })}
                            />
                        </div>
                        <div className="form-group">
                            <label>Secreto del cliente de Genesys</label>
                            <input 
                                disabled={(this.state.disabled)? "disabled" : ""}
                                type="password" 
                                    className="form-control" 
                                    placeholder="Genesys Client" 
                                    value={this.state.secret}
                                    onChange={(e)=>this.setState({
                                        secret: e.target.value
                                        })}
                            />
                        </div>
                           </>):(<></>)
                       }
                        <button className="paddingbutton btn btn-block btn-outline-primary" 
                        onClick={this.handleCreate}
                        disabled={(this.state.disabled)? "disabled" : "" }> Crear</button>
                        <a href="/conectorgenesys-list" className="paddingbutton btn btn-outline-info"  role="button" aria-disabled="true">Volver</a>  
                        <div>
                            <label>{this.state.mensaje}</label>
                        </div>
                    </div>
                    <br/>
                </div>
                </>
        );
    }
}