import React, { Component } from "react";
import { getToken } from "../../auth-helpers";
import jwtDecode from "jwt-decode";
const usermanager = require('../../ServerFunctions/usermanager');
export default class UserAdd extends Component {
constructor(props){
    super(props);
    this.state={
        first:"",
        last:"",
        rol:"",
        mensaje:"",
        disabled:false,
        role:''
    }
    this.handleCreate=this.handleCreate.bind(this);
    
}

componentDidMount(){
    var authToken = getToken();
    var usr=jwtDecode(authToken);
    this.setState({
        role:usr['custom:role']
    })
    if(this.state.role==="SystemAdmin"){
        this.setState({
            rol:"SystemAdmin"
        })
    }else{
        this.setState({
            rol:"TenantUser"
        })
    }
}

handleCreate(event){
    event.preventDefault();
    this.setState({
        disabled:true,
        mensaje:"Espere mientras creamos el usuario"
    })
    if(this.state.first===""||this.state.last===""||this.state.email===""){
        this.setState({
            mensaje:"Campos vacios",
            disabled:false
        })
        return;
    }
    
    var newrole=""
    if(this.state.role==="SystemAdmin"){
        newrole="SystemAdmin"
    }else{
        newrole=this.state.rol
    }
    var user={
      firstName:this.state.first,
      lastName: this.state.last,
      userName: this.state.email,
      role: newrole,
    }
    var authToken = getToken();
    var thisa=this;
    console.log(user)
    usermanager.CrearUser(authToken,user, function(users) {
        if(users){
                thisa.setState({
                    mensaje:'Usuario creado correctamente'
                })
                setTimeout(function(){
                    window.location.href="/user-list"
                }, 1000);
        }else{
            thisa.setState({
                disabled:false,
                mensaje:"No se pudo agregar el usuario. Intentelo más tarde"
            })
        }
    });
}

    render() {
        return (
            <div className="auth-wrapper">
              <div className="auth-inner">
                <h3>Agregar Usuario</h3>

                <div className="form-group">
                <label class="form-label">Nombre</label>
                <div className="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">@</span>
                        </div>
                        <input  
                            disabled={(this.state.disabled)? "disabled" : ""}
                            type="text" 
                            className="form-control" 
                            placeholder="Nombre" 
                            value={this.state.first}
                            onChange={(e)=>this.setState({
                            first: e.target.value
                        })}/>
                        </div>
                </div>

                <div className="form-group">
                <label class="form-label">Apellido</label>
                <div className="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">@</span>
                        </div>
                        <input 
                            disabled={(this.state.disabled)? "disabled" : ""}
                            type="text" 
                            className="form-control" 
                            placeholder="Apellido" 
                            value={this.state.last}
                            onChange={(e)=>this.setState({
                            last: e.target.value
                            })}
                        />
                    </div>
                    
                </div>

                <div className="form-group">
                <label className="form-label">Correo</label>
                <div className="input-group mb-3">
                <input 
                    disabled={(this.state.disabled)? "disabled" : ""}
                    type="email" 
                    className="form-control" 
                    placeholder="Correo electrónico" 
                    value={this.state.email}
                    onChange={(e)=>this.setState({
                        email: e.target.value
                        })}
                    />
                    <div className="input-group-append">
                    <span className="input-group-text" id="basic-addon2">@example.com</span>
                </div>
                    </div>
                    
                </div>

                <div className="form-group">
                    <label className="form-label">Rol</label>
                    <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <label className="input-group-text">Opciones</label>
                    </div>
                    <select 
                    disabled={(this.state.disabled)? "disabled" : ""}
                    className="form-control" 
                    value={this.state.rol}
                    onChange={(e)=>this.setState({
                        rol:e.target.value
                    })}>
                    {
                        this.state.role==="SystemAdmin"?(<>
                           <option value='SystemAdmin'>Administrador del sistema</option>
                        </>):(<>
                            <option value='TenantAdmin'>Administrador</option>
                            <option value='TenantUser'>Usuario</option>
                        </>)
                    }
                    </select>
                    </div>
                    
                </div>
                <button type="submit" id="createuser" className="paddingbutton btn btn-block btn-outline-primary" onClick={this.handleCreate}
                    disabled={(this.state.disabled)? "disabled" : ""}
                >Crear</button>
                <a href="/user-list" className="paddingbutton btn btn-outline-info"  role="button" aria-disabled="true">Volver</a>  
                <div>
                <label>{this.state.mensaje}</label>
                </div>
            </div>
            </div>
        );
    }
}