//Configure Environment
import axios from 'axios';
//local
//const url_conectorgenesys="http://localhost:3045/"
//desarrollo
//const url_conectorgenesys="https://zlv2ewfv1c.execute-api.us-east-2.amazonaws.com/dev/"

//producción
const url_conectorgenesys="https://toeshguwo8.execute-api.us-east-2.amazonaws.com/prod/"

export function CrearGenesysConectorAdministrador(token,conector,tenantid,callback) {
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.post( 
        url_conectorgenesys+`conector/genesys/`+tenantid,
      conector,
      config
    ).then(res=>{
        callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}

export function EditarGenesysConectorAdministrador(token,conector,tenantid,callback) {
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.put( 
        url_conectorgenesys+`conector/genesys/`+tenantid,
      conector,
      config
    ).then(res=>{
        callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}

export function EliminarGenesysConectorAdministrador(conector_id,tenantid,token,callback) {
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.get(
        url_conectorgenesys+'buscarconexion/'+tenantid+'/'+conector_id,
        config    
    ).then(res=>{
        console.log(res)
        if(res.data.length!==0){
            res.data.mensaje='Este conector esta en uso. No se puede eliminar'
            callback(res.data)
        }else{
            axios.delete( 
                url_conectorgenesys+'conector/genesys/'+tenantid+'/'+conector_id,
                config
            ).then(resp=>{
                    resp.data.mensaje='Conector eliminado exitosamente'
                  callback(resp.data)
              }).catch(err=>{
                 callback(null)
              });
        }
    }).catch(err=>{
        callback(null)
    });

}

export function ListGenesysConectorAdministrador(token,metodo,tenantid,callback){
    const config = {
        headers: { Authorization: `${token}` }
    };
    var cuerpo={
        metodo:metodo
    }
    axios.post( 
        url_conectorgenesys+`conectores/genesys/`+tenantid,
        cuerpo,
      config
    ).then(res=>{
        console.log(res.data)
         callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}

export function ObtenerGenesysConectorAdministrador(token,tenant,id,callback){
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.get( 
        url_conectorgenesys+`genesys/conector/`+tenant+'/'+id,
      config
    ).then(res=>{
         callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}

export function ConexionConectoresAdministrador(token,conector_id,conector_genesys_id,tenantId,callback) {
    const config = {
        headers: { Authorization: `${token}` }
    };
    var conector={
        conector_id:conector_id,
        conector_genesys_id:conector_genesys_id
    }
    axios.put( 
        url_conectorgenesys+`conectores/servicios/`+tenantId,
      conector,
      config
    ).then(res=>{
        callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}

export function EliminarConexionConectoresAdministrador(token,conector_id,conector_genesys_id,tenantId,callback) {
    const config = {
        headers: { Authorization: `${token}` }
    };
    var conector={
        conector_id:conector_id,
        conector_genesys_id:conector_genesys_id
    }
    axios.put( 
        url_conectorgenesys+`eliminar/conexiones/`+tenantId,
      conector,
      config
    ).then(res=>{
        callback(res.data)
    }).catch(err=>{
        callback(null)
    });
    
}

