import React, { Component } from "react";
import { getTenant, getToken } from "../../../../auth-helpers";
import Papa from 'papaparse';// Encargado de parsear
import { Image } from 'react-bootstrap';
import fondoimage from '../../../../fondo.jpg';
const $ = require('jquery');
const templatemanager = require('../../../../ServerFunctions/templatemanager.js');
const conectormanager = require('../../../../ServerFunctions/conectormanager.js');
const campañamanager = require('../../../../ServerFunctions/campaignmanager');

export default class TenantSegmentAdd extends Component {
constructor(props){
    super(props);
    this.state={
        filas:[],
        filt:[],
        cabeceras:[],
        buttons:[],
        buttonsac:[],
        name:"",
        picture: fondoimage,
        templates:[],
        border:"10px 10px 10px 10px",
        mensaje:"", 
        msgdos:"",
        checkedId:"",
        template:null,
        conector:null,
        file:null,
        appId:props.location.state.applicationid,
        n_conector:'',
        chsm_conector:'',
        num_conector:'',
        codwhats:""
    }
    this.Cargar=this.Cargar.bind(this);
    this.updateData= this.updateData.bind(this);
    this.LoadTemplate= this.LoadTemplate.bind(this);
    this.handleCreate= this.handleCreate.bind(this);
    this.exportCSVFile= this.exportCSVFile.bind(this)
    this.convertToCSV = this.convertToCSV.bind(this)
}

componentDidMount(){
    var authToken = getToken();
    var thisa= this;
    var tenant=getTenant();
    templatemanager.ListTemplateAdministrador(authToken,tenant.TenantId,function(templates) {
        thisa.setState({
            templates,
            disbled:false
        })
        $('#templatesPC').DataTable();
    });
}

LoadTemplate(template){
    this.setState({
        mensaje:"Cargando conector"
    })
    if(template.buttons.buttons_type==="callaction"){
        this.setState({
            border:"10px 10px 0px 0px",
        })
    }else{
        this.setState({
            border:"10px 10px 10px 10px",
        })
    }

    
    this.setState({
        buttons:template.buttons.buttons_quick,
        buttonsac:template.buttons.buttons_action
    })
    var authToken = getToken();
    var thisa= this;
    this.setState({
        disabled:true
    })
    conectormanager.ObtenerConectorAdministrador(authToken,template.conector.conector_id,template.tenant_id,function(conector){
        if(conector){
            thisa.setState({
                n_conector:conector.nombre,
                chsm_conector:conector.costo_hsm,
                num_conector:conector.num_whatsapp,
                cod_conector:conector.cod_whatsapp,
                conector:conector,
                conector_id:conector.conector_id,
                disabled:false,
                mensaje:""
            })
        }
    })
    
}

Cargar(csvfile){
    this.setState({
        filas:[],
        cabeceras:[]
    })
    Papa.parse(csvfile, {
        complete: this.updateData,
        header: true
      });
}



updateData(result) {
    var rdata = result.data;
    var data=[]
    var error="false";
    var existe=false;
    var existep=false;
    for(let i=0;i<rdata.length;i++){
        if(rdata[i].id.trim()!=="" && rdata[i].telefono.trim()!==""){
            data.push(rdata[i])
        }
    }
    this.setState({
        numfila:data.length,
        filt:data,
        filas:data.slice(0,10)
    })
    var cabeceras=[];
    for(var key in data[0]){
        cabeceras=cabeceras.concat(key);
        if(key===""){
            error="Existe una columna sin nombre en el archivo cargado"
        }else{
            if(key==="id"){
                existe=true;
            }
            if(key==="telefono"){
                existep=true;
            }
        }
    }

    if(!existe){
        error="No existe la columna id en el archivo cargado"
    }
    if(!existep){
        error="No existe la columna telefono en el archivo cargado"
    }
    if(error==="false"){
        this.setState({
            cabeceras:cabeceras,
            msgdos:""
        })    
    }else{
        this.setState({
            msgdos:error,
            file:null
           })
    }
  }

  handleCreate(event){
    event.preventDefault();
    this.setState({
        disabled:true,
        mensaje:''
    })
    if(this.state.conector==null||this.state.template==null || this.state.name=="" || this.state.file==null){
        this.setState({
            mensaje:'Parámetros incompletos. Seleccione una plantilla y carge un archivo de lista de contactos',
            disabled:false
        })
        return false;
    }
    if(parseInt(this.state.numfila)>1000){
        this.setState({
            mensaje:'La lista de contacto puede tener máximo 1000 registros',
            disabled:false
        })
        return false;
    }
    this.setState({
        disabled:true,
        mensaje:'Espere mientras cargamos la lista de contactos'
    })
   
    const cabeceras=this.state.cabeceras;
    
    var headers={
        id:'Id',
        telefono:'User.UserAttributes.phone',
        Address:'Address',
        ChannelType:'ChannelType',
        tenant:'Attributes.tenant',
        endpoint:'Attributes.EndpointId',
        templateid:'Attributes.templateid',
        templatename:'Attributes.templatename',
        templatenamespace:'Attributes.templatenamespace',
        templatelang:'Attributes.templatelanguage',
        templateheadermedia:'Attributes.templateheadermedia',
        templateheadertype:'Attributes.templateheadertype',
        templateheadertext:'Attributes.templateheadertext',
        templateparone:'Attributes.templateparone',
        templatepartwo:'Attributes.templatepartwo',
        templateparthree:'Attributes.templateparthree',
        templateparfour:'Attributes.templateparfour',
        templateparfive:'Attributes.templateparfive',
        templateparsix:'Attributes.templateparsix',
        templateparseven:'Attributes.templateparseven',
        templatepareight:'Attributes.templatepareight',
        templateparnine:'Attributes.templateparnine',
        templateparten:'Attributes.templateparten',
        templateparcount:'Attributes.templateparcount',
        conectorid:'Attributes.connectorid',
        conectorname:'Attributes.connectorname',
        conectorappid:'Attributes.connectorappid',
        conectorprivatekey:'Attributes.connectorprivatekey',
        conectornum:'Attributes.connectornum',
        conectorurl:'Attributes.connectorurl',
        cedula:'Attributes.cedula',
        proveedor:'Attributes.proveedor',
        btnquiuno:'Attributes.buttonone',
        btnquidos:'Attributes.buttontwo',
        btnquitres:'Attributes.buttonthree'

    };
    var cont=0;
    var datos2=[]
    var template=this.state.template;  
    var parameters=template.body.parameters
    var headerbool=false;
    for(var key in cabeceras){
        if(cabeceras[key]!=="id" && cabeceras[key]!=="telefono"){
            var name=cabeceras[key];
            for(let par=0;par< parameters.length ; par++){
            if(name==parameters[par].description){
                    var value='User.UserAttributes.var'+parameters[par].index
                    headers[name]=value;
                    datos2.push(parameters[par].index)
                    cont=cont+1;
    
                }
            }
            if(name=="cabecera"){
                var value='User.UserAttributes.var11'
                headers[name]=value;
                datos2.push(11)
                headerbool=true;
            }
        }
    }
    
    if(template.header.header_type!="none"){
        if(template.header.header_type!="text"){
            if(!headerbool){
                this.setState({
                    mensaje:"Según la plantilla elegida, se requiere una columna cabecera con la url del documento o imagen",
                    disabled:false
                }) 
                return;
            }
        }
    }
    
   
    if(cont!=parameters.length){
        this.setState({
            mensaje:"Según la plantilla seleccionada, el número de parámetros es incorrecto. Compruebe los nombres de los parámetros de la plantilla y las columnas.",
            disabled:false
        })    
    }else{
        if(cont<11){
            var datos1=[1,2,3,4,5,6,7,8,9,10]
            if(template.header.header_type=="none" || template.header.header_type=="text"){
                datos1=[1,2,3,4,5,6,7,8,9,10,11]
            }

            var result = datos1.filter(el => !datos2.includes(el));
            for(let i=0;i<result.length;i++){
                var cab="var"+result[i];
                var value='User.UserAttributes.var'+result[i]
                headers[cab]=value;
            }
        }
        var array=this.state.filt;
       console.log(array)
        for (let i = 0; i < array.length; i++) {
            array[i]['cedula']=array[i].id;
            array[i]['Id']=i+1;
            array[i]['Address']=i+1;
            array[i]['ChannelType']="CUSTOM";
            array[i]['tenant']=this.state.template.tenant_id;
            array[i]['endpoint']=i+1;
            array[i]['templateid']=this.state.template.template_id;
            array[i]['templatename']=this.state.template.template;
            array[i]['templatenamespace']=this.state.template.conector.namespace;
            array[i]['templatelang']=this.state.template.msg_language;
            array[i]['templateheadertype']=this.state.template.header.header_type;
            array[i]['templateheadermedia']=this.state.template.header.header_media;
            array[i]['templateheadertext']=this.state.template.header.header_text;
            if(this.state.template.body.parameters[0]!=undefined){
                array[i]['templateparone']=this.state.template.body.parameters[0].description;
            }else{
                array[i]['templateparone']="";
            }
            if(this.state.template.body.parameters[1]!=undefined){
                array[i]['templatepartwo']=this.state.template.body.parameters[1].description;
            }else{
                array[i]['templatepartwo']="";
            }
            if(this.state.template.body.parameters[2]!=undefined){
                array[i]['templateparthree']=this.state.template.body.parameters[2].description
            }else{
                array[i]['templateparthree']=""
            }
            if(this.state.template.body.parameters[3]!=undefined){
                array[i]['templateparfour']=this.state.template.body.parameters[3].description
            }else{
                array[i]['templateparfour']=""
            }
            if(this.state.template.body.parameters[4]!=undefined){
                    array[i]['templateparfive']=this.state.template.body.parameters[4].description
            }else{
                array[i]['templateparfive']="" 
            }
            if(this.state.template.body.parameters[5]!=undefined){
                array[i]['templateparsix']=this.state.template.body.parameters[5].description
            }else{
                array[i]['templateparsix']="" 
            }
            if(this.state.template.body.parameters[6]!=undefined){
                array[i]['templateparseven']=this.state.template.body.parameters[6].description
            }else{
                array[i]['templateparseven']=""
            }
            if(this.state.template.body.parameters[7]!=undefined){
                array[i]['templatepareight']=this.state.template.body.parameters[7].description
            }else{
                array[i]['templatepareight']=""
            }
            if(this.state.template.body.parameters[8]!=undefined){
                array[i]['templateparnine']=this.state.template.body.parameters[8].description
            }else{
                array[i]['templateparnine']=""           
            }
            if(this.state.template.body.parameters[9]!=undefined){
                array[i]['templateparten']=this.state.template.body.parameters[9].description
            }else{
                array[i]['templateparten']=""
            }
            array[i]['templateparcount']=this.state.template.body.parameters.length;
            array[i]['conectorid']=this.state.conector.conector_id;
            array[i]['conectorname']=this.state.conector.nombre;
            array[i]['conectorappid']=this.state.conector.aplication_id;
            array[i]['conectorprivatekey']=this.state.conector.private_key;
            array[i]['conectornum']=this.state.conector.cod_whatsapp+this.state.conector.num_whatsapp.substr(1);
            array[i]['conectorurl']=this.state.conector.message_url;
            array[i]['proveedor']=this.state.conector.provider_name;
            array[i]['telefono']=this.state.conector.cod_whatsapp+array[i]['telefono']
            if(this.state.template.buttons.buttons_quick[0]!=undefined){
                array[i]['btnquiuno']=this.state.template.buttons.buttons_quick[0].description+"/"+this.state.template.buttons.buttons_quick[0].token
            }else{
                array[i]['btnquiuno']=""
            }
            if(this.state.template.buttons.buttons_quick[1]!=undefined){
                array[i]['btnquidos']=this.state.template.buttons.buttons_quick[1].description+"/"+this.state.template.buttons.buttons_quick[1].token
            }else{
                array[i]['btnquidos']=""
            }
            if(this.state.template.buttons.buttons_quick[2]!=undefined){
                array[i]['btnquitres']=this.state.template.buttons.buttons_quick[2].description+"/"+this.state.template.buttons.buttons_quick[2].token
            }else{
                array[i]['btnquitres']=""
            }
        }
        this.exportCSVFile(headers, array.slice(0,array.length),this.state.name);
    }
  }

  exportCSVFile(headers, items, fileName) {
    if (headers) {
     items.unshift(headers);
    }
    const jsonObject = JSON.stringify(items);
    const csv = this.convertToCSV(jsonObject,headers);
    const exportName = fileName + ".csv" || "export.csv";
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, exportName);
    } else {
        var authToken = getToken();
        const f = new FormData();
        f.append('file',blob);
        var thisa=this
    
        campañamanager.InsertarSegmentoAdministrador(authToken,f,this.state.template.template_id,this.state.conector_id,this.state.name,this.state.template.tenant_id,parseInt(this.state.numfila),this.state.template.body_text,this.state.cod_conector,function(segment){
            if(segment){
                setTimeout(function(){
                    thisa.setState({
                        mensaje:"Lista de Contactos creada correctamente"
                    })
                }, 1000);
                setTimeout(function(){
                   window.location.href="/segment-list"
                }, 2500);
            }else{
                thisa.setState({
                    disabled:false,
                    mensaje:"Error al crear la lista de contactos. Intentelo más tarde."
                })
            }

        })
     }
  }

    convertToCSV(objArray,headers) {
        const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
        let str = "";
        for (let i = 0; i < array.length; i++) {
            let line = "";
            for (let index in headers) {
                if (line != ""){
                    line += ",";
                }
                var text =array[i][index];
                if(text===undefined){
                    text="-"
                }else if(text===""){
                    text="-" 
                }           
                line += text;
            }
            str += line + "\r\n";
        }
        return str;
    }



   render() {
    var btns=this.state.buttons;
    var btnsac=this.state.buttonsac;
    const filascsv= this.state.filas;
    const cabeceras= this.state.cabeceras;
    const templates= this.state.templates;
    var hora = new Date();
    var show=hora.getHours()+':'+hora.getMinutes()
    const CheckBox = ({template}) => (
        <input
          id={template.template_id}
          onChange={() => {
            this.setState({
                checkedId:template.template_id,
                template:template
            })
            this.LoadTemplate(template);
          }}
          checked={this.state.checkedId === template.template_id}
          type="checkbox"
        />
      )
    return (
        <div style={{textAlign:"start"}}>
            <br/>
            <form >
            <div className="template-inner">
                    <h2>Cargar Lista de Contactos (Archivo CSV) </h2>
                    <br/>
                <div className="row">
                    <div className="col-sm-4">
                        <label>Nombre de Lista de Contactos</label>
                        <input type="text" className="form-control" 
                        disabled={(this.state.disabled)? "disabled" : ""}
                        placeholder="Nombre del archivo" value={this.state.name}
                        minLength="6"
                        maxLength="20"
                        onChange={(e)=>{
                            if ( /[^A-Za-z\d]/.test(e.target.value)) {
                                this.setState({
                                    name: e.target.value.substring(0, e.target.value.length - 1),
                                    mensaje:"Ingrese solo letras y caracteres numéricos"
                                })
                            }else{
                                this.setState({
                                    name: e.target.value.toLocaleLowerCase(),
                                    mensaje:""
                                })
                            }
                        }
                         }
                        />
                    </div>
                    <div className="col-sm-8">
                        <label>Cargar Lista de Contactos</label>
                        <input type="file" className="form-control"
                        disabled={(this.state.disabled)? "disabled" : ""}
                            onChange={(e)=>{
                                if(e.target.files[0]){
                                    if(e.target.files[0].name.split(".")[1]!=="csv"){
                                        this.setState({
                                            file:null,
                                            mensaje:"Formato de archivo incorrecto",
                                            filas:[],
                                            msgdos:""
                                            })
                                    }else{
                                        this.setState({
                                            file: e.target.files[0],
                                            mensaje:"",
                                            msgdos:""
                                        })
                                        this.Cargar(e.target.files[0]);
                                    }
                                }
                            }}
                        />
                        <label>{this.state.msg}</label>
                    </div>
                </div>

                <button
                disabled={(this.state.disabled)? "disabled" : ""}
                id="createuser" className="paddingbutton btn btn-primary btn-block" onClick={this.handleCreate}>Crear</button>
                <a href="/segment-list" className="btn btn-secondary "  role="button" aria-disabled="true">Volver</a>  
                <div>
                <label>{this.state.mensaje}</label>
                </div>
            </div>
            <br/>
            <div className="template-inner table-responsive">
            <h2>PLANTILLAS</h2>
                    <div className="row" style={{alignContent:"center"}}>
                        <div  style={{maxHeight:"500px"}} className="table-responsive">
                           <table  id="templatesPC" className="display"    style={{ width: '100%', float: 'left' }}>
                                    <thead>
                                        <tr>
                                        <th></th>
                                        <th>Nombre</th>
                                        <th>País</th>
                                        <th>Validado</th>
                                        </tr>
                                    </thead>
                                <tbody>
                                    {
                                        templates?(
                                            <>
                                            { templates.map((item, i) => {
                                                    return (
                                                        <>{
                                                            item.verificacion==="false" || item.verificacion==="pendiente"?(
                                                                <></>
                                                            ):(
                                                                <>
                                                                    <tr key={item.template_id}>
                                                                    <td>
                                                                    <CheckBox template={item} />
                                                                    </td>
                                                                    <td>{item.template_name}</td>
                                                                    <td>{item.conector.codigo=="593"?("EC"):(
                                                                                <>
                                                                                {
                                                                                    item.conector.codigo=="51"?("PE"):("COL")
                                                                                }</>)}</td>
                                                                    <td>{item.verificacion}</td>
                                                                    </tr>
                                                                </>
                                                            )

                                                        }
                                                        </>
                                                       
                                                    );
                                            })}
                                            </>
                                        ):(
                                            <>
                                            </>
                                        )
                                    }
                                </tbody>
                                </table>
                        </div>
                        
                                {this.state.template?(
                                    <>
                                    <br/>
                                    <br/>
                                    <div className="row">
                                        <div className="col-sm-5" style={{paddingRight:"30px"}}>
                                            <h4>Información del conector</h4>
                                                <div className="form-group row">
                                                    <label className="col-sm-8 col-form-label">Nombre</label>
                                                    <p className="col-sm-4 col-form-label">{this.state.n_conector}</p>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-sm-8 col-form-label"># Número</label>
                                                    <p className="col-sm-4 col-form-label">{this.state.cod_conector}{this.state.num_conector.substring(1)}</p>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-sm-8 col-form-label">Costo por mensaje</label>
                                                    <p className="col-sm-4 col-form-label">{this.state.chsm_conector}</p>
                                                </div>
                                        </div>
                                        <div className="col-sm-7" >
                                            <br/>
                                            <div className="container" style={{backgroundImage:"url("+this.state.picture+")",minWidth:"250px", width: "auto", marginLeft: "20px", paddingBottom:"20px", maxWidth:"350px"}}>

                                            <h4 style={{paddingTop:"15px"}}>Visualización</h4>
                                            <div className="row" style={{marginBottom: "8px",width: "auto"}}>
                                                    <div className="col-sm" style={{paddingTop: "15px",paddingLeft: "15px", paddingRight: "30px"}}>
                                                        <div className="alert-secondary" 
                                                            style={{textAlign:"start" ,fontSize: "12px", paddingTop: "0px",paddingLeft: "10px",paddingRight: "10px", paddingBottom:"3px",borderRadius: this.state.border, backgroundColor:"white"}}>
                                                            {
                                                                this.state.template.header.header_type==='none'?(
                                                                    <></>
                                                                ):(
                                                                    <>
                                                                    {
                                                                        this.state.template.header.header_type==='text'?(
                                                                        <>
                                                                            <span>{this.state.template.header.header_text}</span><br />
                                                                        </>
                                                                        ):(
                                                                        <>
                                                                        {
                                                                            this.state.template.header.header_text!=''?(<>
                                                                            <Image src={this.state.template.header.header_text} style={{height:"auto", width:"100%"}}></Image> 
                                                                            <br/></>):(<></>)
                                                                        }
                                                                        </>
                                                                        )
                                                                    }
                                                                    </>
                                                                )
                                                            }
                                                            <div>
                                                            {this.state.template.body.body_text.split("\n").map((item,i)=>{
                                                                return(<>
                                                                    {item}
                                                                    <br/>
                                                                </>)
                                                            })} 
                                                            </div>
                                                            <div className="row" >
                                                                <div className="col-sm-8">
                                                                    <small className="text-muted">
                                                                    {this.state.template.body.footer}
                                                                    </small>
                                                                </div>
                                                                <div className="col-sm-4" style={{textAlign:"end", height:"auto"}}>
                                                                    <small className="text-muted">
                                                                    {show}
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                            
                                                        {
                                                                this.state.template.buttons.buttons_type=='none'?(
                                                                    <>
                                                                    </>
                                                                ):(
                                                                    <>
                                                                    {
                                                                        this.state.template.buttons.buttons_type=='callaction'?(
                                                                            <>
                                                                            {
                                                                                btnsac.length>0?(<>
                                                                                {
                                                                                 btnsac.map((item,i)=>{
                                                                                     
                                                                                     return (
                                                                                         <div key={i} className={"alert-secondary"} 
                                                                                             style={{textAlign:"center",borderRight:"inset",borderLeft:"outset",marginTop:"2px",fontSize: "12px",padding:"5px", color:"blue",borderRadius: "10px 10px 10px 10px", backgroundColor:"white"}}>
                                                                                             {
                                                                                                item.tipo=='call'?(
                                                                                                    <>
                                                                                                        <span style={{paddingRight:"5px"}} className="glyphicon glyphicon-earphone"></span>
                                                                                                        <label > {item.texto}</label>
                                                                                                    </>
                                                                                                ):(
                                                                                                    <>
                                                                                                    <label > {item.texto}</label>
                                                                                                    </>
                                                                                                )
                                                                                            }   
                                                                                         </div>
                                                                                     )})
                                                                             }
                                                                                </>):(<>
                                                                                   </>)
                                                                            }
                                                                           
                                                                            </>
                                                                            ):(
                                                                            <>
                                                                            <div className="row" style={{margin:"1px"}}>
                                                                            {
                                                                                btns.map((item,i)=>{
                                                                                        var estilo=" col-sm-12"
                                                                                        if(btns.length>1){
                                                                                        var estilo=" col-sm-6"
                                                                                        if(i==2){
                                                                                            estilo=" col-sm-12"
                                                                                        }
                                                                                    }
                                                                                    
                                                                                    return (
                                                                                        <div key={i} className={"alert-secondary"+ estilo} 
                                                                                            style={{textAlign:"center",borderRight:"inset",borderLeft:"outset",marginTop:"2px",fontSize: "12px",padding:"5px", color:"blue",borderRadius: "10px 10px 10px 10px", backgroundColor:"white"}}>
                                                                                            <label>{item.description}</label>
                                                                                        </div>
                                                                                    )})
                                                                            }
                                                                            <br/>

                                                                            </div>
                                                                            </>
                                                                        )
                                                                    }
                                                                </>
                                                                )
                                                            }

                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                ):(
                                    <></>
                                )}
                     </div>
            </div>
            <br/>
            {
                filascsv.length===0?(
                    <></>
                ):(
                    <> 
                      <div className="template-inner">
                        <h2>Lista de Contactos</h2>
                        
                        <div className="row">
                        <label>{this.state.msgdos}</label>
                        <div className="row">
                            {
                                this.state.msgdos===""?(
                                    <>
                                        <label>Número de registros: {parseInt(this.state.numfila)}</label>
                                    </>
                                ):(
                                    <></>
                                )
                            }
                        </div>
                        <div id="cognitousers" className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>{
                                            cabeceras?(
                                                <>
                                                    {
                                                        cabeceras.map((item,i)=>{
                                                            return(
                                                                <th key={item}>{item}</th>
                                                            )
                                                        })
                                                    }
                                                </>
                                            ):(
                                                <></>
                                            )
                                        }
                                    </tr>
                                </thead>
                            <tbody>
                                {
                                    filascsv?(
                                        <>
                                        {filascsv.map((item, a) => {
                                            return (
                                                <tr key={a}>
                                                    {
                                                        cabeceras?(
                                                            <>
                                                                {
                                                                    cabeceras.map((itema,i)=>{
                                                                        return(
                                                                            <th key={a+i}>{item[itema]}</th>
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                        ):(
                                                            <></>
                                                        )
                                                    }
                                                </tr>
                                            );
                                        })}        
                                    </>
                                    ):(
                                        <>
                                        </>
                                    )
                                }
                            </tbody>
                        </table>
                        </div>
                        </div>
                    </div>
                
                    </>
                )
            }
            </form>
        </div>
    );
}
}