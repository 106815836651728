import React, { Component } from "react";
import { getToken } from "../../../auth-helpers";
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';

import { Line, Pie, Doughnut, Bar, Radar } from 'react-chartjs-2';
const campañamanager = require('../../../ServerFunctions/campaignmanager');
const contactmanager = require('../../../ServerFunctions/contactmanager');

export default class CampaignEdit extends Component {
constructor(props){
    super(props);
    this.state={
        campaign:null, 
        contactos:[],
        campaignid:props.location.state.campaign.Id,
        appid:props.location.state.campaign.ApplicationId,
        name:props.location.state.campaign.Name,
        status:props.location.state.campaign.State.CampaignStatus,
        endpoint_target:'',
        endpoint_processed:'',
        lastrun:'',
        result:'',
        startsate:props.location.state.campaign.Schedule.StartTime,
        endtime:props.location.state.campaign.Schedule.EndTime,
        frecuency:props.location.state.campaign.Schedule.Frequency,
        time_zone:props.location.state.campaign.Schedule.Timezone,
        segment_name:'',
        segment_id:props.location.state.campaign.SegmentId,
        cnt_campaña_env:0,
        cnt_campaña_err:0,
        cnt_agente_env:0,
        cnt_agente_err:0,
        habilitada:false,
        campaign_id:"",
        agentesh:props.location.state.habilitar,
        usuario:""
    }
    this.handleExport=this.handleExport.bind(this)
}

componentDidMount(){
    var authToken = getToken();
    var thisa= this;
     var cnt_envio=0;
    var cnt_error=0;
    var cnt_envioa=0;
    var cnt_errora=0;
 
    campañamanager.ObtenerCampaña(authToken,this.state.campaignid,function(camp){
      if(camp){
            thisa.setState({
                habilitada:camp.habilitada,
                campaign_id:camp.campaign_id,
                reserva:camp.reserva,
                usuario:camp.user_id
            })
            
            if(thisa.state.status=="COMPLETED" && camp.reserva==true){
                var campa={
                    campaign_id:camp.campaign_id,
                    finalizado:false,
                    reserva:false,
                    habilitada:camp.habilitada
                }
                campañamanager.ActualizarCampaña(authToken,campa,function(respo){
                    console.log(respo)
                })
            }

        }
    })

    contactmanager.ObtenerContactos(authToken,this.state.campaignid,function(contactos){
      console.log(contactos)
        if(contactos){
          for(var i=0;i<contactos.length;i++){
            if(contactos[i].estado_mensaje==null || contactos[i].estado_mensaje==undefined){
              contactos[i].estado_mensaje=contactos[i].estado
            }
            if(contactos[i].numero_contacto==null || contactos[i].numero_contacto==undefined){
              contactos[i].numero_contacto=contactos[i].contacto
            }
            if(contactos[i].codigo_error==null || contactos[i].codigo_error==undefined){
              contactos[i].codigo_error=contactos[i].codigo
            }
            if(contactos[i].mensaje_error==null || contactos[i].mensaje_error==undefined){
              contactos[i].mensaje_error=contactos[i].mensaje
            }
            if(contactos[i].detalle_error==null || contactos[i].detalle_error==undefined){
              contactos[i].detalle_error=contactos[i].detalle
            }
            
          if(contactos[i].medio_envio==null || contactos[i].medio_envio==undefined){
            contactos[i].medio_envio=contactos[i].envio
          }
                if(contactos[i].estado_mensaje=="enviado" || contactos[i].estado=="enviado" ){
                    cnt_envio=cnt_envio+1;
                }else{
                    cnt_error=cnt_error+1;
                }

           }
           thisa.setState({
               cnt_campaña:contactos.length,
               cnt_campaña_err:cnt_error,
               cnt_campaña_env:cnt_envio,
               contactos:contactos
           })
        }
    })
    contactmanager.ObtenerContactosAgente(authToken,this.state.campaignid,function(contactos){
        if(contactos){
          for(var i=0;i<contactos.length;i++){
            if(contactos[i].estado_mensaje==null || contactos[i].estado_mensaje==undefined){
              contactos[i].estado_mensaje=contactos[i].estado
            }
            if(contactos[i].numero_contacto==null || contactos[i].numero_contacto==undefined){
              contactos[i].numero_contacto=contactos[i].contacto
            }
            if(contactos[i].codigo_error==null || contactos[i].codigo_error==undefined){
              contactos[i].codigo_error=contactos[i].codigo
            }
            if(contactos[i].mensaje_error==null || contactos[i].mensaje_error==undefined){
              contactos[i].mensaje_error=contactos[i].mensaje
            }
            if(contactos[i].detalle_error==null || contactos[i].detalle_error==undefined){
              contactos[i].detalle_error=contactos[i].detalle
            }
            
          if(contactos[i].medio_envio==null || contactos[i].medio_envio==undefined){
            contactos[i].medio_envio=contactos[i].envio
          }

              if(contactos[i].estado=="enviado" || contactos[i].estado_mensaje=="enviado"){
                    cnt_envioa=cnt_envioa+1;
                }else{
                    cnt_errora=cnt_errora+1;
                }

           }
            thisa.setState({
                cnt_agente:contactos.length,
                cnt_agente_env:cnt_envioa,
                cnt_agente_err:cnt_errora,
                agentes:contactos
           })
        }
    })
}

handleExport(event){
  event.preventDefault();

  var items=this.state.contactos;
  var agents=this.state.agentes;
  for(let i=0;i<agents.length;i++){
    items.push(agents[i])
  }
  var filename= this.state.campaignid;
  var headers={
    user_id:'Id',
    numero_contacto:'Contacto',
    medio_envio:'TipoEnvio',
    fecha_hora:'FechaInteraccion',
    conector_name:'Conector',
    template_name:'Template',
    estado_mensaje:"Estado",
    codigo_error:"Codigo",
    mensaje_error:"Mensaje",
    detalle_error:"Detalle",
  }
var agentes=false;
var campana=false;
  if(agents.length>0){
    headers.agent_id="AgenteId"
    headers.agente="Agente"
    agentes=true
  }
  if(items.length>0){
    headers.usuario="Usuario"
    campana=true
  }
  const jsonObject = JSON.stringify(items);
const csv = this.convertToCSV(jsonObject,headers,agentes,campana);
  const exportName = filename + ".csv" || "export.csv";
const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
  navigator.msSaveBlob(blob, exportName);
  } else {
      console.log("AQUI2")
      const link = document.createElement("a");
      if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      }
}
}
convertToCSV(objArray,headers,agentes,campana) {
const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
let str = "Id,Contacto,TipoEnvio,FechaEnvio(UTC),Conector,Template,EstadoEnvio,CodigoError,MensajeError,DetalleError"
if(agentes){
str=str+",AgenteId,Agente"
}
if(campana){
str=str+",Usuario"
}
str=str+ "\r\n";
for (let i = 0; i < array.length; i++) {
    let line = "";
    for (let index in headers) {
        if (line != ""){
            line += ",";
        }
        var text =array[i][index];
        var tipo=array[i]["medio_envio"]
        if(headers[index]=="FechaEnvio(UTC)"){
          text= (new Date(text)).toLocaleString()
        }
        if(headers[index]=="Usuario"){
          if(tipo=="campaña"){
            text=array[i]["agente"]
          }
        }
        if(headers[index]=="Agente"){
          if(tipo=="campaña"){
            text="-"
          }
        }
        if(text===undefined){
          text="-"
      }else if(text===""){
          text="-"
      }
        line += text;
    }
    str += line + "\r\n";
}

return str;
}
    render() {
     const genPieData=()=>{
            return {
              datasets: [
                {
                  data: [parseInt(this.state.cnt_campaña_env)+parseInt(this.state.cnt_agente_env),parseInt(this.state.cnt_agente_err)+parseInt(this.state.cnt_campaña_err)],
                  backgroundColor: [
                    '#77dd77',
                    '#ff6961',
                  ],
                  label: 'Campaña',
                },
              ],
              labels: ['Enviados', 'Error'],
            };
          };
          const genPieDatas=()=>{
            return {
              datasets: [
                {
                  data: [this.state.cnt_agente,this.state.cnt_campaña],
                  backgroundColor: [
                    '#fdfd69',
                    '#84b6f4',
                  ],
                  label: 'Campaña',
                },
              ],
              labels: ['Agentes', 'Campaña'],
            };
          };

          const genPieDatacam=()=>{
            return {
              datasets: [
                {
                  data: [this.state.cnt_campaña_env,this.state.cnt_campaña_err],
                  backgroundColor: [
                    '#77dd77',
                    '#ff6961',
                  ],
                  label: 'Campaña',
                },
              ],
              labels: ['Enviados', 'Error'],
            };
          };
          const genPieDatasagen=()=>{
            return {
              datasets: [
                {
                  data: [this.state.cnt_agente_env,this.state.cnt_agente_err],
                  backgroundColor: [
                    '#77dd77',
                    '#ff6961',
                  ],
                  label: 'Campaña',
                },
              ],
              labels: ['Enviados', 'Error'],
            };
          };
     
        return (
            <div style={{textAlign:"start"}}>
                <br/>
                <form >
                <div className="template-inner">
                    <div className="row">
                        <h2>Detalles de Campaña</h2>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <label>Nombre</label>
                            <div >{this.state.name}</div>
                            <label>Estado</label>
                            <div>{this.state.status}</div>
                            <label>Enviado por:</label>
                            <div>{this.state.usuario}</div>
                        </div>
                        {
                            this.state.campaign!==null?(
                                <>
                                <div className="col-sm-4">
                                    <h4 style={{textAlign:"start"}}>Ultimo Envio</h4>
                                    <label>Fecha de Envio</label>
                                    <div className="col-sm-6">{this.state.lastrun}</div>
                                    <label>Resultado</label>
                                    <div className="col-sm-6">{this.state.result}</div>
                                </div>
                            </>
                            ):(
                                <></>
                            )
                        }
                        {this.state.agentesh?(<>
                            <div className="col-sm-4">
                            <label className="form-check-label" >Habilitar para agentes</label>
                            </div>
                            <div className="form-check form-switch col-sm-2">
                                <input className="form-check-input" type="checkbox" 
                                    disabled={(this.state.disabled)? "disabled" : "" }
                                    checked={(this.state.habilitada)? "checked" : ""}
                                    onChange={(e) => {
                                        this.setState({
                                            habilitada:e.target.checked
                                        })
                                        var camp={
                                            habilitada:e.target.checked,
                                            finalizado:false,
                                            campaign_id:this.state.campaign_id,
                                            reserva:this.state.reserva
                                        }
                                        var thisa=this;
                                        var msg="deshabilita"
                                        if(e.target.checked==true){
                                            msg="habilita"
                                        }
                                        campañamanager.ActualizarCampaña(getToken(),camp,function(res){
                                            if(res){
                                                thisa.setState({
                                                    mensaje:"Campaña "+msg+"da para agentes"
                                                })
                                            }else{
                                                thisa.setState({
                                                    mensaje:"No se pudo "+msg+"r la campaña"
                                                })
                                            }
                                        })
                                    }}
                                    />
                            </div>
                   </>):(<></>)}
                    </div>
                    <label>{this.state.mensaje}</label>
                    <div>
                    <a href="/campaign-list" className="btn btn-secondary "  role="button" aria-disabled="true">Volver</a>  
                    </div>
                </div>
                <br/>
                <div className="template-inner">
                    <h2>Calendarizado</h2>
                    <div className="row">
                        <div className="col-sm-4">
                            <label>Fecha de inicio</label>
                            <div>{this.state.startsate}</div>
                            {
                                this.state.endtime!==undefined?(
                                    <>
                                        <label>Fecha final</label>
                                        <div>{this.state.endtime}</div>
                                    </>
                                ):(
                                    <></>
                                )
                            }
                        </div>
                        <div className="col-sm-4">
                            <label>Zona Horaria</label>
                            <div>{this.state.time_zone}</div>
                        </div>
                        <div className="col-sm-4">
                            <label>Frecuencia</label>
                            {
                                this.state.startsate==='IMMEDIATE'?(
                                    <>
                                    <div>
                                    Enviado Inmediato
                                    </div>
                                    </>
                                ):(
                                    <>
                                        <div>{this.state.frecuency}</div>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
                <br/>

                {
                    this.state.contactos.length!=0?(<>
                      <div className="template-inner">
               { <button id="createuser" 
                className="paddingbutton btn btn-primary btn-block" 
                onClick={this.handleExport}>Exportar Lists de Contactos </button>
                }  
                <br/>
                   <br/>
                   <Row>
            <Col xl={3} lg={6} md={6}>
            <Card>
                <CardHeader>Mensajes Enviados</CardHeader>
                <CardBody>
                <Pie data={genPieData} />
                </CardBody>
            </Card>
            </Col>

            <Col xl={3} lg={6} md={6}>
            <Card>
                <CardHeader>Medio de Envio</CardHeader>
                <CardBody>
                <Pie data={genPieDatas} />
                </CardBody>
            </Card>
            </Col>
            <Col xl={3} lg={6} md={6}>
            <Card>
                <CardHeader>Enviados por campaña</CardHeader>
                <CardBody>
                <Pie data={genPieDatacam} />
                </CardBody>
            </Card>
            </Col>

            <Col xl={3} lg={6} md={6}>
            <Card>
                <CardHeader>Enviados por agentes</CardHeader>
                <CardBody>
                <Pie data={genPieDatasagen} />
                </CardBody>
            </Card>
            </Col>
        </Row> 
                    
                </div>
              </>):(<></>)
                }
                </form>
            </div>
        );
    }
}