import React, { Component } from "react";
import { getTenant, getToken } from "../../../auth-helpers";
const templatemanager = require('../../../ServerFunctions/templatemanager.js');
export default class TenantTemplateDelete extends Component {
constructor(props){
    super(props);
    this.state={
        id:props.location.state.template.template_id,
        name:props.location.state.template.template_name,
        disabled:false
    }
    this.handleDelete=this.handleDelete.bind(this);
}

handleDelete(event){ 
    event.preventDefault();
    this.setState({
        disabled:true,
        mensaje:'Espere mientras se elimina la plantilla'
    })
    const thisa = this; 
    var authToken = getToken();
    var tenant=getTenant();
    templatemanager.EliminarTemplateAdministrador(this.state.id,tenant.TenantId,authToken,function(template) {
        if(template){
            thisa.setState({
                mensaje:'Plantilla eliminada correctamente'
            })
            setTimeout(function(){
                window.location.href="/template-list"
            }, 1000);

        }else{
            thisa.setState({
                disabled:false,
                mensaje:'No se pudo elimar la plantilla. Intentelo más tarde',
            })
            setTimeout(function(){
                window.location.href="/template-list"
            }, 2000);
        } 
    })
}

render() {
    var tenant=getTenant();
    return (
        <div className="auth-wrapper">
          <div className="auth-inner">
          <form role="form">
            <p>Esta seguro de eliminar la plantilla: {this.state.name} de la organización {tenant.CompanyName }?</p>
            <button type="submit" disabled={(this.state.disabled)? "disabled" : ""} className="paddingbutton btn btn-block btn-outline-primary" onClick={this.handleDelete}>Eliminar</button>
            <a href="/template-list" className="paddingbutton btn btn-outline-info"  role="button" aria-disabled="true">Volver</a>  
            <div>
                <label>{this.state.mensaje}</label>
            </div>
        </form>
        </div>
        </div>
    );
}
}