import React, { Component} from "react";
import { getToken } from "../../auth-helpers";
import jwtDecode from "jwt-decode";
import {Link} from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay-ts';
import 'datatables.net-dt/js/dataTables.dataTables.min.js';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
const $ = require('jquery');
$.DataTable = require('datatables.net');
const templatemanager = require('../../ServerFunctions/templatemanager.js');
export default class TemplateList extends Component {
constructor(props){
    super(props);
    this.state={
        templates:[],
        user:null,
        isActive:true,
    }    
}

componentDidMount(){
    var authToken = getToken();
    var thisa= this;
    var usr;

    if(authToken){
        var user = jwtDecode(authToken);
        usr={
            companyName:user['custom:companyName'],
            email:user['email'],
            role:user['custom:role']
        };
        this.setState({
            user:usr,
        })
        templatemanager.ListTemplate(authToken,function(templates) {
            thisa.setState({
                templates,
                isActive:false
            })
            $('#templatesPC').DataTable();
        });
    }    
}

    render() {
        var templates=this.state.templates;
        var isActive=this.state.isActive;
        return (
            <>
              <LoadingOverlay
                active={isActive}
                spinner
                styles={{
                    wrapper: {
                        width: '100%',
                        height: '100%',
                        overflow: isActive ? 'hidden' : '',
                    },
                }}
                text="Cargando ...">
                <div>
                    {
                        this.state.user&&(
                            <>
                                {
                                    this.state.user.role==="SystemAdmin" || this.state.user.role=="TenantAdmin"?(
                                        <>
                                            <div id="cognitousers"  align="right">
                                                <a className="btn btn-primary" href="/template-add">
                                                    <span className="glyphicon glyphicon-plus"></span> Agregar Plantilla
                                                </a>
                                            </div>
                                    </>):(<></>)
                                }
                            </>
                        )
                    }            
                <div id="cognitousers" className="table-responsive">
                    <table id="templatesPC" className="display"    
                    style={{ width: '100%', float: 'left' }}>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>ID</th>                
                                <th>Nombre</th>
                                <th>País</th>
                                <th>Template</th>
                                <th>Validado</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                templates?(
                                    <>
                                    { templates.map((item, i) => {
                                        return (
                                            <tr key={item.template_id}>
                                                <td>{i+1}</td>
                                                <td>{item.template_id}</td>
                                                <td>{item.template_name}</td>
                                                <td>
                                                {
                                                    item.conector.codigo=="593"?("EC"):(
                                                    <>
                                                        {
                                                        item.conector.codigo=="51"?("PE"):("COL")
                                                        }
                                                    </>)
                                                }
                                                </td>
                                                <td>{item.template}</td>
                                                <td>{item.verificacion}</td>
                                                
                                                <td>
                                                <div className="btn-group">
                                                    {
                                                        this.state.user&&(<>
                                                        {
                                                            this.state.user.role==="SystemAdmin" || this.state.user.role=="TenantAdmin"?(<>
                                                            {
                                                            item.verificacion==="pendiente"?(<>
                                                                {
                                                                    this.state.user.role==="SystemAdmin"?(
                                                                        <>
                                                                            <Link
                                                                            className="paddingicon" title="Edit"
                                                                            to={{
                                                                                pathname: "/template-edit",
                                                                                state: { template: item, edit:false }
                                                                            }}>
                                                                                <span className="glyphicon glyphicon-pencil"></span>
                                                                            </Link>
                                                                            <Link
                                                                                className="paddingicon" title="Delete"
                                                                                to={{
                                                                                    pathname: "/template-delete",
                                                                                    state: { template: item }
                                                                                }}>
                                                                                    <span className="glyphicon glyphicon-trash"></span>
                                                                                </Link>
                                                                        </>
                                                                    ):(
                                                                        <>
                                                                        <Link
                                                                            className="paddingicon" title="View"
                                                                            to={{
                                                                                pathname: "/template-edit",
                                                                                state: { template: item,edit:true }
                                                                            }}>
                                                                                <span className="glyphicon glyphicon-eye-open"></span>
                                                                        </Link>
                                                                        <Link
                                                                            className="paddingicon" title="Delete"
                                                                            to={{
                                                                                pathname: "/template-delete",
                                                                                state: { template: item }
                                                                            }}>
                                                                                <span className="glyphicon glyphicon-trash"></span>
                                                                            </Link>
                                                                        </>
                                                                    )
                                                                }
                                                            </>):(<>
                                                                <Link
                                                                    className="paddingicon" title="View"
                                                                    to={{
                                                                        pathname: "/template-edit",
                                                                        state: { template: item,edit:true }
                                                                    }}>
                                                                        <span className="glyphicon glyphicon-eye-open"></span>
                                                                    </Link>
                                                                    <Link
                                                                    className="paddingicon" title="Delete"
                                                                    to={{
                                                                        pathname: "/template-delete",
                                                                        state: { template: item }
                                                                    }}>
                                                                        <span className="glyphicon glyphicon-trash"></span>
                                                                    </Link>
                                                            </>)
                                                        }</>):(<>
                                                        <Link
                                                            className="paddingicon" title="View"
                                                            to={{
                                                                pathname: "/template-edit",
                                                                state: { template: item,edit:true }
                                                            }}>
                                                                <span className="glyphicon glyphicon-eye-open"></span>
                                                        </Link></>)}
                                                        </>)
                                                    }
                                                    
                                                </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </>
                                ):(
                                    <>
                                    No se econtraron plantillas creadas
                                    </>
                                )
                            }
                        </tbody>
                    </table>
                </div>
                </div>         
            </LoadingOverlay>
        </>
        );
    }
}