import axios from 'axios';
//Pruebas
//const url_tenantreg="http://localhost:3025/"
//const url_tenantmanager="http://localhost:3020/"

//Pruebas
const url_tenantreg="https://4rr8k3lg47.execute-api.us-east-2.amazonaws.com/dev/"
const url_tenantmanager="https://u1mweiz5v1.execute-api.us-east-2.amazonaws.com/dev/"
/*
//Producción
const url_tenantreg="https://fiivlhpg73.execute-api.us-east-2.amazonaws.com/prod/"
const url_tenantmanager="https://va0ksp79z8.execute-api.us-east-2.amazonaws.com/prod/"
*/
 export function RegisterTenant(tenant,token,callback) {
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.post( 
      url_tenantreg+`reg`,
      tenant,
      config
    ).then(res=>{
        console.log(res.data);
        axios.post(
            url_tenantmanager+'tenant/create/'+res.data.tenan.tenant_id
        ).then(res=>{
            callback(tenant)
        }).catch(err=>{
            callback(null)
        });
    }).catch(err=>{
        callback(null)
    });
}

 export function EditTenant(tenant,token,callback) {
   const config = {
       headers: { Authorization: `${token}` }
   };
   axios.put( 
     url_tenantmanager+`tenant`,
     tenant,
     config
   ).then(res=>{
       callback(res.data)
   }).catch(err=>{
       callback(null)
   });
}

 export function DeleteTenant(tenant,token,callback) {
   const config = {
       headers: { Authorization: `${token}` }
   };
   axios.delete( 
     url_tenantmanager+`tenant/`+tenant,
     config
   ).then(res=>{
       callback(res.data)
   }).catch(err=>{
       callback(null)
   });
}

export function ListTenant(token,callback){
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.get( 
        url_tenantmanager+`tenants`,
      config
    ).then(res=>{
        callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}