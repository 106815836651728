import React, { Component } from "react";
import { getToken } from "../../auth-helpers";
const tenantmanager = require('../../ServerFunctions/tenantmanager');
export default class TenantEdit extends Component {
constructor(props){
    super(props);
    console.log(props.location.state.tenant)
    this.state={
        companyName:props.location.state.tenant.companyName,
        ownerName: props.location.state.tenant.ownerName,
        status:props.location.state.tenant.status,
        mensaje:'',
        id:props.location.state.tenant.tenant_id,
        domain:props.location.state.tenant.genesysdomain,
        genesysclient:props.location.state.tenant.genesysclient,
        org:props.location.state.tenant.genesysorg,
        disabled:false
    }
    this.handleCreate=this.handleCreate.bind(this);
}

handleCreate(event){
    event.preventDefault();
    this.setState({
        mensaje:'',
        disabled:true
    })
   var tenantAdminData = {
        id: this.state.id,
        companyName: this.state.companyName,
        ownerName: this.state.ownerName,
        domain: this.state.domain||"",
        org:this.state.org||"",
        status:this.state.status,
        genesysclient:this.state.genesysclient||""
    };
  if( this.state.domain==='undefined' || this.state.domain===undefined){
        this.setState({
            domain:""
        })
  }
  if(this.state.genesysclient==='undefined' || this.state.genesysclient===undefined){
    this.setState({
        genesysclient:""
    })
  }
  if(this.state.org==='undefined' || this.state.org===undefined){
    this.setState({
        org:""
    })
  }
    const thisa = this; 
   if(this.state.companyName!=='' && this.state.ownerName!==''  && this.state.status!==''){
        var authToken = getToken();
        tenantmanager.EditTenant(tenantAdminData,authToken,function(tenant) {
            console.log(tenant)
            if(tenant){
                thisa.setState({
                    mensaje:'Organización actualizada'
                })
                setTimeout(function(){
                    window.location.href="/tenant-list"
                }, 2000);
            }else{
                thisa.setState({
                    mensaje:'No se pudo actualizar la información. Intentelo más tarde'
                })
            }
        })
   }else{
        this.setState({
            mensaje:'Campos vacios',
        disabled:false
    })
   }
}

    render() {
        return (
            <div className="auth-wrapper">
              <div className="auth-inner">
           
            <form onSubmit={this.handleCreate}>
                <h3>Actualizar Organización</h3>

                <div className="form-group">
                    <label>Nombre de la organización</label>
                    <input  
                    disabled
                    className="form-control" 
                    placeholder="Tenant name" 
                    value={this.state.companyName}
                    onChange={(e)=>this.setState({
                        companyName: e.target.value
                        })}/>
                </div>
                <div className="form-group">
                    <label>Nombre del propietario</label>
                    <input 
                    type="text" 
                disabled={(this.state.disabled)? "disabled" : ""}
                className="form-control" 
                    placeholder="Owner Name" 
                    value={this.state.ownerName}
                    onChange={(e)=>this.setState({
                        ownerName: e.target.value
                        })}
                    />
                </div>
                <div className="form-group">
                    <label>Dominio de Genesys</label>
                    <input 
                disabled={(this.state.disabled)? "disabled" : ""}
                type="text" 
                    className="form-control" 
                    placeholder="Genesys" 
                    value={this.state.domain}
                    onChange={(e)=>this.setState({
                        domain: e.target.value
                        })}
                    />
                </div>
                <div className="form-group">
                    <label>Organización de Genesys</label>
                    <input 
                disabled={(this.state.disabled)? "disabled" : ""}
                type="text" 
                    className="form-control" 
                    placeholder="Genesys Organization" 
                    value={this.state.org}
                    onChange={(e)=>this.setState({
                        org: e.target.value
                        })}
                    />
                </div>
                <div className="form-group">
                    <label>Cliente de Genesys</label>
                    <input 
                disabled={(this.state.disabled)? "disabled" : ""}
                type="text" 
                    className="form-control" 
                    placeholder="Genesys Client" 
                    value={this.state.genesysclient}
                    onChange={(e)=>this.setState({
                        genesysclient: e.target.value
                        })}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="status" className="control-label">Status</label>
                    <select
                disabled={(this.state.disabled)? "disabled" : ""}
                className="form-control" 
                     value={this.state.status}
                     onChange={(e)=>this.setState({
                         status:e.target.value
                     })} >
                    <option value='Active'>Active</option>
                    <option value='Inactive'>Inactive</option>
                    </select>
                </div>

                <button type="submit" 
                disabled={(this.state.disabled)? "disabled" : ""}
                className="paddingbutton btn btn-block btn-outline-primary" 
                onClick={this.handleCreate}
                >Actualizar</button>
                <a href="/tenant-list" className="paddingbutton btn btn-outline-info "  role="button" aria-disabled="true">Volver</a>  
                <div>
                    <br/>
                <label>{this.state.mensaje}</label>
                </div>
            </form>
            </div>
            </div>
        );
    }
}