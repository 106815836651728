import React, { Component } from "react";
import { getTenant, getToken } from "../../../auth-helpers";
const conectormanagergenesys = require('../../../ServerFunctions/conectormanagergenesys');
export default class TenantConectorAdd extends Component {
constructor(props){
    super(props);
    this.state={
        conector_genesys_id:"a",
        conector_genesys_name:"",
        conector_servicio_id:"a",
        conector_servicio_name:"",
        disabled:false,
        conectores_genesys:[],
        conectores_servicio:[],
        texto:"Cargando Conectores"
    }
    this.handleCreate=this.handleCreate.bind(this);
}

componentDidMount(){
    var authToken = getToken();
        var thisa= this;
        var tenant=getTenant();
        conectormanagergenesys.ListGenesysConectorAdministrador(authToken,"ObtenerDisponiblesGenesys",tenant.TenantId,function(conectors) {
            thisa.setState({
                conectores_genesys:conectors
            })
            if(conectors){
                if(conectors.length==0){
                    thisa.setState({
                        conector_genesys_id:"",
                        texto:"No existen conectores disponibles"   
                    })
                }
            }else{
                    thisa.setState({
                        conector_genesys_id:"" ,   
                        texto:"No existen conectores disponibles"   
                    })
            }
        })
        conectormanagergenesys.ListGenesysConectorAdministrador(authToken,"ObtenerDisponiblesServicio",tenant.TenantId,function(conectors) {
            thisa.setState({
                conectores_servicio:conectors
            })
            if(conectors){
                if(conectors.length==0){
                    thisa.setState({
                        conector_servicio_id:""  ,
                        texto:"No existen conectores disponibles"   

                    })
                }
            }else{
                    thisa.setState({
                        conector_servicio_id:"" ,
                        texto:"No existen conectores disponibles"   

                    })
            }
        })
    
}

handleCreate(event){
    event.preventDefault();
    this.setState({
        disabled:true,
        mensaje:'Espere mientras se crea el Conector'
    })
    var authToken = getToken();
    var thisa= this;
    var tenant=getTenant();
    var conector_genesys_id=this.state.conector_genesys_id
    var conector_servicio_id=this.state.conector_servicio_id
    console.log(conector_servicio_id)
    console.log(conector_genesys_id)
    if(conector_genesys_id=="" || conector_genesys_id=="a" || conector_servicio_id=="" || conector_servicio_id=="a"){
        alert("Seleccione un conector de genesys y un conector de servicio")
        this.setState({
            disabled:false,
            mensaje:''
        })
    }else{
       conectormanagergenesys.ConexionConectoresAdministrador(authToken,conector_servicio_id,conector_genesys_id,tenant.TenantId,function(conector){
            if(conector){
                alert("Conectores asociados correctamente")
                setTimeout(function(){
                    window.location.href="/conectorgenesysconexion-list"
                }, 2000);
            }else{
                thisa.setState({
                    disabled:false,
                    mensaje:"No se pudo crear el conector. Intentelo más tarde"
                })
                
                setTimeout(function(){
                    window.location.href="/conectorgenesysconexion-list"
                }, 2000);
            }
        })
    }
}

    render() {
        var conector_genesys=this.state.conectores_genesys
        var conector_servicio=this.state.conectores_servicio
        return (
            <>
            <div>
            <br/>
            <div className="template-inner" style={{maxWidth:"650px"}}>
                <h3>Relacionar Conectores</h3>
                <br/>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Seleccionar conector genesys</label>
                            <div className="col-sm-9">
                            <select 
                            className="form-select"
                            value={this.state.conector_genesys_id}
                            disabled={(this.state.disabled)? "disabled" : "" }
                            onChange={
                                (e)=>{
                                    this.setState({
                                        conector_genesys_id: e.target.value,
                                        conector_genesys_name: e.target[e.target.selectedIndex].text

                            })}}
                            >
                            {
                                conector_genesys.length>0?(
                                    <>
                                    <option value='a' >Escoger Conector</option>
                                    {
                                        conector_genesys.map((item, i) => {
                                            return (
                                                <option key={item.conector_id} value={item.conector_id} >{item.nombre}</option>
                                            )
                                        })
                                    }
                                    </>
                                ):(
                                    <>
                                        <option value='' >{this.state.texto}</option>
                                    </>
                                )                                
                            }
                        </select>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Seleccionar conector de servicio</label>
                            <div className="col-sm-9">
                            <select 
                            className="form-select"
                            value={this.state.conector_servicio_id}
                            disabled={(this.state.disabled)? "disabled" : "" }
                            onChange={
                                (e)=>{
                                    this.setState({
                                        conector_servicio_id: e.target.value,
                                        conector_servicio_name: e.target[e.target.selectedIndex].text

                            })}}
                            >
                            {
                                conector_servicio.length>0?(
                                    <>
                                    <option value='a' >Escoger Conector</option>                                    
                                    {
                                        conector_servicio.map((item, i) => {
                                            return (
                                                <option key={item.conector_id} value={item.conector_id} >{item.nombre}</option>
                                            )
                                        })
                                    }
                                    </>
                                ):(
                                    <>
                                        <option value='' >{this.state.texto}</option>
                                    </>
                                )                                
                            }
                        </select>
                            </div>
                        </div>

                        <button className="paddingbutton btn btn-block btn-outline-primary" 
                        onClick={this.handleCreate}
                        disabled={(this.state.disabled)? "disabled" : "" }> Crear</button>
                        <a href="/conectorgenesysconexion-list" className="paddingbutton btn btn-outline-info"  role="button" aria-disabled="true">Volver</a>  
                        <div>
                            <label>{this.state.mensaje}</label>
                        </div>
                    </div>
                    <br/>
                </div>
                </>
        );
    }
}