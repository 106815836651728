 import axios from 'axios';
 //local
//const url_auth="http://localhost:3010/"
//const url_usuarios="http://localhost:3015/"
//Develop
const url_auth="https://1g1zr76xe1.execute-api.us-east-2.amazonaws.com/dev/"
const url_usuarios="https://3ta4izjnt1.execute-api.us-east-2.amazonaws.com/dev/"

//Producción
//const url_auth="https://km6y4ceul5.execute-api.us-east-2.amazonaws.com/prod/"
//const url_usuarios="https://nym4tjsspb.execute-api.us-east-2.amazonaws.com/prod/"
//
export function Login(user,callback) {
    axios.post(
        url_auth+`auth`
        ,user).then(
            res=>{
                console.log(res)
                callback(res.data);
            }
        ).catch(
            err=>{
                console.log(err)
                callback(err)
            }
        )
}

export function Refresh(user,callback) {
    axios.post(
        url_auth+`auth/refresh`
        ,user).then(
            res=>{
                callback(res.data);
            }
        ).catch(
            err=>{
                callback(null)
            }
        )
}

export function CrearUser(token,user,callback) {
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.post( 
      url_usuarios+`user`,
      user,
      config
    ).then(res=>{
        if(res.status=="200"){
            callback(res.data)
        }else{
        callback(null)
        }
    }).catch(err=>{
        callback(null)
    });
}

export function EliminarUser(token,user,callback) {
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.delete( 
      url_usuarios+`user/`+user,
      config
    ).then(res=>{
        callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}

export function EditarUser(token,user,callback) {
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.put( 
      url_usuarios+`user`,
      user,
      config
    ).then(res=>{
        if(res.status=="200"){
            callback(res.data)
        }else{
            callback(null)
        }
    }).catch(err=>{
        callback(null)
    });
}

export function EnabledUser(token,user,callback) {
    const config = {
        headers: { Authorization: `${token}` }
    };
        axios.put( 
            url_usuarios+`user/enable`,
            user,
            config
          ).then(res=>{
                if(res.status=="200"){
                    callback(res.data)
                }else{
                      callback(null)
                }
          }).catch(err=>{
              callback(null)
          });
}
   
export function ListUser(token,callback){
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.get( 
        url_usuarios+`users`,
      config
    ).then(res=>{
        callback(res.data)
    }).catch(err=>{
        callback(null)
    });
   
}

export function GetCode(user, callback) {
    axios
      .post(
       url_auth+'auth/forgotpassword',
        user
      )
      .then((res) => {
        var errores={}
        if (res.data.message === 'UserCompany not found') {
          errores = {
            mensaje: 'No existe',
          };
          callback(errores);
        } else {
          errores = {
            mensaje: 'Si existe',
            status: res.data.statusCode,
          };
          callback(errores);
        }
      })
      .catch((err) => {
        callback(err);
      });
  }

export function NewPassword(user, callback) {
    axios
      .post(
        url_auth+'auth/newpassword',
        user
      )
      .then((res) => {
        var errores={}
        if (res.data.message === 'UserCompany not found') {
          errores = {
            mensaje: 'No existe',
          };
          callback(errores);
        } else {
          errores = {
            mensaje: 'Si existe',
            status: res.data.statusCode,
            msg: res.data.message,
          };
          callback(errores);
        }
      })
      .catch((err) => {
        callback(err);
      });
  }

export function ListUserAdministrador(token,tenant,callback){
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.get( 
        url_usuarios+`users/admin/`+tenant.UserPoolId,
      config
    ).then(res=>{
        callback(res.data)
    }).catch(err=>{
        callback(null)
    });
   
}

export function CrearUserAdministrador(token,user,tenant,callback) {

    user.UserPoolId=tenant.UserPoolId;
    user.IdentityPoolId=tenant.IdentityPoolId;
    user.client_id=tenant.ClientId;
    user.tenant_id=tenant.TenantId;
    user.companyName=tenant.CompanyName;
    user.app=tenant.PinPointId;
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.post( 
      url_usuarios+`user/admin/add`,
      user,
      config
    ).then(res=>{
        console.log(res);
        callback(res.data)
    }).catch(err=>{
        console.log(err);
        callback(null)
    });
}

export function EditarUserAdministrador(token,user,tenant,callback) {
    user.UserPoolId=tenant.UserPoolId;
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.put( 
      url_usuarios+`user/admin`,
      user,
      config
    ).then(res=>{
        callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}

export function EnabledUserAdministrador(token,user,tenant,callback) {
    user.UserPoolId=tenant.UserPoolId;
    const config = {
        headers: { Authorization: `${token}` }
    };
        axios.put( 
            url_usuarios+`user/admin/enable`,
            user,
            config
          ).then(res=>{
              callback(res.data)
          }).catch(err=>{
              callback(null)
          });
    
    
}

export function EliminarUserAdministrador(token,user,tenantid,callback) {
    const config = {
        headers: { Authorization: `${token}` }
    };
    axios.delete( 
      url_usuarios+`user/admin/`+user+'/'+tenantid,
      config
    ).then(res=>{
        callback(res.data)
    }).catch(err=>{
        callback(null)
    });
}


