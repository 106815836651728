import React, { Component } from "react";
import { getToken } from "../../../auth-helpers";
const campañamanager = require('../../../ServerFunctions/campaignmanager');
export default class SegmentDelete extends Component {
constructor(props){
    super(props);
    console.log(props.location.state.segment)
    this.state={
        name:props.location.state.segment.nombre,
        id:props.location.state.segment.segment_id,
        fecha:props.location.state.segment.fecha,
        key:props.location.state.segment.urlS3,
        tenantid:props.location.state.segment.tenant_id,
        mensaje:""
    }
    this.handleDelete=this.handleDelete.bind(this);
}


handleDelete(event){
    event.preventDefault();
    this.setState({
        disabled:true,
        mensaje:"Espere mientra se elimina la lista de contactos"
    })
    var authToken = getToken();
    console.log(authToken)
      var thisa=this;
      var key=this.state.key.split("/Contact/")[1]
      campañamanager.EliminarSegmento(authToken,this.state.id,key,function(segment){
        if(segment){
            thisa.setState({
                mensaje:"Lista de contactos eliminada correctamente"
            })
            setTimeout(function(){
                window.location.href="/segment-list"
            }, 1000);
        }else{
            thisa.setState({
                mensaje:"No se pudo eliminar la Lista de contactos. Intentelo más tarde"    
            })
            setTimeout(function(){
                window.location.href="/segment-list"
            }, 2000);
        }
    })
}

    render() {
        return (
            <div className="auth-wrapper">
              <div className="auth-inner">
              <form role="form">
                <p>Esta seguro de eliminar la lista de contacto: {this.state.name} creada el {this.state.fecha}?</p>
                <button type="submit" className="paddingbutton btn btn-primary btn-block" 
                disabled={(this.state.disabled)? "disabled" : ""}
                onClick={this.handleDelete}>Eliminar</button>
                <a href="/segment-list" className="btn btn-secondary "  role="button" aria-disabled="true">Volver</a>  
                <div>
                    <label>{this.state.mensaje}</label>
                </div>
            </form>
            </div>
            </div>
        );
    }
}