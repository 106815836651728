import React, { Component} from "react";
import { getTenant, getToken } from "../../../auth-helpers";
import {Link} from 'react-router-dom';
const conectormanager = require('../../../ServerFunctions/conectormanager.js');
export default class TenantConectorList extends Component {
constructor(props){
    super(props);
    this.state={
        conectors:[],
        user:null
    }    
}

componentDidMount(){
    var authToken = getToken();
        var thisa= this;
        var tenant=getTenant();
        conectormanager.ListConectorAdministrador(authToken,tenant.TenantId,function(conectors) {
            thisa.setState({
                conectors
            })
        })
    
}

    render() {
        var conectors=this.state.conectors;
        return (
            <>
            <div>
            <div id="cognitousers"  align="right">
                <a className="btn btn-primary" href="/conector-add">
                    <span className="glyphicon glyphicon-plus"></span> Agregar Conector
                </a>
            </div>
            <div id="cognitousers" className="table-responsive">
            <table className="table table-striped">
            <thead>
                    <tr>
                    <th>Nombre</th>
                    <th>Proveedor</th>
                    <th>País</th>
                    <th># Número</th>
                    <th>Acciones</th>
                    </tr>
                </thead>
            <tbody>
                {
                    conectors?(
                        <>
                        {conectors.map((item, i) => {
                            return (
                                <tr key={item.conector_id}>
                                    <td>{item.nombre}</td>
                                    <td>{item.provider_name}</td>
                                    <td>{item.cod_whatsapp=="593"?("EC"):(
                                        <>
                                        {
                                            item.cod_whatsapp=="51"?("PE"):("COL")
                                        }</>)}</td>
                                    <td>{item.num_whatsapp}</td>
                                <td>
                                    <div className="btn-group">
                                        
                                        <Link
                                        className="paddingicon" title="Edit"
                                        to={{
                                            pathname: "/conector-edit",
                                            state: { conector: item }
                                        }}>
                                            <span className="glyphicon glyphicon-pencil"></span>
                                        </Link>
                                        <Link
                                        className="paddingicon" title="Delete"
                                        to={{
                                            pathname: "/conector-delete",
                                            state: { conector: item }
                                        }}>
                                            <span className="glyphicon glyphicon-trash"></span>
                                        </Link>
                                    </div>
                                    </td>
                                </tr>
                            );
                        })}
                        </>
                    ):(
                        <>
                        </>
                    )
                }
            </tbody>
            </table>
            </div>
            </div>         

          </>

        );
    }
}