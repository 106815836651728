import React, { Component } from "react";
import {Navbar, NavDropdown,Nav} from "react-bootstrap"
import { deleteTenant, getTenant, getToken } from "../auth-helpers";
import logo from "../icono.png"
import jwtDecode from "jwt-decode";

export default class Menu extends Component {
    constructor(props){
      super(props);
      this.handleLogout=this.handleLogout.bind(this);
      this.handleBack=this.handleBack.bind(this);
    }

    handleLogout(event){
    event.preventDefault();
    window.localStorage.clear();
    window.location.href="/"
  }
  handleBack(event){
    event.preventDefault();
    deleteTenant();
    window.location.href="/home"
  }
    render() {
      var user=null
      var title="High Wizard"
      const token=getToken();
      if(token){
        user = jwtDecode(token);
        title=user['custom:companyName'];
      }
      var tenant=getTenant();
      if(tenant){
        user = jwtDecode(token);
        title=tenant.CompanyName+'(SystemAdmin)';
      }
      return (
          <>
            <Navbar collapseOnSelect expand="lg" bg="light" variant="light" className="padlat">
              <Navbar.Brand href="/home">
              <a class="navbar-brand" href="/">
                <img
                  alt=""
                  src={logo}
                  width="30"
                  height="30"
                  className="d-inline-block align-top"
                />{'  '}
                <span style={{ fontSize: 16 }}>
                  {title}
                </span>
                </a>
              </Navbar.Brand>
              {
                 user?(
                  <>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                  <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="mr-auto">
                    {
                      user["custom:role"]==="SystemAdmin"?(
                        <>
                        {
                          tenant?(
                            <></>
                          ):(
                            <>
                          <Nav.Link href="/tenant-list">Organizaciones</Nav.Link>
                            </>
                          )
                        }
                        </>
                      ):(
                        <></>
                      )
                    }
                    
                    <Nav.Link href="/user-list">Usuarios</Nav.Link>
                    {
                      user["custom:role"]==="SystemAdmin"?(
                        <>
                          {
                            !tenant?(
                              <Nav.Link href="/provider-list">Proveedor</Nav.Link>
                            ):(
                              <Nav>
                                <NavDropdown title="Conectores" id="collasible-nav-dropdown">
                                      <NavDropdown.Item href="/conector-list">
                                        Conectores WhatsApp
                                      </NavDropdown.Item>
                                      <NavDropdown.Item href="/conectorgenesys-list">
                                        Conectores Genesys
                                      </NavDropdown.Item>
                                      <NavDropdown.Item href="/conectorgenesysconexion-list">
                                        Conexión entre Conectores
                                      </NavDropdown.Item>
                                </NavDropdown>
                              </Nav>
                              
                            )
                          }
                        </>
                      ):(
                        <> 
                        </>
                      )
                    }
                    {
                      user["custom:role"]==="SystemAdmin"?(
                        <>
                        {
                          tenant?(
                            <>
                             <Nav.Link href="/template-list">  
                        Plantillas
                      </Nav.Link>
                  
                        <Nav className="mr-auto">
                          <NavDropdown title="Envio de Campañas" id="collasible-nav-dropdown">
                              <NavDropdown.Item href="/segment-list">
                                Lista de contactos
                              </NavDropdown.Item>
                              <NavDropdown.Item href="/campaign-list">
                                Campañas
                              </NavDropdown.Item>
                          </NavDropdown>
                      </Nav>
                      <Nav className="mr-auto">
                          <NavDropdown title="Reportes" id="collasible-nav-dropdown">
                              <NavDropdown.Item href="/reporteria-campañas">
                                Reportes por campañas
                              </NavDropdown.Item>
                              <NavDropdown.Item href="/reporteria-fechas">
                                Reportes por fechas
                              </NavDropdown.Item>
                              <NavDropdown.Item href="/reporteria-templates">
                                Reportes por templates
                              </NavDropdown.Item>
                          </NavDropdown>
                      </Nav>

                            </>
                          ):(
                            <>
                            </>
                          )
                        }
                        </>
                      ):(
                        <>
                        
                         <Nav.Link href="/template-list">  
                        Plantillas
                      </Nav.Link>
                  
                      <Nav className="mr-auto">
                        <NavDropdown title="Envio de Campañas" id="collasible-nav-dropdown">
                            <NavDropdown.Item href="/segment-list">
                              Lista de contactos
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/campaign-list">
                              Campañas
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    <Nav className="mr-auto">
                        <NavDropdown title="Reportes" id="collasible-nav-dropdown">
                            <NavDropdown.Item href="/reporteria-campañas">
                              Reportes por campañas
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/reporteria-fechas">
                              Reportes por fechas
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/reporteria-templates">
                              Reportes por templates
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    {
                          user["custom:role"]==="TenantAdmin"?(
                            <>
                            <Nav.Link href="/genesys-list">  
                              Base de Datos
                            </Nav.Link>
                            </>
                          ):(<>
                          </>)
                        }

                        </>
                      )
                    }
                 
                  </Nav>
                </Navbar.Collapse>
                <Navbar.Collapse className="justify-content-end">
                <Nav className="mr-auto">
                <NavDropdown title={user['cognito:username']} id="collasible-nav-dropdown">
                  {
                    tenant?(
                      <>
                      <NavDropdown.Item onClick={this.handleBack}>Volver</NavDropdown.Item>
                    <NavDropdown.Divider />
                      </>
                    ):(
                      <></>
                    )
                  }
                    <NavDropdown.Item onClick={this.handleLogout}>Cerrar sesión</NavDropdown.Item>
                </NavDropdown>
                </Nav>
              </Navbar.Collapse>
              </>
                 ):(
                   <>
                  </>
                 )
              }
            </Navbar>
          </>
        );
    }
}